import React from 'react';
import { connect } from 'react-redux';
import BaseComponent from '../../shared/BaseComponent'
import ExecuteArgs from '../../shared/model/executeArgs';
import i18n from '../../../i18n';
import Validation from './validationComponent';
import commonConfig from '../../common/commonConfig';
import * as actionsCommon from '../../shared/store/actions/index';
class MarkersDelete extends BaseComponent {
    initialFields = {
        id: null,
        deletionReason: ''
    }
    constructor(props) {
        super(props);
        this.state = {
            fields: { ...this.initialFields },
            requiredFields: [
                { fieldName: 'deletionReason', fieldType: 'default' }
            ],
            isError: 0,
            isDelete: false,
            showDeleteNotify: false,
            hideDeleteReason: false
        }
        if (props.useDeleteSuccessRef) this.setDeleteSuccessRef = React.createRef(props.setDeleteSuccess);
    }
    componentDidMount = () => {
        window.$('#deleteModal').modal({
            backdrop: 'static',
            keyboard: false,
            show: false
        });
        window.$("#deleteModal").on('hidden.bs.modal', () => {
            this.setState({ fields: this.initialFields, showDeleteNotify: false }, () => {
                this.props.onResetProps({ name: 'commonDelete', data: false });
                this.props.useDeleteSuccessRef ? this.setDeleteSuccessRef.current(null) : this.props.setDeleteSuccess(null);
            });
        });
    }
    handleChange = (event) => {
        let fields = Object.assign({}, this.state.fields);
        fields[event.target.name] = event.target.value.replace(/^\s+/g, '');    //trims space at start
        this.setState({ fields: fields });
    }
    componentWillReceiveProps(nextProps) {
        if (!!nextProps.setDeleteSuccess && this.props.useDeleteSuccessRef){
            this.setDeleteSuccessRef.current = nextProps.setDeleteSuccess
        }
        if (!!nextProps.deleteId) {
            let fields = Object.assign({}, this.initialFields);
            fields.id = nextProps.deleteId;
            this.setState({ fields: fields, isError: 0, hideDeleteReason: nextProps.twoStepDelete }, () => {
                window.$("#deleteModal").modal('show');
            });
        }
        if (!!nextProps.commonDelete && this.state.isDelete) {
            this.setState({ fields: this.initialFields, isDelete: false, showDeleteNotify: true, hideDeleteReason: nextProps.twoStepDelete }, () => {
                window.$("#deleteModal").modal('hide');
                this.props.setDeleteSuccess(null, true);
            });
        }
    }
    handleShowDeleteReason = event => {
        event.preventDefault();
        this.setState({ hideDeleteReason: false })
    }
    handleSubmit = (event) => {
        event.preventDefault();
        let validationObj = new Validation();
        let submitStatus = validationObj.fieldValidation(this.state.fields, this.state.requiredFields);
        if (submitStatus === 0) {
            this.setState({ isError: 1 });
        } else if (!!this.state.fields.id) {
            this.setState({ isError: 0, isDelete: true }, () => {
                let executedArgument = new ExecuteArgs(this.props.componentName, this.props.endPoint, commonConfig.METHOD_TYPE_POST, this.state.fields);
                this.props.onDeleteSuccess(executedArgument);
            });
        }
    }
    render() {
        return (
            <React.Fragment>
                <div id="deleteModal" className="modal fade" tabIndex="-1" role="dialog" aria-labelledby="deleteModalTitle" aria-modal="true">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            {/*<div className="modal-header">*/}
                            {/*    <h5 className="modal-title" id="exampleModalCenterTitle">{i18n.t("areYouSureYouWantToDeleteTheRecord")}</h5>*/}
                            {/*    <button type="button" className="close" data-dismiss="modal" aria-label="Close">*/}
                            {/*        <span aria-hidden="true">&times;</span>*/}
                            {/*    </button>*/}
                            {/*</div>*/}
                            <div className="modal-body text-center pb-0">
                                <svg xmlns="http://www.w3.org/2000/svg" height="100" viewBox="0 0 64 64" width="100"><g id="Delete_File" data-name="Delete File"><path d="m47.66 13.67a1.72 1.72 0 0 0 -1.72-1.67h-39.57a1.72 1.72 0 0 0 -1.72 1.72v3.39a1.74 1.74 0 0 0 1.72 1.72h39.57a1.74 1.74 0 0 0 1.72-1.72z" fill="#808b95" fill-rule="evenodd" /><path d="m30.78 52.92h-18.64a3.47 3.47 0 0 1 -3.48-3.47v-30.67h35v18.22z" fill="#c6d0da" /><g fill="#808b95" fill-rule="evenodd"><path d="m17.79 27.07a1.69 1.69 0 0 0 -.51-1.22 1.72 1.72 0 0 0 -1.22-.5h-.43a1.72 1.72 0 0 0 -1.73 1.72v17.56a1.74 1.74 0 0 0 1.73 1.73h.43a1.73 1.73 0 0 0 1.22-.51 1.69 1.69 0 0 0 .51-1.22z" /><path d="m28.1 27.07a1.69 1.69 0 0 0 -.51-1.22 1.72 1.72 0 0 0 -1.22-.5h-.43a1.72 1.72 0 0 0 -1.73 1.72v17.56a1.74 1.74 0 0 0 1.73 1.73h.43a1.73 1.73 0 0 0 1.22-.51 1.69 1.69 0 0 0 .51-1.22z" /><path d="m38.41 27.07a1.69 1.69 0 0 0 -.51-1.22 1.72 1.72 0 0 0 -1.22-.5h-.43a1.72 1.72 0 0 0 -1.22.5 1.69 1.69 0 0 0 -.51 1.22v17.56a1.69 1.69 0 0 0 .48 1.22 1.73 1.73 0 0 0 1.22.51h.43a1.73 1.73 0 0 0 1.22-.51 1.69 1.69 0 0 0 .51-1.22z" /></g><path d="m17 4a1.89 1.89 0 0 0 -1.89 1.9v6h4v-4.25h14v4.35h4v-6a1.91 1.91 0 0 0 -1.83-2z" fill="#5e6b75" /><path d="m41.71 34.9a1.42 1.42 0 0 1 2.42 0l13.92 23a1.41 1.41 0 0 1 -1.21 2.1h-27.84a1.4 1.4 0 0 1 -1.2-2.14c3.1-5.13 10.9-18 13.91-22.96z" fill="#f9f48e" /><path d="m45.29 43.17c0 1.31-1.06 7.63-2.37 7.63s-2.37-6.32-2.37-7.63a2.37 2.37 0 1 1 4.74 0z" fill="#df75a5" /><path d="m44.47 55a1.55 1.55 0 1 1 -1.55-1.55 1.55 1.55 0 0 1 1.55 1.55z" fill="#df75a5" /></g></svg>
                                <h5 className="modal-title mt-4 mb-2" id="exampleModalCenterTitle"><b>{i18n.t("areYouSure?")}</b></h5>
                                <p className="m-0 f-s-16 text-muted">{i18n.t("OnceDeletedMsg")}</p>
                            </div>
                            {!this.state.hideDeleteReason ?
                                <div className="modal-body">
                                    <div>
                                        <label className="col-form-label">{i18n.t("deletionReason")}</label>
                                        <textarea name="deletionReason" className="form-control resize-none" placeholder={i18n.t("enterDeletionReason")} value={this.state.fields.deletionReason || ''} style={{ height: "126px" }} onChange={(e) => this.handleChange(e)}></textarea>
                                    </div>
                                    <Validation data={{
                                        field: i18n.t("deletionReason"),
                                        errorClass: 'deletion_reason_error',
                                        value: this.state.fields.deletionReason,
                                        isError: this.state.isError,
                                        validationType: 'field'
                                    }} returnValidation={this.functionValidation} />
                                </div> : null
                            }
                            <div className="modal-body pt-0 text-center">
                                <button type="button" className="btn btn-secondary mr-2" data-dismiss="modal" aria-label="Close">{i18n.t('noCancel')}</button>
                                <button type="button" className="btn btn-danger" id="yesBtn" onClick={this.state.hideDeleteReason ? this.handleShowDeleteReason : this.handleSubmit}>{i18n.t('yesdelete')}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
const mapStateToProps = state => {
    return {
        commonDelete: state.commonStore.commonDelete
    }
}
const mapDispatchToProps = dispatch => {
    return {
        onDeleteSuccess: (executeArgs) => dispatch(actionsCommon.callCommonDeleteApi(executeArgs, 'common')),
        onResetProps: (executeArgs) => dispatch(actionsCommon.commonStoreStateReset(executeArgs))
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(MarkersDelete)