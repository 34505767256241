import React from 'react';
import i18n from '../../../i18n';
import _ from 'lodash';
import sessionStore from './sessionStoreComponent';
const moment = require('moment');
const momentTimeZone = require('moment-timezone');
export class CommonFuncs {
    timeFormat(date) {
        var d = date;
        var hh = d.slice(0, 2);
        var m = d.slice(3, 2);
        var s = d.slice(6, 2);
        var dd = "AM";
        var h = hh;
        if (h >= 12) {
            h = hh - 12;
            dd = "PM";
        }
        if (h === 0) {
            h = 12;
        }
        m = m < 10 ? "00" + m : m;
        s = s < 10 ? "00" + s : s;
        /* if you want 2 digit hours:
        h = h<10?"0"+h:h; */
        var replacement = h + ":" + m;
        /* if you want to add seconds
        replacement += ":"+s;  */
        replacement += " " + dd;
        return replacement;
    }
    returnResetRoute(currentRoute) {
        let returnRoute = '';
        if (currentRoute && currentRoute.includes("school") === true)
            returnRoute = '/school/showSchoolListRoute';
        if (currentRoute && currentRoute.includes("student") === true)
            returnRoute = '/student/showStudentListRoute';
        if (currentRoute && currentRoute.includes("staff") === true)
            returnRoute = '/staff/showStaffListRoute'
        return returnRoute;
    }
    addClassToBody(className) {
        if (className !== '')
            window.$('body').addClass(className)
    }
    removeClassFromBody(className) {
        if (className !== '')
            window.$('body').removeClass(className)
    }
    compareValues(key, order = 'asc'){
        return function innerSort(a, b) {
            if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
                // property doesn't exist on either object
                return 0;
            }

            const varA = (typeof a[key] === 'string')
                ? a[key].toUpperCase() : a[key];
            const varB = (typeof b[key] === 'string')
                ? b[key].toUpperCase() : b[key];

            let comparison = 0;
            if (varA > varB) {
                comparison = 1;
            } else if (varA < varB) {
                comparison = -1;
            }
            return (
                (order === 'desc') ? (comparison * -1) : comparison
            );
        };
     }
     //Returns day of week...
     getDaysName = (id) => {
        switch (parseInt(id, 10)) {
            case 1:
                return i18n.t('monday');
                break;
            case 2:
                return i18n.t('tuesday');
                break;
            case 3:
                return i18n.t('wednesday');
                break;
            case 4:
                return i18n.t('thursday');
                break;
            case 5:
                return i18n.t('friday');
                break;
            case 6:
                return i18n.t('saturday');
                break;
            case 7:
                return i18n.t('sunday');
                break;
            default:
            // code block
        }
    }
    //This will convert time into 12hr format
    convertTime = (tempTime) => {
        let convertedTime = '';
        if (!!tempTime) {
            var am_pm = "AM";
            var arrHours = ['0', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11'];
            var new_time = tempTime.split(":");
            var hours = parseInt(new_time[0], 10);
            var min = new_time[1];
            var hrs = arrHours[hours];
            if (hours > 12) {
                am_pm = "PM";
            }
            convertedTime = hrs + ":" + min + " " + am_pm;
        }
        return convertedTime;
    }
    //This will convert time into 12hr format with seconds
    convertTimeWithSeconds = (tempTime) => {
        let convertedTime = '';
        if (!!tempTime) {
            var am_pm = "AM";
            var arrHours = ['0', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11'];
            var new_time = tempTime.split(":");
            var hours = parseInt(new_time[0], 10);
            var min = new_time[1];
            const sec = new_time.length > 2 ? new_time[2] : "00";
            var hrs = arrHours[hours];
            if (hours > 12) {
                am_pm = "PM";
            }
            convertedTime = hrs + ":" + min + ":" + sec + " " + am_pm;
        }
        return convertedTime;
    }
    //This function is used to convert 12 hr time format to 24 hr format for validating
    get24hTime = (str) => {
        str = String(str).toLowerCase().replace(/\s/g, '');
        var has_am = str.indexOf('am') >= 0;
        var has_pm = str.indexOf('pm') >= 0;
        str = str.replace('am', '').replace('pm', '');
        if (str.indexOf(':') < 0) str = str + ':00';
        if (has_am) str += ' am';
        if (has_pm) str += ' pm';
        var d = new Date("1/1/2011 " + str);
        var doubleDigits = function (n) {
            return (parseInt(n) < 10) ? "0" + n : String(n);
        };
        return doubleDigits(d.getHours()) + ':' + doubleDigits(d.getMinutes());
    }
    //This function is used to convert Date
    formatDate = (date) => {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();
        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;
        return [year, month, day].join('-');
    }
    //Remove element from array
    removeElement = (array, name) => {
        var evens = _.remove(array, function (n) {
            return n.fieldName === name;
        });
    }
    //Remove element from array with custom name
    removeElementCustom = (array, name, customName) => {
        var evens = _.remove(array, function (n) {
            return n[customName] === name;
        });
    }
    //Get today's date in format
    getCurrentDate = () => {
        var today = new Date();
        var dd = today.getDate();
        var mm = today.getMonth() + 1;
        var yyyy = today.getFullYear();
        if (dd < 10) {
            dd = '0' + dd;
        }
        if (mm < 10) {
            mm = '0' + mm;
        }
        today = yyyy + '-' + mm + '-' + dd;
        return today;
    }
    //Get today's date for calendar event format
    getEventCurrentDate = (date) => {
        var days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
        var months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
        var now = new Date(date);
        return days[now.getDay()] + ', ' + months[now.getMonth()] + ' ' + now.getDate() + ', ' + now.getFullYear();
    }
    //Search value from lookup array
    search = (nameKey, myArray) => {
        for (var i = 0; i < myArray.length; i++) {
            if (myArray[i].id === parseInt(nameKey, 10)) {
                return myArray[i].title;
            }
        }
    }
    //This function is called for modal show by id
    showModal = (id) => {
        window.$('#' + id).modal("show");
    }
    //This function is called for modal hide by id
    hideModal = (id) => {
        window.$('#' + id).modal("hide");
    }
    //This function is called for reset form attributes by id
    formTriggerReset = (id) => {
        window.$('#' + id).trigger("reset");
    }
    //Download file
    download = (filename, filetype, content) => {
        var element = document.createElement('a');
        element.setAttribute('href', 'data:' + filetype + ';base64,' + encodeURIComponent(content));
        element.setAttribute('download', filename);
        element.style.display = 'none';
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
    }
    //Remove duplicates from array
    removeDuplicates(myArr, prop) {
        return !!myArr && myArr.length > 0 && myArr.filter((obj, pos, arr) => {
            return arr.map(mapObj => mapObj[prop]).indexOf(obj[prop]) === pos;
        });
    }

    //Change time format...
    changeTimeFormat(timeString) {
        let timeString12hr = new Date('1970-01-01T' + timeString + 'Z')
            .toLocaleTimeString({},
                { timeZone: 'Asia/Kolkata', hour12: true, hour: 'numeric', minute: 'numeric' }
            );
        return timeString12hr;
    }
    //Change time format...
    changeTimeFormatnew(timeString) {
        if (timeString !== null) {
            let timeString12hr = new Date(timeString).toLocaleTimeString({},
                { timeZone: 'Asia/Kolkata', hour12: true, hour: 'numeric', minute: 'numeric' }
            );
            return timeString12hr.toLocaleUpperCase();
        }
        else {
            return timeString
        }
    }
    //Change time format as per time zone...
    changeTimeFormatTimeZone(timeString) {
        if (typeof timeString === 'string' && timeString != null) {
            if (!!new sessionStore().returnSchoolTimeZone()) {
                return momentTimeZone(timeString).tz(new sessionStore().returnSchoolTimeZone()).format('hh:mm A')
            }
            else {
                return momentTimeZone(timeString).local().format('hh:mm A')
            }
        }
        else
            return timeString;
    }
    //Change time format updated...
    changeTimeFormatUpdated(timeString) {
        if (typeof timeString === 'string' && timeString != null) {
            if (timeString.substr(timeString.length - 5) === '00:00')
                return moment(timeString).utc().format('hh:mm A');
            else
                return moment(timeString).format('hh:mm A');
        }
        else
            return timeString;
    }
    //Format field status...
    formatStatus(status, customDeclineColor = null, customDeclineTextFormat = null) {
        switch (status) {
            case 'Approved':
                return <div style={{ color: 'green' }}> {status} </div>
            case 'Pending':
                return <div style={{ color: 'orange' }}> {status} </div>
            case 'Discarded':
                return <div style={{ color: 'gray', 'text-decoration': 'line-through' }}> {status} </div>
            case 'Declined':
                return <div style={{ color: !!customDeclineColor ? customDeclineColor : 'gray', 'text-decoration': !!customDeclineTextFormat ? customDeclineTextFormat : 'line-through' }}> {status} </div>
            case 'Cancelled':
                return <div style={{ color: 'red', 'text-decoration': 'line-through' }}> {status} </div>
            default:
                return <div> {status} </div>
        }
    }
    //Format payment field status...
    formatPaymentStatus(status, customDeclineColor = null, customDeclineTextFormat = null) {
            switch (status) {
                case 'Paid':
                    return <div style={{ color: 'green' }}> {status} </div>
                case 'Pending':
                    return <div style={{ color: 'red' }}> {status} </div>
                case 'Cancelled':
                    return <div style={{ color: 'red' }}> {status} </div>
                default:
                    return <div> {status} </div>
            }
        }
    //Format comma separated string...
    formatCommaSeparatedString(string) {
        if (string !== '' && string !== null) {
            let stringArray = string.split(",");
            let finalStringArray = [];
            if (stringArray && stringArray.length > 0) {
                stringArray.forEach((data, i) => {
                    let value;
                    value = this.getDaysName(data);
                    finalStringArray.push(value);
                })
            }
            return finalStringArray.toString();
        }
    }
    // checks if string is valid positive integer from 0 to 2,147,483,647
    isValidInt = stringNum => {
        const maxInt = 2147483647;
        if (stringNum.match(/\D/) || parseInt(stringNum, 10) > maxInt || (stringNum.length > 1 && stringNum[0] === '0')) return false;
        return true
    }
    // checks if string is valid positive bigInt from 0 to 9,223,372,036,854,775,807
    isValidBigInt = stringNum => {
        const maxBigInt = "9223372036854775807"
        if (stringNum.match(/\D/) || stringNum.length > maxBigInt.length || (stringNum.length > 1 && stringNum[0] === '0')) return false;
        if (stringNum.length < maxBigInt.length) return true;
        for (let i=0; i < maxBigInt.length; i++){
            if (stringNum[i] > maxBigInt[i]) return false;
        }
        return true;
    }
    // this function coverts a number string "1401,1402,1403" into days of the week "M T W"
    convertNumberListToSpacedDaysOfTheWeek = numberString => {
        if (!numberString) return ''
        const conversionArray = [1401,1402,1403,1404,1405,1406,1407];
        const dayArray = ['M','T','W','T','F','S','S']
        for (let i = 0; i < conversionArray.length; i++){
            conversionArray[i] = numberString.includes(conversionArray[i]) ? dayArray[i] : " ";
        }
        return `${conversionArray.join(" ")}`
    }
    isBoolean = (val) => {
        let isBoolean = 'boolean' === typeof val;
        return isBoolean;
    }
    filterMenuList = (array, rejectedSubMenus = []) => {
        let listArray = _.map(array, (element) => {
            return { ...element, child: _.filter(element.child, ({ title }) => rejectedSubMenus.indexOf(title) === -1) };
        });
        return listArray;
    };
}
export default CommonFuncs;
