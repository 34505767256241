import { Component } from 'react';
import i18n from '../../../i18n';
class markersBreadCrumb extends Component {
    returnContent(url) {
        var content = {
            '/billing/studentPayment':
                { 'name': i18n.t('studentPayment'), 'url': 'billing/studentPayment', 'parent': [{ 'name': i18n.t('billing'), 'url': '#' }] },
            '/billing/studentPayment/paymentDetails':
                { 'name': i18n.t('paymentDetails'), 'url': 'billing/paymentDetails', 'parent': [{ 'name': i18n.t('billing'), 'url': '#' }, { 'name': i18n.t('studentPayment'), 'url': '/billing/studentPayment' }] },
            '/billing/studentPayment/paymentDetails/pastPayment':
                { 'name': i18n.t('paymentDetails'), 'url': 'billing/paymentDetails', 'parent': [{ 'name': i18n.t('billing'), 'url': '#' }, { 'name': i18n.t('studentPayment'), 'url': '/billing/studentPayment' }] },
            '/billing/studentPayment/paymentDetails/duePayment':
                { 'name': i18n.t('paymentDetails'), 'url': 'billing/paymentDetails', 'parent': [{ 'name': i18n.t('billing'), 'url': '#' }, { 'name': i18n.t('studentPayment'), 'url': '/billing/studentPayment' }] },
            '/billing/studentPayment/paymentDetails/pendingCancelPayment':
                { 'name': i18n.t('paymentDetails'), 'url': 'billing/paymentDetails', 'parent': [{ 'name': i18n.t('billing'), 'url': '#' }, { 'name': i18n.t('studentPayment'), 'url': '/billing/studentPayment' }] },
            '/billing/payableDate':
                { 'name': i18n.t('BILL_PAYBL_DT'), 'url': 'billing/payableDate', 'parent': [{ 'name': i18n.t('billing'), 'url': '/billing/payableDate' }] },
            '/billing/billingHead':
                { 'name': i18n.t('billingHead'), 'url': 'billing/billingHead', 'parent': [{ 'name': i18n.t('billing'), 'url': '/billing/billingHead' }] },
            '/billing/studentPayable':
                { 'name': i18n.t('BILL_STUD_PAYBL'), 'url': 'billing/studentPayable', 'parent': [{ 'name': i18n.t('billing'), 'url': '/billing/studentPayable' }] },
            '/billing/feeStructure':
                { 'name': i18n.t('feeStructure'), 'url': 'billing/feeStructure', 'parent': [{ 'name': i18n.t('billing'), 'url': '/billing/feeStructure' }] },
            '/billing/paymentHistory':
                { 'name': i18n.t('paymentHistory'), 'url': 'billing/paymentHistory', 'parent': [{ 'name': i18n.t('billing'), 'url': '/billing/paymentHistory' }] },
            '/billing/gatewayIntegration':
                { 'name': i18n.t('gatewayIntegration'), 'url': 'billing/gatewayIntegration', 'parent': [{ 'name': i18n.t('billing'), 'url': '/billing/gatewayIntegration' }] },
            '/billing/pastDueReport':
                { 'name': i18n.t('BILL_PDRPT'), 'url': 'billing/pastDueReport', 'parent': [{ 'name': i18n.t('billing'), 'url': '/billing/pastDueReport' }] },
            '/billing/studentCollectionReport':
                { 'name': i18n.t('studentCollectionReport'), 'url': 'billing/studentCollectionReport', 'parent': [{ 'name': i18n.t('billing'), 'url': '/billing/studentCollectionReport' }] },
        
        };
        return content[url];
    }
}
export default markersBreadCrumb;