import * as actionTypesShared from "../actions/actionTypesShared"
import { updateObject } from '../utility';
import commonConfig from '../../../common/commonConfig';
import _ from 'lodash';
const initialState = {
    getDownloadFile:{},
    isLoading: false,
    notifyType: 'success',
    notifyMessage: '',
    errorMessage: '',
    commonDelete: false,
    countryList: [],
    saveCustomFieldValues: false,
    getCustomField: {},
    customFieldList: [],
    menuList: [],
    userMenuList: [],
    getFileUrl: {},
    searchResultList: [],
    searchFieldList: [],
    fileDelete: false,
    getStudentCollection: {},
    saveUserPreference: false,
    isActiveComposeEmailPopup: false,
    isActiveComposeMessagePopup: false,
    isSaveEmailPopup: false,
    dynamicFormList: [],
    saveDynamicForm: false,
    getDynamicFormData: {},
    stateByCountryList: [],
    countyByStateList: [],
    colourList: [],
    saveGenerateNotifyStudentPassword: false,
    studentNotifyList: [],
    /* lookup state */
    lookupSchoolType: [],
    lookupSchoolLevel: [],
    lookupSchoolFocusType: [],
    lookupAddressTypeList: [],
    lookupSchoolIdentificationSystemList: [],
    lookupPhoneTypeList: [],
    lookupEmailTypeList: [],
    lookupDesignationTypeList: [],
    lookupAccredationAgencyTypeList: [],
    lookupHeAccredationTypeList: [],
    lookupAdminFundControlList: [],
    lookupSchoolOperationalStatusList: [],
    lookupSchoolImprovementStatusList: [],
    lookupSchoolGradeList: [],
    lookupStudentEthnicityList: [],
    lookupStudentHispanicLatinoEthnicityList: [],
    lookupStudentHispanicLatinoRaceEthList: [],
    lookupStudentLanguageList: [],
    lookupStudentRollingRetentionList: [],
    lookupVisibility: [],
    lookupSchoolFieldType: [],
    lookupSchoolFieldDataType: [],
    lookupSchoolFieldDateFormat: [],
    lookupRoleTypeList: [],
    lookupStudentIdentificationSystem: [],
    lookupStudentPublicSchoolResidentialStatusList: [],
    lookupStudentOtherBackwardCommunityList: [],
    lookupStudentMilitaryBranchList: [],
    lookupStudentMilitaryConnectionList: [],
    lookupStudentVerifiableInfoList: [],
    lookupDocumentVerificationCertificateList: [],
    lookupStudentVisaTypeList: [],
    lookupPersonRelationshipList: [],
    lookupSalutationList: [],
    lookupDisabilityType: [],
    lookupAccommodationNeedType: [],
    lookupDisabilityConditionType: [],
    lookupDisabilityConditionNature: [],
    lookupDeterminedBy: [],
    lookupIdeaDisabilityType: [],
    lookUpMarkingPeriodList: [],
    lookupFoodServiceEligibility: [],
    lookupSchoolLunchProgramType: [],
    lookupFoodServiceEligibilityFree: [],
    lookupFoodServiceEligibilityFull: [],
    lookupFoodServiceEligibilityReduced: [],
    lookupFoodServiceEligibilityOther: [],
    lookupStudentReferralType: [],
    lookupStudentReferralOutcome: [],
    lookupVisionStatusList: [],
    lookupHearingStatusList: [],
    lookupDentalStatusList: [],
    lookupHealthInsuranceCoverageList: [],
    lookupDentalInsuranceCoverageList: [],
    lookupMedicalAlertTypeList: [],
    lookupStudentHealthScreeningStatusList: [],
    lookupStudentMultipleBirthList: [],
    lookupNonPromotionReasonList: [],
    lookupPromotionReasonList: [],
    lookupExitWithdrawalTypeList: [],
    lookupExitWithdrawalStatusList: [],
    lookupEnrollmentStatusList: [],
    lookupRollingRetentionList: [],
    lookupEnrollmentEntryTypeList: [],
    lookupEnrollmentGiftedProgramList: [],
    LookUpSchoolSessionTitle: [],
    LookupCrisisType: [],
    lookupSchoolCalendarEventType: [],
    lookupEntityType: [],
    lookupCredentialTypeList: [],
    lookupStaffVisaTypeList: [],
    lookupCertificateAccreditationStatusList: [],
    lookupRelationship: [],
    lookupTeachingCredentialTypeList: [],
    lookupTeachingCredentialBasisList: [],
    lookupStaffCertSponsorTypeList: [],
    lookupStudentInsuranceTypeList: [],
    lookupLeaveTypeList: [],
    lookupPublicationStatusList: [],
    lookupCourseLevelCharacteristic: [],
    lookupCourseCreditUnit: [],
    lookupCourseWblOpportunityType: [],
    lookupAditionalCreditType: [],
    lookupCourseGpaApplicability: [],
    lookupCurriculumFrameworkType: [],
    lookupCourseCreditTypeEarned: [],
    lookupCourseCareerCluster: [],
    lookupCourseBlndLearningModelType: [],
    lookupCourseExamRequirement: [],
    lookupAdvancedPlacementCourseCode: [],
    lookupCertificationTypeList: [],
    lookupPeriodScheduleList: [],
    lookupSingleSexClassList: [],
    lookupCourseDeliveryList: [],
    lookupCourseSectionLevelTypeList: [],
    lookupCertificateVerificationMethodList: [],
    lookupDocumentVerificationStatusList: [],
    lookupStaffLeaveStatusList: [],
    lookupCourseType: [],
    lookupMathOperatorList: [],
    lookupAttendanceStatusList: [],
    lookupPresentAttendanceCategoryList: [],
    lookupAbsentAttendanceCategoryList: [],
    lookupIncidentTimeType: [],
    lookupIncidentBehaviourType: [],
    lookupIncidentBehaviour: [],
    lookupWeaponType: [],
    lookupInjuryType: [],
    lookupIncidentLocation: [],
    lookupFireArmType: [],
    lookupMultipleOffenceType: [],
    lookupGradeScaleDefination: [],
    lookupAssessmentAssetTypeList: [],
    lookupSearchType: [],
    lookupAccommodationTypeList: [],
    lookupAccommodationCategoryList: [],
    lookupDisciplineReason: [],
    lookupDisciplinaryActionTaken: [],
    lookupDisciplineLengthDifference: [],
    lookupIdeaInterimRemoval: [],
    lookupIdeaInterimRemovalReason: [],
    lookupFirearmDisciplineMethod: [],
    lookupIdeaFirearmDisciplineMethod: [],
    lookupDmForDiabeticChildren: [],
    lookupIncidentPersonRole: [],
    lookupIncidentPersonType: [],
    lookupAssessmentPurposeList: [],
    lookupAssessmentTypeList: [],
    lookupAssessmentTypeWithDisabilityList: [],
    lookupAssessmentRegistrationCompletionStatusList: [],
    lookupAssessmentParticipationList: [],
    lookupAssessmentReasonNotTestedList: [],
    lookupAssessmentReasonNotCompletedList: [],
    lookupAssessmentReportingMethodList: [],
    lookupAssessmentSessionType: [],
    lookupAssessmentFormSecIdentificationSystemList: [],
    lookupScoreMetricTypeList: [],
    lookupAssessmentOutcomeList: [],
    lookupResultScoreTypeList: [],
    lookupCourseSectionExitType: [],
    getLmsSubscriptionFlag: null,
    lookupAnnouncementType: [],
    lookupEntryType: [],
    lookupCourseSequenceType: [],
    lookupPersonOccupationTypeList: [],
    lookupAssignmentAggregationList: [],
    lookupAssignmentGradingTypeList: [],
    lookupGPACalCBasedOn: [],
    lookupAdaEligibilityTypeList: [],
    lookupGradeTypeList: [],
    lookupNameSuffixList: [],
    lookupStudentImmunizationRouteList: [],
    lookupStudentImmunizationStatusList: [],
    lookupStudentInjectionSiteList: [],
    lookupVehicleTypeList: [],
    lookupOwnershipTypeList: [],
    lookupSeatTypeList: [],
    lookupBillingType: [],
    getUserProfilePicture: false,
    lookupBillingFrequencyType: [],
    lookupPaymentType: [],
    lookupStatusType: [],
    lookupGatewayTypeList: [],
    //school
    isLoadDistrictMenuCallRequired: false,
    schoolList: [],
    schoolBasedRoleList: [],
    schoolTimeZoneNameList: [],
    getStaffDemographicInfo: {},
    getStudent: {},
    getSchoolDefaultCalendar: {},
    schoolAllMarkingPeriodsList: [],
    getStudentList: {},
    getStaffInfo: {},
    getSchoolInformation: {},
    getSchoolSectionInfo: {},
    getSchoolGrade: {},
    getSchoolSessionInfo: {},
    getSchoolRoom: {},
    getSchoolDepartment: {},
    getSchoolGradeScaleDetails: {},
    getSchoolPeriod: {},
    getSchoolRole: {},
    getStudentGuardian: {},
    getStaffContactInfo: {},
    getSchoolMarkingPeriod: {},
    getCourseSubject: {},
    getCourse: {},
    getCourseSection: {},
    schoolRoleBasedPermissionList: [],
    getSchoolSession: {},
    //Billing
    billingRecordList: [],
    saveBillingRecord: false,
    getBillingRecord: {},
    payableDateList: [],
    schoolRoleBasedPermissionList: [],
    schoolSessionList: [],
    gradeLevelList: [],
    feeStructureList: [],
    getFeeStructure: {},
    saveFeeStructure: {},
    savePayableDate: false,
    payableDateDetailsList: [],
    studentPayableList: [],
    studentGradeSessionList: [],
    saveStudentPayable: {},
    getStudentPayable: {},
    studentPaymentList: [],
    duePaymentList: [],
    receiveMessagesList: [],
    sendEmailList:[],
    studentPaymentList: [],
    studentPaymentPendingCancelledList: [],
    getPaymentDetails: {},
    savePaymentDetails: {},
    pastPaymentList: [],
    saveCashPayment: false,
    saveStudentPayableMassAssociation: {},
    paymentDetailsList: [],
    paymentHistoryList: [],
    saveGatewayIntegration: false,
    gatewayIntegrationList: [],
    getGatewayIntegration: {},
    massDelete: null,
    studentGuardiansList:[],
    /* generate student password*/
    pendingPasswordStudentList: [],
    saveGenerateRegenerateStudentPassword: false,
    /* generate student password*/
    getStateFieldView: null,
    getResStateFieldView: null,
    getSchStateFieldView: null,
    getStfStateFieldView: null,
    getStfResStateFieldView: null,
    getStuStateFieldView: null,
    getStuResStateFieldView: null,
    getStuLeaveStateFieldView: null,
    getStuGradStateFieldView: null,
    getRazorCheckoutPayment: {},
    gatewayDetailsList: [],
    saveRazorCheckoutPayment: {},
    parentDuePaymentList: [],
    saveTotalOnlinePayment: {},
    getCountryStateLanguageCurrency: {},
    studentPastDueList: [],
    getStudentPastDue: {},
    studentAssocFeeStructureList: [],
    feeGroupNameList: [],
    studentCollectionList: [],
    lookupStuCollectSummaryRptSortOrder: [],

    // District
    getDistrictSchoolEditPermissionsForAdmin: {},
    copyFeeStructureList: [],
    getCopyDetails: {}
}
const handleFail = (state) => {
    return updateObject(state, {
        isLoading: false
    });
}
const handleSuccess = (state, action, storeStateName) => {
    return updateObject(state, {
        loading: false,
        [storeStateName]: action.responseData.data
    });
}
const storeLookupToSession = (state, action) => {
    var lookups = action.lookups;
    var postedData = action.postedData["categoryType"];
    if (lookups !== null && lookups !== "") {
        switch (postedData) {
            case commonConfig.LOOKUP_MATH_OPERATOR: return updateObject(state, { isLoading: false, lookupMathOperatorList: lookups });
            case commonConfig.LOOKUP_ENTITY_TYPE: return updateObject(state, { isLoading: false, lookupEntityType: lookups });
            case commonConfig.LOOKUP_SCHOOL_TYPE: return updateObject(state, { isLoading: false, lookupSchoolType: lookups });
            case commonConfig.LOOKUP_SCHOOL_LEVEL: return updateObject(state, { isLoading: false, lookupSchoolLevel: lookups });
            case commonConfig.LOOKUP_SCHOOL_FOCUS: return updateObject(state, { isLoading: false, lookupSchoolFocusType: lookups });
            case commonConfig.LOOKUP_ADDRESS_TYPE: return updateObject(state, { isLoading: false, lookupAddressTypeList: lookups });
            case commonConfig.LOOKUP_SCHOOL_IDENTIFICATION_SYSTEM: return updateObject(state, { isLoading: false, lookupSchoolIdentificationSystemList: lookups });
            case commonConfig.LOOKUP_PHONE_TYPE: return updateObject(state, { isLoading: false, lookupPhoneTypeList: lookups });
            case commonConfig.LOOKUP_EMAIL_TYPE: return updateObject(state, { isLoading: false, lookupEmailTypeList: lookups });
            case commonConfig.LOOKUP_SCHOOL_DESIGNATION_TYPE: return updateObject(state, { isLoading: false, lookupDesignationTypeList: lookups });
            case commonConfig.LOOKUP_ACCREDITATION_AGENCY: return updateObject(state, { isLoading: false, lookupAccreditationAgencyList: lookups });
            case commonConfig.LOOKUP_HE_ACCREDITATION_STATUS: return updateObject(state, { isLoading: false, lookupHeAccreditationStatusList: lookups });
            case commonConfig.LOOKUP_ADMIN_FUND_CONTROL: return updateObject(state, { isLoading: false, lookupAdminFundControlList: lookups });
            case commonConfig.LOOKUP_SCHOOL_OPERATIONAL_STATUS: return updateObject(state, { isLoading: false, lookupSchoolOperationalStatusList: lookups });
            case commonConfig.LOOKUP_SCHOOL_IMPROVEMENT_STATUS: return updateObject(state, { isLoading: false, lookupSchoolImprovementStatusList: lookups });
            case commonConfig.LOOKUP_SCHOOL_GRADE_LEVEL: return updateObject(state, { isLoading: false, lookupSchoolGradeList: lookups });
            case commonConfig.LOOKUP_SCHOOL_CALENDAR_VISIBILITY: return updateObject(state, { isLoading: false, lookupVisibility: lookups });
            case commonConfig.LOOKUP_SCHOOL_FIELD_TYPE: return updateObject(state, { isLoading: false, lookupSchoolFieldType: lookups });
            case commonConfig.LOOKUP_SCHOOL_FIELD_DATA_TYPE: return updateObject(state, { isLoading: false, lookupSchoolFieldDataType: lookups });
            case commonConfig.LOOKUP_SCHOOL_FIELD_DATE_FORMAT: return updateObject(state, { isLoading: false, lookupSchoolFieldDateFormat: lookups });
            case commonConfig.LOOKUP_ROLE_TYPE: return updateObject(state, { isLoading: false, lookupRoleTypeList: lookups });
            case commonConfig.LOOKUP_SALUTATION_TYPE: return updateObject(state, { isLoading: false, lookupSalutationList: lookups });
            case commonConfig.LOOKUP_SALUTATION_STAFF_TYPE: return updateObject(state, { isLoading: false, lookupSalutationList: lookups });
            case commonConfig.LOOKUP_MARKINGPERIOD_TYPE: return updateObject(state, { isLoading: false, lookUpMarkingPeriodList: lookups });
            case commonConfig.LOOKUP_SCHOOL_SESSION_TITLE: return updateObject(state, { isLoading: false, LookUpSchoolSessionTitle: lookups });
            case commonConfig.LOOKUP_CRISIS_TYPE: return updateObject(state, { isLoading: false, LookupCrisisType: lookups });
            case commonConfig.LOOKUP_SCHOOL_CALENDAR_EVENT_TYPE: return updateObject(state, { isLoading: false, lookupSchoolCalendarEventType: lookups });
            case commonConfig.LOOKUP_GRADE_SCALE_DEFINITION: return updateObject(state, { isLoading: false, lookupGradeScaleDefination: lookups });
            case commonConfig.LOOKUP_SEARCH_TYPE: return updateObject(state, { isLoading: false, lookupSearchType: lookups });
            case commonConfig.LOOKUP_GPA_CALC_BASED_ON: return updateObject(state, { isLoading: false, lookupGPACalCBasedOn: lookups });
            case commonConfig.LOOKUP_GRADE_TYPE: return updateObject(state, { isLoading: false, lookupGradeTypeList: lookups });
            /*Student section*/
            case commonConfig.LOOKUP_STUDENT_ETHNICITY: return updateObject(state, { isLoading: false, lookupStudentEthnicityList: lookups });
            case commonConfig.LOOKUP_STUDENT_HISPANIC_LATINO_ETHNICITY: return updateObject(state, { isLoading: false, lookupStudentHispanicLatinoEthnicityList: lookups });
            case commonConfig.LOOKUP_STUDENT_HISPANIC_LATINO_RACE: return updateObject(state, { isLoading: false, lookupStudentHispanicLatinoRaceEthList: lookups });
            case commonConfig.LOOKUP_STUDENT_LANGUAGE: return updateObject(state, { isLoading: false, lookupStudentLanguageList: lookups });
            case commonConfig.LOOKUP_STUDENT_ROLLING_RETENTION: return updateObject(state, { isLoading: false, lookupStudentRollingRetentionList: lookups });
            case commonConfig.LOOKUP_STUDENT_IDENTIFICATION_SYSTEM: return updateObject(state, { isLoading: false, lookupStudentIdentificationSystem: lookups });
            case commonConfig.LOOKUP_STUDENT_PUBLIC_SCHOOL_RESIDENTIAL_STATUS: return updateObject(state, { isLoading: false, lookupStudentPublicSchoolResidentialStatusList: lookups });
            case commonConfig.LOOKUP_STUDENT_OTHER_BACKWARD_COMMUNITY: return updateObject(state, { isLoading: false, lookupStudentOtherBackwardCommunity: lookups });
            case commonConfig.LOOKUP_STUDENT_MILITARY_BRANCH: return updateObject(state, { isLoading: false, lookupStudentMilitaryBranchList: lookups });
            case commonConfig.LOOKUP_STUDENT_MILITARY_CONNECTION: return updateObject(state, { isLoading: false, lookupStudentMilitaryConnectionList: lookups });
            case commonConfig.LOOKUP_STUDENT_VERIFIABLE_INFO: return updateObject(state, { isLoading: false, lookupStudentVerifiableInfoList: lookups });
            case commonConfig.LOOKUP_STUDENT_DOCUMENT_VERIFICATION_CERTIFICATE: return updateObject(state, { isLoading: false, lookupDocumentVerificationCertificateList: lookups });
            case commonConfig.LOOKUP_STUDENT_VISA_TYPE: return updateObject(state, { isLoading: false, lookupStudentVisaTypeList: lookups });
            case commonConfig.LOOKUP_STUDENT_PERSON_RELATIONSHIP: return updateObject(state, { isLoading: false, lookupPersonRelationshipList: lookups });
            case commonConfig.LOOKUP_STUDENT_DISABILITY_TYPE: return updateObject(state, { isLoading: false, lookupDisabilityType: lookups });
            case commonConfig.LOOKUP_STUDENT_ACCOMMODATION_NEED_TYPE: return updateObject(state, { isLoading: false, lookupAccommodationNeedType: lookups });
            case commonConfig.LOOKUP_STUDENT_DISABILITY_CONDITION_TYPE: return updateObject(state, { isLoading: false, lookupDisabilityConditionType: lookups });
            case commonConfig.LOOKUP_STUDENT_DISABILITY_CONDITION_NATURE: return updateObject(state, { isLoading: false, lookupDisabilityConditionNature: lookups });
            case commonConfig.LOOKUP_STUDENT_DETERMINED_BY: return updateObject(state, { isLoading: false, lookupDeterminedBy: lookups });
            case commonConfig.LOOKUP_STUDENT_IDEA_DISABILITY_TYPE: return updateObject(state, { isLoading: false, lookupIdeaDisabilityType: lookups });
            case commonConfig.LOOKUP_SCHOOL_LUNCH_PROGRAM_TYPE: return updateObject(state, { isLoading: false, lookupSchoolLunchProgramType: lookups });
            case commonConfig.LOOKUP_STUDENT_FOOD_SERVICE_ELIGIBILITY: return updateObject(state, { isLoading: false, lookupFoodServiceEligibility: lookups });
            case commonConfig.LOOKUP_STUDENT_FOOD_SERVICE_ELIGIBILITY_FREE: return updateObject(state, { isLoading: false, lookupFoodServiceEligibilityFree: lookups });
            case commonConfig.LOOKUP_STUDENT_FOOD_SERVICE_ELIGIBILITY_FULL: return updateObject(state, { isLoading: false, lookupFoodServiceEligibilityFull: lookups });
            case commonConfig.LOOKUP_STUDENT_FOOD_SERVICE_ELIGIBILITY_REDUCED: return updateObject(state, { isLoading: false, lookupFoodServiceEligibilityReduced: lookups });
            case commonConfig.LOOKUP_STUDENT_FOOD_SERVICE_ELIGIBILITY_OTHER: return updateObject(state, { isLoading: false, lookupFoodServiceEligibilityOther: lookups });
            case commonConfig.LOOKUP_STUDENT_REFERRAL_TYPE: return updateObject(state, { isLoading: false, lookupStudentReferralType: lookups });
            case commonConfig.LOOKUP_STUDENT_REFERRAL_OUTCOME: return updateObject(state, { isLoading: false, lookupStudentReferralOutcome: lookups });
            case commonConfig.LOOKUP_STUDENT_VISION_STATUS: return updateObject(state, { isLoading: false, lookupVisionStatusList: lookups });
            case commonConfig.LOOKUP_STUDENT_HEARING_STATUS: return updateObject(state, { isLoading: false, lookupHearingStatusList: lookups });
            case commonConfig.LOOKUP_STUDENT_DENTAL_STATUS: return updateObject(state, { isLoading: false, lookupDentalStatusList: lookups });
            case commonConfig.LOOKUP_STUDENT_HEALTH_INSURANCE_COVERAGE: return updateObject(state, { isLoading: false, lookupHealthInsuranceCoverageList: lookups });
            case commonConfig.LOOKUP_STUDENT_DENTAL_INSURANCE_COVERAGE: return updateObject(state, { isLoading: false, lookupDentalInsuranceCoverageList: lookups });
            case commonConfig.LOOKUP_STUDENT_MEDICAL_ALERT_TYPE: return updateObject(state, { isLoading: false, lookupMedicalAlertTypeList: lookups });
            case commonConfig.LOOKUP_STUDENT_HEALTH_SCREENING_STATUS: return updateObject(state, { isLoading: false, lookupStudentHealthScreeningStatusList: lookups });
            case commonConfig.LOOKUP_STUDENT_MULTIPLE_BIRTH: return updateObject(state, { isLoading: false, lookupStudentMultipleBirthList: lookups });
            case commonConfig.LOOKUP_NON_PROMOTION_REASON: return updateObject(state, { isLoading: false, lookupNonPromotionReasonList: lookups });
            case commonConfig.LOOKUP_PROMOTION_REASON: return updateObject(state, { isLoading: false, lookupPromotionReasonList: lookups });
            case commonConfig.LOOKUP_EXIT_WITHDRAWAL_TYPE: return updateObject(state, { isLoading: false, lookupExitWithdrawalTypeList: lookups });
            case commonConfig.LOOKUP_EXIT_WITHDRAWAL_STATUS: return updateObject(state, { isLoading: false, lookupExitWithdrawalStatusList: lookups });
            case commonConfig.LOOKUP_EXIT_ENROLLMENT_STATUS: return updateObject(state, { isLoading: false, lookupEnrollmentStatusList: lookups });
            case commonConfig.LOOKUP_EXIT_ROLLING_RETENTION: return updateObject(state, { isLoading: false, lookupRollingRetentionList: lookups });
            case commonConfig.LOOKUP_ENROLLMENT_ENTRY_TYPE: return updateObject(state, { isLoading: false, lookupEnrollmentEntryTypeList: lookups });
            case commonConfig.LOOKUP_STUDENT_ENROLLMENT_GIFTED_PROGRAM: return updateObject(state, { isLoading: false, lookupEnrollmentGiftedProgramList: lookups });
            case commonConfig.LOOKUP_STUDENT_INSURANCE_TYPE: return updateObject(state, { isLoading: false, lookupStudentInsuranceTypeList: lookups });
            case commonConfig.LOOKUP_IMMUNIZATION_ROUTE: return updateObject(state, { isLoading: false, lookupStudentImmunizationRouteList: lookups });
            case commonConfig.LOOKUP_IMMUNIZATION_STATUS: return updateObject(state, { isLoading: false, lookupStudentImmunizationStatusList: lookups });
            case commonConfig.LOOKUP_INJECTION_SITE: return updateObject(state, { isLoading: false, lookupStudentInjectionSiteList: lookups });
            /*Staff section*/
            case commonConfig.LOOKUP_RELATIONSHIP: return updateObject(state, { isLoading: false, lookupPersonRelationshipList: lookups });
            case commonConfig.LOOKUP_STAFF_CERTIFICATE_ACCREDITATION_STATUS: return updateObject(state, { isLoading: false, lookupCertificateAccreditationStatusList: lookups });
            case commonConfig.LOOKUP_STAFF_CREDENTIAL_TYPE: return updateObject(state, { isLoading: false, lookupCredentialTypeList: lookups });
            case commonConfig.LOOKUP_STAFF_VISA_TYPE: return updateObject(state, { isLoading: false, lookupStaffVisaTypeList: lookups });
            case commonConfig.LOOKUP_STAFF_CREDENTIAL_TEACHING_CREDENTIAL_TYPE: return updateObject(state, { isLoading: false, lookupTeachingCredentialTypeList: lookups });
            case commonConfig.LOOKUP_STAFF_CREDENTIAL_TEACHING_CREDENTIAL_BASIS: return updateObject(state, { isLoading: false, lookupTeachingCredentialBasisList: lookups });
            case commonConfig.LOOKUP_STAFF_CREDENTIAL_CERTIFICATE_SPONSOR_TYPE: return updateObject(state, { isLoading: false, lookupStaffCertSponsorTypeList: lookups });
            case commonConfig.LOOKUP_STAFF_LEAVE_TYPE: return updateObject(state, { isLoading: false, lookupLeaveTypeList: lookups });
            case commonConfig.LOOKUP_STAFF_PERSONAL_DOCUMENT_VATIFICATION: return updateObject(state, { isLoading: false, lookupStaffPersonalDocumentVerificationList: lookups });
            case commonConfig.LOOKUP_SCHOOL_PUBLICATION_STATUS: return updateObject(state, { isLoading: false, lookupPublicationStatusList: lookups });
            case commonConfig.LOOKUP_LEAVE_STATUS_TYPE: return updateObject(state, { isLoading: false, lookupStaffLeaveStatusList: lookups });
            /*Course section*/
            case commonConfig.LOOKUP_COURSE_LEVEL_CHARACTERISTIC: return updateObject(state, { isLoading: false, lookupCourseLevelCharacteristic: lookups });
            case commonConfig.LOOKUP_COURSE_CREDIT_UNIT: return updateObject(state, { isLoading: false, lookupCourseCreditUnit: lookups });
            case commonConfig.LOOKUP_COURSE_WBL_OPPORTUNITY_TYPE: return updateObject(state, { isLoading: false, lookupCourseWblOpportunityType: lookups });
            case commonConfig.LOOKUP_ADITIONAL_CREDIT_TYPE: return updateObject(state, { isLoading: false, lookupAditionalCreditType: lookups });
            case commonConfig.LOOKUP_COURSE_GPA_APPLICABILITY: return updateObject(state, { isLoading: false, lookupCourseGpaApplicability: lookups });
            case commonConfig.LOOKUP_CURRICULUM_FRAMEWORK_TYPE: return updateObject(state, { isLoading: false, lookupCurriculumFrameworkType: lookups });
            case commonConfig.LOOKUP_COURSE_CREDIT_TYPE_EARNED: return updateObject(state, { isLoading: false, lookupCourseCreditTypeEarned: lookups });
            case commonConfig.LOOKUP_COURSE_CAREER_CLUSTER: return updateObject(state, { isLoading: false, lookupCourseCareerCluster: lookups });
            case commonConfig.LOOKUP_COURSE_BLND_LEARNING_MODEL_TYPE: return updateObject(state, { isLoading: false, lookupCourseBlndLearningModelType: lookups });
            case commonConfig.LOOKUP_COURSE_EXAM_REQUIREMENT: return updateObject(state, { isLoading: false, lookupCourseExamRequirement: lookups });
            case commonConfig.LOOKUP_ADVANCED_PLACEMENT_COURSE_CODE: return updateObject(state, { isLoading: false, lookupAdvancedPlacementCourseCode: lookups });
            case commonConfig.LOOKUP_STAFF_CERTIFICATION_TYPE: return updateObject(state, { isLoading: false, lookupCertificationTypeList: lookups });
            case commonConfig.LOOKUP_PERIOD_SCHEDULE_TYPE: return updateObject(state, { isLoading: false, lookupPeriodScheduleList: lookups });
            case commonConfig.LOOKUP_SINGLE_SEX_CLASS_STATUS: return updateObject(state, { isLoading: false, lookupSingleSexClassList: lookups });
            case commonConfig.LOOKUP_COURSE_SECTION_DELIVERY_MODE: return updateObject(state, { isLoading: false, lookupCourseDeliveryList: lookups });
            case commonConfig.LOOKUP_COURSE_SECTION_LEVEL_TYPE: return updateObject(state, { isLoading: false, lookupCourseSectionLevelTypeList: lookups });
            case commonConfig.LOOKUP_CERTIFICATE_VERIFICATION_METHOD: return updateObject(state, { isLoading: false, lookupCertificateVerificationMethodList: lookups });
            case commonConfig.LOOKUP_DOCUMENT_VERIFICATION_STATUS: return updateObject(state, { isLoading: false, lookupDocumentVerificationStatusList: lookups });
            case commonConfig.LOOKUP_COURSE_TYPE: return updateObject(state, { isLoading: false, lookupCourseType: lookups });
            case commonConfig.LOOKUP_COURSE_APPROVAL_STATUS: return updateObject(state, { isLoading: false, lookupCourseApprovalStatus: lookups });
            case commonConfig.LOOKUP_COURSE_SECTION_EXIT_TYPE: return updateObject(state, { isLoading: false, lookupCourseSectionExitType: lookups });
            case commonConfig.LOOKUP_ATTENDANCE_STATUS: return updateObject(state, { isLoading: false, lookupAttendanceStatusList: lookups });
            case commonConfig.LOOKUP_PRESENT_ATTENDANCE_CATEGORY: return updateObject(state, { isLoading: false, lookupPresentAttendanceCategoryList: lookups });
            case commonConfig.LOOKUP_ABSENT_ATTENDANCE_CATEGORY: return updateObject(state, { isLoading: false, lookupAbsentAttendanceCategoryList: lookups });
            case commonConfig.LOOKUP_COURSE_SECTION_ENTRY_TYPE: return updateObject(state, { isLoading: false, lookupEntryType: lookups });
            case commonConfig.LOOKUP_COURSE_SEQUENCE_TYPE: return updateObject(state, { isLoading: false, lookupCourseSequenceType: lookups });
            //Discipline
            case commonConfig.LOOKUP_INCIDENT_TIME_TYPE: return updateObject(state, { isLoading: false, lookupIncidentTimeType: lookups });
            case commonConfig.LOOKUP_INCIDENT_BEHAVIOUR_TYPE: return updateObject(state, { isLoading: false, lookupIncidentBehaviourType: lookups });
            case commonConfig.LOOKUP_INCIDENT_BEHAVIOUR: return updateObject(state, { isLoading: false, lookupIncidentBehaviour: lookups });
            case commonConfig.LOOKUP_WEAPON_TYPE: return updateObject(state, { isLoading: false, lookupWeaponType: lookups });
            case commonConfig.LOOKUP_INJURY_TYPE: return updateObject(state, { isLoading: false, lookupInjuryType: lookups });
            case commonConfig.LOOKUP_INCIDENT_LOCATION: return updateObject(state, { isLoading: false, lookupIncidentLocation: lookups });
            case commonConfig.LOOKUP_FIREARM_TYPE: return updateObject(state, { isLoading: false, lookupFireArmType: lookups });
            case commonConfig.LOOKUP_MULTIPLE_OFFENCE_TYPE: return updateObject(state, { isLoading: false, lookupMultipleOffenceType: lookups });
            case commonConfig.LOOKUP_DISCIPLINE_REASON: return updateObject(state, { isLoading: false, lookupDisciplineReason: lookups });
            case commonConfig.LOOKUP_DISCIPLINARY_ACTION_TAKEN: return updateObject(state, { isLoading: false, lookupDisciplinaryActionTaken: lookups });
            case commonConfig.LOOKUP_DISCIPLINE_LENGTH_DIFFERENCE: return updateObject(state, { isLoading: false, lookupDisciplineLengthDifference: lookups });
            case commonConfig.LOOKUP_IDEA_INTERIM_REMOVAL: return updateObject(state, { isLoading: false, lookupIdeaInterimRemoval: lookups });
            case commonConfig.LOOKUP_IDEA_INTERIM_REMOVAL_REASON: return updateObject(state, { isLoading: false, lookupIdeaInterimRemovalReason: lookups });
            case commonConfig.LOOKUP_FIREARM_DISCIPLINE_METHOD: return updateObject(state, { isLoading: false, lookupFirearmDisciplineMethod: lookups });
            case commonConfig.LOOKUP_IDEA_FIREARM_DISCIPLINE_METHOD: return updateObject(state, { isLoading: false, lookupIdeaFirearmDisciplineMethod: lookups });
            case commonConfig.LOOKUP_DM_FOR_DIABETIC_CHILDREN: return updateObject(state, { isLoading: false, lookupDmForDiabeticChildren: lookups });
            case commonConfig.LOOKUP_INCIDENT_PERSON_ROLE: return updateObject(state, { isLoading: false, lookupIncidentPersonRole: lookups });
            case commonConfig.LOOKUP_INCIDENT_PERSON_TYPE: return updateObject(state, { isLoading: false, lookupIncidentPersonType: lookups });
            //Assessment
            case commonConfig.LOOKUP_ASSESSMENT_ASSET_TYPE: return updateObject(state, { isLoading: false, lookupAssessmentAssetTypeList: lookups });
            case commonConfig.LOOKUP_PUBLICATION_STATUS: return updateObject(state, { isLoading: false, lookupPublicationStatusList: lookups });
            case commonConfig.LOOKUP_ACCOMMODATION_TYPE: return updateObject(state, { isLoading: false, lookupAccommodationTypeList: lookups });
            case commonConfig.LOOKUP_ACCOMMODATION_CATEGORY: return updateObject(state, { isLoading: false, lookupAccommodationCategoryList: lookups });
            case commonConfig.LOOKUP_ASSESSMENT_PURPOSE: return updateObject(state, { isLoading: false, lookupAssessmentPurposeList: lookups });
            case commonConfig.LOOKUP_ASSESSMENT_TYPE: return updateObject(state, { isLoading: false, lookupAssessmentTypeList: lookups });
            case commonConfig.LOOKUP_ASSESSMENT_TYPE_WITH_DISABILITY: return updateObject(state, { isLoading: false, lookupAssessmentTypeWithDisabilityList: lookups });
            case commonConfig.LOOKUP_ASSESSMENT_REGISTRATION_COMPLETION_STATUS: return updateObject(state, { isLoading: false, lookupAssessmentRegistrationCompletionStatusList: lookups });
            case commonConfig.LOOKUP_ASSESSMENT_PARTICIPATION: return updateObject(state, { isLoading: false, lookupAssessmentParticipationList: lookups });
            case commonConfig.LOOKUP_ASSESSMENT_REASON_NOT_TESTED: return updateObject(state, { isLoading: false, lookupAssessmentReasonNotTestedList: lookups });
            case commonConfig.LOOKUP_ASSESSMENT_REASON_NOT_COMPLETED: return updateObject(state, { isLoading: false, lookupAssessmentReasonNotCompletedList: lookups });
            case commonConfig.LOOKUP_ASSESSMENT_REPORTING_METHOD: return updateObject(state, { isLoading: false, lookupAssessmentReportingMethodList: lookups });
            case commonConfig.LOOKUP_ASSESSMENT_SESSION_TYPE: return updateObject(state, { isLoading: false, lookupAssessmentSessionType: lookups });
            case commonConfig.LOOKUP_ASSESSMENT_FORM_SEC_IDENTIFICATION_SYSTEM: return updateObject(state, { isLoading: false, lookupAssessmentFormSecIdentificationSystemList: lookups });
            case commonConfig.LOOKUP_SCORE_METRIC_TYPE: return updateObject(state, { isLoading: false, lookupScoreMetricTypeList: lookups });
            case commonConfig.LOOKUP_ASSESSMENT_OUTCOME: return updateObject(state, { isLoading: false, lookupAssessmentOutcomeList: lookups });
            case commonConfig.LOOKUP_ASSESSMENT_RESULT_SCORE_TYPE: return updateObject(state, { isLoading: false, lookupResultScoreTypeList: lookups });
            case commonConfig.LOOKUP_ANNOUNCEMENT_TYPE: return updateObject(state, { isLoading: false, lookupAnnouncementType: lookups });
            case commonConfig.LOOKUP_PERSON_OCCUPATION_TYPE: return updateObject(state, { isLoading: false, lookupPersonOccupationTypeList: lookups });
            case commonConfig.LOOKUP_ASSIGNMENT_AGGREGATION: return updateObject(state, { isLoading: false, lookupAssignmentAggregationList: lookups });
            case commonConfig.LOOKUP_ASSIGNMENT_GRADING_TYPE: return updateObject(state, { isLoading: false, lookupAssignmentGradingTypeList: lookups });
            case commonConfig.LOOKUP_ADA_ELIGIBILITY_TYPE: return updateObject(state, { isLoading: false, lookupAdaEligibilityTypeList: lookups });
            case commonConfig.LOOKUP_NAME_SUFFIX_TYPE: return updateObject(state, { isLoading: false, lookupNameSuffixList: lookups });
            // Transport
            case commonConfig.LOOKUP_VEHICLE_TYPE: return updateObject(state, { isLoading: false, lookupVehicleTypeList: lookups });
            case commonConfig.LOOKUP_OWNERSHIP_TYPE: return updateObject(state, { isLoading: false, lookupOwnershipTypeList: lookups });
            case commonConfig.LOOKUP_SEAT_TYPE: return updateObject(state, { isLoading: false, lookupSeatTypeList: lookups });
            case commonConfig.LOOKUP_TRANSPORTATION_JOB_TYPE: return updateObject(state, { isLoading: false, transportationJobTypeList: lookups });
            case commonConfig.LOOKUP_TRANSPORTATION_STAFF_ROLE_TYPE: return updateObject(state, { isLoading: false, transportationStaffRoleList: lookups });
            case commonConfig.LOOKUP_TRANSPORTATION_REQUEST_TYPE: return updateObject(state, { isLoading: false, transportationRequestTypeList: lookups });
            case commonConfig.LOOKUP_TRANSPORTATION_VEHICLE_LOG_TYPE: return updateObject(state, { isLoading: false, lookupVehicleLogTypeList: lookups });
            case commonConfig.LOOKUP_TRANSPORTATION_VEHICLE_IMAGE_TYPE: return updateObject(state, { isLoading: false, lookupVehicleImageTypeList: lookups });
            // Billing
            case commonConfig.LOOKUP_BILLING_TYPE: return updateObject(state, { isLoading: false, lookupBillingType: lookups });
            case commonConfig.LOOKUP_BILLING_FREQUENCY_TYPE: return updateObject(state, { isLoading: false, lookupBillingFrequencyType: lookups });
            case commonConfig.LOOKUP_PAYMENT_TYPE: return updateObject(state, { isLoading: false, lookupPaymentType: lookups });
            case commonConfig.LOOKUP_PAYMENT_STATUS: return updateObject(state, { isLoading: false, lookupStatusType: lookups });
            case commonConfig.LOOKUP_GATEWAY_TYPE: return updateObject(state, { isLoading: false, lookupGatewayTypeList: lookups });
            case commonConfig.LOOKUP_STU_COLLECT_SUMMARY_RPT_SORTORDER: return updateObject(state, { isLoading: false, lookupStuCollectSummaryRptSortOrder: lookups });
            default: return updateObject(state, { isLoading: false })
        }
    }
}
const handleSetNotify = (state, type, message) => {
    return updateObject(state, {
        notifyType: type,
        notifyMessage: message
    });
}
const reducerShared = (state = initialState, action) => {
    state = state || initialState;
    switch (action.type) {
        case 'COMMON_LIST': return handleSuccess(state, action, _.camelCase(`${action.title}_list`));
        case 'COMMON_SAVE': return handleSuccess(state, action, _.camelCase(`save_${action.title}`));
        case 'COMMON_GET': return handleSuccess(state, action, _.camelCase(`get_${action.title}`));
        case 'COMMON_DELETE': return handleSuccess(state, action, _.camelCase(`${action.title}_delete`));
        case actionTypesShared.GET_LOOKUP_SUCESS: return storeLookupToSession(state, action);
        case actionTypesShared.SHOW_NOTIFY: return handleSetNotify(state, action.notifyType, action.notifyMessage);
        case actionTypesShared.REMOVE_NOTIFY: return handleSetNotify(state, 'success', '');
        case actionTypesShared.HANDLE_COMMON_FAIL: return handleFail(state);
        case commonConfig.COMMON_STORE_RESET: return handleSuccess(state, action, action.title);
        default: return state;
    }
}
export default reducerShared;
