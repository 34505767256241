const currentHost = 'development';
const genarateNewToken = true;
const hostArray = {
    'mp-web-local': {
        home: 'https://localhost:5001',
        transport: 'https://localhost:5006',
        billing: 'https://localhost:5004',
        lms: 'https://localhost:5002'
    },
    'mp-web-development': {
        home: 'https://app.development.us.markerspro.com',
        transport: 'https://app-transport.development.us.markerspro.com',
        billing: 'https://app-billing.development.us.markerspro.com',
        lms: 'https://app-lms.development.us.markerspro.com'
    },
    'mp-web-qa': {
        home: 'https://app.qa.us.markerspro.com',
        transport: 'https://app-transport.qa.us.markerspro.com',
        billing: 'https://app-billing.qa.us.markerspro.com',
        lms: 'https://app-lms.qa.us.markerspro.com'
    },
    'mp-web-stage': {
        home: 'https://app.stage.us.markerspro.com',
        transport: 'https://app-transport.stage.us.markerspro.com',
        billing: 'https://app-billing.stage.us.markerspro.com',
        lms: 'https://app-lms.stage.us.markerspro.com'
    },
    'mp-web-production': {
        home: 'https://app.production.us.markerspro.com',
        transport: 'https://app-transport.production.us.markerspro.com',
        billing: 'https://app-billing.production.us.markerspro.com',
        lms: 'https://app-lms.production.us.markerspro.com'
    }
}
export {
    currentHost,
    genarateNewToken,
    hostArray
}