import { Route } from 'react-router';
import React from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { sharedActionCreators } from '../components/shared/store/sharedAction';
import asyncComponent from './asyncComponent';
import sessionStore from '../../src/components/common/component/sessionStoreComponent';
import { hostArray } from '../hostConfig';
const routeList = [
    'studentPayment',
    'studentPayment/paymentDetails',
    'studentPayment/paymentDetails/pastPayment',
    'studentPayment/paymentDetails/duePayment',
    'studentPayment/paymentDetails/pendingCancelPayment',
    'payableDate',
    'billingHead',
    'studentPayable',
    'feeStructure',
    'paymentHistory',
    'gatewayIntegration',
    'pastDueReport',
    'studentCollectionReport'
]
let routeState = {}
for (let i = 0; i < routeList.length; i++) {
    routeState = {
        ...routeState,
        [routeList[i]]: asyncComponent(() =>
            import(`../components/view/${routeList[i].indexOf('studentPayment/paymentDetails') !== -1 ? 'studentPayment/paymentDetails' : routeList[i]}/homeComponent`).then(module => module.default)
        )
    }
}
const MarkersCommonRoute = asyncComponent(() =>
    import('../components/common/component/markersCommonRoute').then(module => module.default)
);
export class PrivateRoute extends Route {
    render() {
        if (new sessionStore().returnSessionToken() == null) {
            // return <Redirect to="/sso" />;
            window.location.href = hostArray[new sessionStore().returnHostEnvironment()].home + '/sso';
        }
        return (
            <div>
                {routeList.map((route, index) => {
                    return <Route exact path={`/billing/${route}`} component={routeState[route]} key={index} />
                })}
                <Route exact path="/billing/commonRoute" component={MarkersCommonRoute} />
            </div>
        )
    }
}
export default connect(
    (state) => { const { sharedStore } = state; return { state: { sharedStore } }; },
    (dispatch) => { return { actions: bindActionCreators(Object.assign({}, sharedActionCreators), dispatch) }; }
)(PrivateRoute)