import React, { Component } from 'react';
import { Redirect} from 'react-router-dom';
import { connect } from 'react-redux';
import {
    ExecuteArgs, commonConfig, i18n, actions, actionsCommon, sessionStore, CommonFuncs
} from '../commonLoginindex';
import Swal from 'sweetalert2';
import _ from 'lodash';
const commonFuncObj = new CommonFuncs();
const sessionObj = new sessionStore();
class SsoComponent extends Component {
   
    constructor(props) {
        super(props);
        this.state = {
            fields: {
                username: '',
                password: '.'
            },
            getSchoolBasedRoleListByUserIdFlag: false,
            schoolListFlag: false,
            userSchoolRoles: [],
            menuList: [],
            showSelectProfileFlag: false,
            redirectUrl: 'https://sso-development.markerspro.com/',
            redirectFlag: false,
            getUrlFlag:false
        }
    }
   
    componentDidMount = () => {
        var n = sessionStorage.length;
        while (n--) {
            var key = sessionStorage.key(n);
            sessionStorage.removeItem(key);
        }
        let qureyParams = (new URL(document.location)).searchParams;
        let ssoToken = qureyParams.get("ssotoken");

        let ssoEmail = null;
        if (ssoToken !== null) {
            let selectedLanguage = qureyParams.get("lng");
            if (selectedLanguage === null)
                selectedLanguage = "en";

            new sessionStore().storeSecureData(commonConfig.SESSION_LANGUAGE, selectedLanguage);
            i18n.changeLanguage(selectedLanguage);

            new sessionStore().storeSecureData(commonConfig.SESSION_SSO_TOKEN, ssoToken);
            ssoEmail = qureyParams.get("ssoemail");
            this.setState({
                fields: {
                    username: ssoEmail,
                    password:'.'
                }, getValidateFlag: true
            }, () => {
                let executedArgument = new ExecuteArgs(commonConfig.COMPONENT_LOGIN,
                    commonConfig.COMPONENT_LOGIN_SSO_VALIDATE_SAVE, commonConfig.METHOD_TYPE_POST,
                    this.state.fields);
                this.props.onValidateLogin(executedArgument);
            })
        }
        else {
            //this.setState({ redirectFlag:true })
            this.setState({ getUrlFlag: true }, () => {
                
                this.props.onGetLoginUrl();
            })
            
        }
    }
    componentWillReceiveProps(nextProps) {
        if (nextProps.isAuthenticated && !nextProps.isResourcesLoaded && this.state.getValidateFlag) {
            let executedArgument = new ExecuteArgs(commonConfig.COMPONENT_RESOURCE,
                commonConfig.COMPONENT_RESOURCE_GET_USER_LAST_SESSION,
                commonConfig.METHOD_TYPE_GET);
            this.props.onGetLastSession(executedArgument);
            this.setState({ getValidateFlag: false, schoolListFlag: true, isLoading: true }, () => {
                let executedArgument = new ExecuteArgs(commonConfig.COMPONENT_RESOURCE,
                    commonConfig.COMPONENT_RESOURCE_GET_RESOURCES + new sessionStore().returnLanguage(),
                    commonConfig.METHOD_TYPE_GET);
                this.props.onGetResources(executedArgument);
                executedArgument = new ExecuteArgs(commonConfig.COMPONENT_SCHOOL,
                    commonConfig.COMPONENT_SCHOOL_SESSION_GET_FOR_USER,
                    commonConfig.METHOD_TYPE_GET);
                this.props.onGetAllSchoolForUser(executedArgument);
                this.props.onGetLoginUrl();
            });
        } 
        if (nextProps.isAuthenticated === null && this.state.getValidateFlag) {

            Swal.fire({
                title: "MarkersPro!",
                text: i18n.t('unAuthorizedAccess'),
                type: 'error',
            }).then(() => {
                if (new sessionStore().returnLoginUrl())
                    this.setState({ redirectFlag: true, redirectUrl: new sessionStore().returnLoginUrl(), getValidateFlag: false })
                else
                    this.setState({ redirectFlag: true, redirectUrl: window.location.protocol + '//' + window.location.host, getValidateFlag: false })
            })
        }
        if (nextProps.getLoginSsoUrl && this.state.getUrlFlag) {
            this.setState({ redirectFlag: true, redirectUrl: nextProps.getLoginSsoUrl.loginUrl, getUrlFlag: false })
        }
        if (nextProps.getLoginSsoUrl === undefined && this.state.getUrlFlag) {

            this.setState({ redirectFlag: true, redirectUrl: window.location.protocol + '//' + window.location.host, getUrlFlag: false })
        }
        if (nextProps.schoolTimeZoneNameList && nextProps.schoolTimeZoneNameList.length > 0) {
            new sessionStore().storeSecureData(commonConfig.SESSION_TIME_ZONE, nextProps.schoolTimeZoneNameList[0].timeZoneName);
            this.props.onResetCommonProps({ name: 'schoolTimeZoneNameList', data: null });
        }
        if (nextProps.schoolList && nextProps.schoolList.length > 0 && this.state.schoolListFlag) {
            let setStateObject = {
                schoolList: commonFuncObj.removeDuplicates(nextProps.schoolList, 'schoolId'),
                schoolSessionList: nextProps.schoolList,
                //getSchoolFlag: false
            }
            let lastSessionObject = new sessionStore().returnLastUserSessionDetails();
            if (lastSessionObject && Object.keys(lastSessionObject).length > 0 && !!lastSessionObject.schoolId) {
                if (!new sessionStore().returnSchoolID()) {
                    new sessionStore().storeSecureData(commonConfig.SESSION_USER_ID, lastSessionObject.userId);
                    new sessionStore().storeSecureData(commonConfig.SESSION_SCHOOLID, lastSessionObject.schoolId);
                }
                let lastSchoolObject = {};
                if (lastSessionObject.schoolSessionId)
                    lastSchoolObject = _.find(setStateObject.schoolSessionList, ['id', lastSessionObject.schoolSessionId]);
                else
                    lastSchoolObject = _.find(setStateObject.schoolSessionList, ['schoolId', lastSessionObject.schoolId]);
                new sessionStore().storeSecureData(commonConfig.SESSION_SCHOOLNAME, lastSchoolObject.schoolname);
                new sessionStore().storeSecureData(commonConfig.SESSION_SCHOOLYEAR, lastSessionObject.schoolYear);
                new sessionStore().storeSecureData(commonConfig.SESSION_MARKINGPERIOD_ID, lastSessionObject.markingPeriodId);
                new sessionStore().storeSecureData(commonConfig.SESSION_ID, lastSessionObject.schoolSessionId);
                new sessionStore().storeSecureData(commonConfig.SESSION_SCHOOLSESSION, lastSchoolObject.title);
            } else {
                if (setStateObject.schoolSessionList.length > 0) {
                    var objectSelected = setStateObject.schoolSessionList[0];
                    if (objectSelected && Object.keys(objectSelected).length !== 0) {
                        if (!new sessionStore().returnSchoolID()) {
                            new sessionStore().storeSecureData(commonConfig.SESSION_USER_ID, objectSelected.userId);
                            if (!!objectSelected.schoolId) {
                                sessionObj.storeSecureData(commonConfig.SESSION_SCHOOLID, objectSelected.schoolId);
                                new sessionStore().storeSecureData(commonConfig.SESSION_SCHOOLNAME, objectSelected.schoolname);
                                new sessionStore().storeSecureData(commonConfig.SESSION_SCHOOLYEAR, objectSelected.schoolYear);
                                new sessionStore().storeSecureData(commonConfig.SESSION_MARKINGPERIOD_ID, objectSelected.markingPeriodId);
                                sessionObj.storeSecureData(commonConfig.SESSION_ID, objectSelected.id);
                                new sessionStore().storeSecureData(commonConfig.SESSION_SCHOOLSESSION, objectSelected.title);
                            }
                        }
                    }
                }
            }
            if (new sessionStore().returnSchoolID()) {
                this.setState({ schoolListFlag: false, getSchoolBasedRoleListByUserIdFlag: true }, () => {
                    let executedArgument = new ExecuteArgs(commonConfig.COMPONENT_SCHOOL,
                        commonConfig.COMPONENT_GET_SCHOOL_BASED_ROLE_LIST_BY_USERID,
                        commonConfig.METHOD_TYPE_POST, parseInt(new sessionStore().returnUserID(), 10));
                    this.props.onGetSchoolBasedRoleListByUserId(executedArgument);
                    this.props.onResetCommonProps({ name: 'schoolTimeZoneNameList', data: null });
                    executedArgument = new ExecuteArgs(commonConfig.COMPONENT_SCHOOL,
                        commonConfig.COMPONENT_GET_SCHOOL_TIMEZONE_NAME + parseInt(new sessionStore().returnSchoolID(), 10),
                        commonConfig.METHOD_TYPE_GET);
                    this.props.onGetTimeZone(executedArgument);
                })
            }
        }
        if (nextProps.schoolBasedRoleList && nextProps.schoolBasedRoleList.length > 0 && this.state.getSchoolBasedRoleListByUserIdFlag) {
            let schoolBasedRoleSession = [];
            let selectedSchoolRoles = [];
            nextProps.schoolBasedRoleList.forEach((data, index) => {
                if (data.schoolId === new sessionStore().returnSchoolID()) {
                    selectedSchoolRoles.push({ schoolId: data.schoolId, schoolRole: data.lookupSchoolRoleTitle, lookupRoleId: data.lookupSchoolRole, roleId: data.id, studentId: data.studentId, staffId: data.staffId });
                }
                schoolBasedRoleSession.push({ schoolId: data.schoolId, schoolRole: data.lookupSchoolRoleTitle, roleId: data.id });
            })
            if (selectedSchoolRoles.length > 1) {
                let lastSessionObject = new sessionStore().returnLastUserSessionDetails();
                if (lastSessionObject && Object.keys(lastSessionObject).length > 0 && !!lastSessionObject.schoolId) {
                    let schoolRoleObj = {};
                    schoolRoleObj = _.find(selectedSchoolRoles, ['roleId', lastSessionObject.schoolDefaultRoleID]);
                    if (schoolRoleObj !== undefined && Object.keys(schoolRoleObj).length > 0 ) {
                        new sessionStore().storeSecureData(commonConfig.SESSION_USER_ROLE, lastSessionObject.schoolDefaultRoleID);
                        new sessionStore().storeSecureData(commonConfig.SESSION_LOGGED_USER_ROLE, lastSessionObject.lookupRoleId);
                        let executedArgument = new ExecuteArgs(commonConfig.COMPONENT_RESOURCE,
                            commonConfig.COMPONENT_GET_MENUS + new sessionStore().returnSchoolID() + '&schoolRoleId=' + new sessionStore().returnUserRole(),
                            commonConfig.METHOD_TYPE_GET);
                        this.props.onGetAllMenuSuccess(executedArgument);
                    }
                }
                new sessionStore().storeSecureData(commonConfig.SESSION_SCHOOL_BASED_MULTI_ROLE_LIST, JSON.stringify(selectedSchoolRoles));
                if (selectedSchoolRoles[0].staffId)
                    new sessionStore().storeSecureData(commonConfig.SESSION_STAFFID, selectedSchoolRoles[0].staffId);
                this.setState({ getSchoolBasedRoleListByUserIdFlag: false, showSelectProfileFlag: true, isLoading: false }, () => {
                    new sessionStore().storeSecureData(commonConfig.SESSION_SCHOOL_BASED_ROLE_LIST, JSON.stringify(schoolBasedRoleSession));
                })
            }
            if (selectedSchoolRoles.length === 1) {
                new sessionStore().storeSecureData(commonConfig.SESSION_LOGGED_USER_ROLE, selectedSchoolRoles[0].lookupRoleId);
                new sessionStore().storeSecureData(commonConfig.SESSION_USER_ROLE, selectedSchoolRoles[0].roleId);
                if (selectedSchoolRoles[0].studentGradeLevelId)
                    new sessionStore().storeSecureData(commonConfig.SESSION_STUDENT_GRADE_LEVEL_ID, selectedSchoolRoles[0].studentGradeLevelId);
                if (selectedSchoolRoles[0].studentId)
                    new sessionStore().storeSecureData(commonConfig.SESSION_STUDENTID, selectedSchoolRoles[0].studentId);
                if (selectedSchoolRoles[0].staffId)
                    new sessionStore().storeSecureData(commonConfig.SESSION_STAFFID, selectedSchoolRoles[0].staffId);
                let executedArgument = new ExecuteArgs(commonConfig.COMPONENT_RESOURCE,
                    commonConfig.COMPONENT_GET_MENUS + new sessionStore().returnSchoolID() + '&schoolRoleId=' + new sessionStore().returnUserRole(),
                    commonConfig.METHOD_TYPE_GET);
                this.props.onGetAllMenuSuccess(executedArgument);
                this.setState({ getSchoolBasedRoleListByUserIdFlag: false, isLoading: false, showSelectProfileFlag: true }, () => {
                    new sessionStore().storeSecureData(commonConfig.SESSION_SCHOOL_BASED_ROLE_LIST, JSON.stringify(schoolBasedRoleSession));
                })
            }
        }
        if (nextProps.isLoadDistrictMenuCallRequired && nextProps.isAuthenticated && nextProps.isResourcesLoaded) {
            let executedArgument = new ExecuteArgs(commonConfig.COMPONENT_RESOURCE,
                commonConfig.COMPONENT_GET_MENUS + new sessionStore().returnSchoolID() + '&schoolRoleId=' + new sessionStore().returnUserRole(),
                commonConfig.METHOD_TYPE_GET);
            this.props.onGetAllMenuSuccess(executedArgument);
        }
   }
    
    render() {
        //let { loggingIn, loginError, isLoginPending } = this.props;
        if (this.props.isAuthenticated && this.props.isResourcesLoaded && new sessionStore().returnSchoolID()
            && this.state.showSelectProfileFlag) {
            return (<Redirect to="/dashboard" />)
        }
        if (this.state.redirectFlag) {
            window.location.href=this.state.redirectUrl;
        }
        return (
            <div>
                <div className="container">
                    <div className="row">
                        <div className="general-loader">
                            <span>
                                <div className="loader-N">
                                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 296.881 258.606">
                                    <g id="Group_159" transform="translate(-0.001 -0.001)">
                                        <path id="Path_1" d="M651.855,744.721l-63.137,40.948L440.28,689.639v161.22h54.792V790.242l93.534,60.664.111-.171.111.171,63.026-40.876Z" transform="translate(-440.275 -592.299)" fill="#0e67b2" />
                                        <path id="Path_2" d="M588.721,748.516l-148.331-96.2-.111.171V717.55l148.331,96.2.111-.172.111.172,93.535-60.664V910.9H737.16V652.491Z" transform="translate(-440.278 -652.315)" fill="#001e33" />
                                    </g>
                                </svg>
                                </div>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        token: state.loginStore.token,
        isAuthenticated: state.loginStore.isAuthenticated,
        loggingIn: state.loginStore.isAuthenticated,
        loginError: state.loginStore.errorMessage,
        isLoginPending: state.loginStore.isLoginPending,
        isResourcesLoaded: state.loginStore.isResourcesLoaded,
        isLoadDistrictMenuCallRequired: (state.commonStore.schoolList && state.commonStore.schoolList.length === 0) ? true : false,
        schoolList: state.commonStore.schoolList,
        menuList: state.loginStore.menuList,
        schoolBasedRoleList: state.commonStore.schoolBasedRoleList,
        getLoginSsoUrl: state.loginStore.getLoginSsoUrl,
        schoolTimeZoneNameList: state.commonStore.schoolTimeZoneNameList
    };
};
const mapDispatchToProps = dispatch => {
    return {
        onGetAllSchoolForUser: (executeArgs) => dispatch(actionsCommon.callCommonListApi(executeArgs, '')),
        onValidateLogin: (executeArgs) => dispatch(actions.validateLogin(executeArgs)),
        onGetLoginUrl:() => dispatch(actions.getLoginUrl()),
        onGetResources: (executeArgs) => dispatch(actions.getResourceDetails(executeArgs)),
        onGetLastSession: (executeArgs) => dispatch(actions.getLastSessionOfaUser(executeArgs)),
        onGetLookups: (categoryType) => dispatch(actions.callLookupApi(categoryType)),
        onGetTimeZone: (executeArgs) => dispatch(actionsCommon.callCommonListApi(executeArgs, 'Time Zone Name')),
        onResetCommonProps: (executeArgs) => dispatch(actionsCommon.commonStoreStateReset(executeArgs)),
        onGetAllMenuSuccess: (executeArgs) => dispatch(actions.getAllMenus(executeArgs)),
        onGetSchoolBasedRoleListByUserId: (executeArgs) => dispatch(actionsCommon.callCommonListApi(executeArgs, 'Based Role')),
        onResetLoginProps: (executeArgs) => dispatch(actions.loginStoreStateReset(executeArgs))
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(SsoComponent);
