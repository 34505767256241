import React, { Component } from 'react';
import CommonFuncs from './markersCommonFunctionComponent';
import { MarkersMultiLingual } from './markersMultiLingualComponent';
const commonFuncObj = new CommonFuncs();
const langObj = new MarkersMultiLingual();
const getFileTypeValid = (selectedFileList, fileType) => {
    let fileError = 0;
    if (selectedFileList.length !== 0) {
        for (let i = 0; i < selectedFileList.length; i++) {
            let selectedFileType = selectedFileList[i].name.split('.');
            let extension = selectedFileType.pop().toLowerCase();
            if (fileType.toLowerCase().indexOf(extension) === -1) {
                fileError++;
            }
        }
    }
    return fileError;
}
const getFileSizeValid = (selectedFileList, fileMaxSize, fileMinSize) => {
    let fileError = 0;
    if (selectedFileList.length !== 0) {
        for (let i = 0; i < selectedFileList.length; i++) {
            let currentSize = selectedFileList[i].size;
            if ((parseInt(fileMinSize) > parseInt(currentSize)) || (parseInt(currentSize) > parseInt(fileMaxSize))) {
                fileError++;
            }
        }
    }
    return fileError;
}
class Validation extends Component {
    constructor(props) {
        super(props);
        this.functionValidate = this.functionValidate.bind(this);
    }
    //This function is used for calling parent validation function.
    functionValidate() {
        this.props.returnValidation();
    }
    //Checking all required fields & return the response.
    fieldValidation = (dataFields, requiredFields) => {
        let response = 1;
        requiredFields.map(function (val, index) {
            switch (val.fieldType) {
                case 'default':
                    if (dataFields[val.fieldName] === '' || dataFields[val.fieldName] === null || (dataFields[val.fieldName] && dataFields[val.fieldName].length === 0) || (!!val.minLength && dataFields[val.fieldName].length < val.minLength))
                        response = 0;
                    else if (!!val.regExp && !specialCharExp.test(dataFields[val.fieldName]))
                        response = 0;
                    break;
                case 'text':
                    if ((dataFields[val.fieldName]) === '' || (dataFields[val.fieldName]) === null)
                        response = 0;
                    else if ((dataFields[val.fieldName].length) > 256)
                        response = 0;
                    break;
                case 'toggleDependent':
                    //use this case when the field is required based on the toggle
                    if ((dataFields[val.toggle]) && dataFields[val.fieldName] === null || dataFields[val.fieldName] === '')
                        response = 0;
                    break;
                case 'date':
                    if ((dataFields[val.fieldName]) === '' || (dataFields[val.fieldName]) === null)
                        response = 0;
                    break;
                case 'dateFieldNotRequired':
                    //use this,only when the start date is mandatory and end date is not mandatory
                    if (dataFields[val.fieldName] !== null && dataFields[val.fieldName] !== '' && (dataFields[val.fieldName]) < (dataFields[val.associatedField]))
                        response = 0;
                    break;
                case 'dateComp':
                    if ((dataFields[val.fieldName]) === '' || (dataFields[val.fieldName]) === null || (dataFields[val.associatedField]) === null)
                        response = 0;
                    else if (dataFields[val.fieldName] < dataFields[val.associatedField])
                        response = 0;
                    break;
                case 'assDateComp':
                    if ((dataFields[val.fieldName]) != '' || (dataFields[val.fieldName]) !== null || (dataFields[val.associatedField]) !== null) {
                        if (dataFields[val.fieldName] < dataFields[val.associatedField]) {
                            response = 0;
                        }
                    }

                case 'yearComp':
                    if (dataFields[val.fieldName] !== null && dataFields[val.fieldName] !== '' && (dataFields[val.fieldName]) < (dataFields[val.associatedField]))
                        response = 0;
                    break;
                case 'time':
                    if ((dataFields[val.fieldName]) === '' || (dataFields[val.fieldName]) === null)
                        response = 0;
                    else if (commonFuncObj.get24hTime(dataFields[val.fieldName]) <= commonFuncObj.get24hTime(dataFields[val.associatedField]))
                        response = 0;
                    break;
                case 'timeNotRequired':
                    if (((dataFields[val.associatedField]) !== '' && (dataFields[val.fieldName]) === '') || ((dataFields[val.associatedField]) === '' && (dataFields[val.fieldName]) !== ''))
                        response = 0;
                    else if (((dataFields[val.associatedField]) !== null && (dataFields[val.fieldName]) === null) || ((dataFields[val.associatedField]) === null && (dataFields[val.fieldName]) !== null))
                        response = 0;
                    else if (dataFields[val.fieldName] !== '' && dataFields[val.fieldName] !== null && commonFuncObj.get24hTime(dataFields[val.fieldName]) <= commonFuncObj.get24hTime(dataFields[val.associatedField]))
                        response = 0;
                    break;
                case 'timeCompNotRequired':
                    if (dataFields[val.fieldName] !== '' && dataFields[val.fieldName] !== null && commonFuncObj.get24hTime(dataFields[val.fieldName]) <= commonFuncObj.get24hTime(dataFields[val.associatedField]))
                        response = 0;
                    break;
                case 'dateCompNotRequired':
                    if (((dataFields[val.associatedField]) !== '' && (dataFields[val.fieldName])) === '' || ((dataFields[val.associatedField]) === '' && (dataFields[val.fieldName]) !== ''))
                        response = 0;
                    else if (((dataFields[val.associatedField]) !== null && (dataFields[val.fieldName])) === null || ((dataFields[val.associatedField]) === null && (dataFields[val.fieldName]) !== null))
                        response = 0;
                    else if (dataFields[val.fieldName] !== null && dataFields[val.fieldName] !== '' && (dataFields[val.fieldName]) < (dataFields[val.associatedField]))
                        response = 0;
                    break;
                case 'countCompNotRequired':
                    if (dataFields[val.fieldName] === '' || dataFields[val.fieldName] === null || (dataFields[val.fieldName]) < (dataFields[val.associatedField]))
                        response = 0;
                    break;
                case 'countNotRequired':
                    if ((dataFields[val.fieldName] !== '') && (dataFields[val.fieldName]) < (dataFields[val.associatedField])) {
                        response = 0;
                    }
                    break;
                case 'regExp':
                    var specialCharExp = val.regExp;
                    var returnStrSpecialCharExp = specialCharExp.test(dataFields[val.fieldName]);
                    if (dataFields[val.fieldName] === '' || dataFields[val.fieldName] === null || returnStrSpecialCharExp === false || (!!val.minLength && dataFields[val.fieldName].length < val.minLength))
                        response = 0;
                    break;
                case 'baseGrade':
                    var specialCharExp = val.regExp;
                    var returnStrSpecialCharExp = specialCharExp.test(dataFields[val.fieldName]);
                    if (dataFields[val.fieldName] !== null && returnStrSpecialCharExp === false)
                        response = 0;
                    break;
                case 'regExpNotRequired':
                    if (!!dataFields[val.fieldName] && !!val.regExp && !val.regExp.test(dataFields[val.fieldName]))
                        response = 0;
                    break;
                case 'minute':
                    if ((dataFields[val.fieldName]) !== null && (dataFields[val.fieldName].toString().length) > 4)
                        response = 0;
                    break;
                case 'url':
                    var pattern = new RegExp('^(https?:\\/\\/)?' +
                        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' +
                        '((\\d{1,3}\\.){3}\\d{1,3}))' +
                        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' +
                        '(\\?[;&a-z\\d%_.~+=-]*)?' +
                        '(\\#[-a-z\\d_]*)?$', 'i');
                    var returnStr = pattern.test(dataFields[val.fieldName]);
                    if (dataFields[val.fieldName] === '' || dataFields[val.fieldName] === null || returnStr === false)
                        response = 0;
                    break;
                case 'email':
                    var emailExp = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                    var returnStr1 = emailExp.test(dataFields[val.fieldName]);
                    if (dataFields[val.fieldName] === '' || dataFields[val.fieldName] === null || returnStr1 === false)
                        response = 0;
                    break;
                case 'number':
                    if (dataFields[val.fieldName] !== null && dataFields[val.fieldName] !== '' && dataFields[val.fieldName] < 24 || dataFields[val.fieldName] > 42)
                        response = 0;
                    break;
                case 'numberfield':
                    if (isNaN(dataFields[val.fieldName]) || parseInt(dataFields[val.fieldName], 10) === 0)
                        response = 0;
                    break;
                case 'checkbox':
                    if (dataFields[val.fieldName] === false)
                        response = 0;
                    break;
                case 'file':
                    if (!!dataFields[val.fieldName] && dataFields[val.fieldName].length > 0) {
                        const selectedFileList = dataFields[val.fieldName];
                        const typeValid = getFileTypeValid(selectedFileList, val.fileType);
                        if (typeValid !== 0) {
                            response = 0;
                        }
                        const sizeValid = getFileSizeValid(selectedFileList, val.fileMaxSize, val.fileMinSize);
                        if (sizeValid !== 0) {
                            response = 0;
                        }
                    } else {
                        response = 0;
                    }
                    break;
                case 'onlyFileType':
                    if (!!dataFields[val.fieldName] && dataFields[val.fieldName].length > 0) {
                        const selectedFileList = dataFields[val.fieldName];
                        const typeValid = getFileTypeValid(selectedFileList, val.fileType);
                        if (typeValid !== 0) {
                            response = 0;
                        }
                        const sizeValid = getFileSizeValid(selectedFileList, val.fileMaxSize, val.fileMinSize);
                        if (sizeValid !== 0) {
                            response = 0;
                        }
                    }
                    break;
                case 'repeatField':
                    if (dataFields[val.fieldName] === '' || dataFields[val.fieldName] !== dataFields[val.parentFieldName])
                        response = 0;
                    break;
                case 'password':
                    var specialCharExp = val.regExp;
                    var returnStrSpecialCharExp = specialCharExp.test(dataFields[val.fieldName]);
                    if (dataFields[val.fieldName] === '' || dataFields[val.fieldName] === null || returnStrSpecialCharExp === false || (!!val.minLength && dataFields[val.fieldName].length < val.minLength))
                        response = 0;
                    break;
            }
        });
        return response;
    }
    //Dynamic validation function for various input types & other UI elements.
    getValidation() {
        switch (this.props.data.validationType) {
            case 'field':
            case 'selectfield':
                if (this.props.data.isError === 1) {
                    return <div>
                        {this.props.data.value === '' || this.props.data.value === null || this.props.data.value === undefined || this.props.data.value.length === 0
                            ?
                            <div className="alert alert-danger m-t-5" role="alert" id={(this.props.data.errorClass) ? this.props.data.errorClass : ''}>
                                <i className="icon-warning f-s-13"></i> {langObj.returnLanguage('pleaseString')} {this.props.data.validationType === 'selectfield' ? 'Select' : 'Enter'}
                                {(this.props.data.field) ? ' ' + this.props.data.field : ''}
                            </div>
                            :
                            !!this.props.data.minLength && this.props.data.value.length < this.props.data.minLength ?
                                <div className="alert alert-danger m-t-5" role="alert" id={(this.props.data.errorClass) ? this.props.data.errorClass : ''}>
                                    <i className="icon-warning f-s-13"></i> {langObj.returnLanguage('pleaseEnterMinimum')} {this.props.data.minLength} Characters
                                </div>
                                :
                                this.props.data.value.length > 256 ?
                                    <div className="alert alert-danger m-t-5" role="alert" id={(this.props.data.errorClass) ? this.props.data.errorClass : ''}>
                                        <i className="icon-warning f-s-13"></i>
                                        {langObj.returnLanguage('enterValid')}{' ' + this.props.data.field + '.' + ' ' + this.props.data.field + ' ' + 'should not be more than 256 characters'}
                                    </div>
                                    :
                                    (!!this.props.data.regExp && this.props.data.regExp.test(this.props.data.value)) &&
                                    <div className="alert alert-danger m-t-5" role="alert" id={(this.props.data.errorClass) ? this.props.data.errorClass : ''}>
                                        <i className="icon-warning f-s-13"></i> {langObj.returnLanguage('enterValid')}{(this.props.data.field) ? ' ' + this.props.data.field : ''}
                                    </div>
                        }
                    </div>
                }
                break;
            case 'identifierLookupDuplicate':
                if (this.props.data.isError === 1) {
                    let duplicateArrays = this.props.data.schoolIdentifierList.filter(arr => arr.lookupSchoolIdentificationSystem === this.props.data.value)
                    return <div>
                        {this.props.data.value === '' || this.props.data.value === null || this.props.data.value.length === 0
                            ?
                            <div className="alert alert-danger m-t-5" role="alert" id={(this.props.data.errorClass) ? this.props.data.errorClass : ''}>
                                <i className="icon-warning f-s-13"></i> {langObj.returnLanguage('pleaseString')} {this.props.data.validationType === 'identifierLookupDuplicate' ? 'Select' : 'Select'}
                                {(this.props.data.field) ? ' ' + this.props.data.field : ''}
                            </div>
                            :
                            duplicateArrays.length > 0 &&
                            <div className="alert alert-danger m-t-5" role="alert" id={(this.props.data.errorClass) ? this.props.data.errorClass : ''}>
                                <i className="icon-warning f-s-13"></i>  {langObj.returnLanguage('pleaseSelectAnother')}
                                    {' ' + this.props.data.field + '.'}
                            </div>
                        }
                    </div>
                }
                break;
            case 'regExp':
                if (this.props.data.isError === 1) {
                    var returnStrRegExp = this.props.data.regExp.test(this.props.data.value);
                    return <div>
                        {this.props.data.value === '' || this.props.data.value === null
                            ?
                            <div className="alert alert-danger m-t-5" role="alert" id={(this.props.data.errorClass) ? this.props.data.errorClass : ''}>
                                <i className="icon-warning f-s-13"></i> {langObj.returnLanguage('pleaseString')} {this.props.data.validationType === 'selectfield' ? 'Select' : 'Enter'}
                                {(this.props.data.field) ? ' ' + this.props.data.field : ''}
                            </div>
                            :
                            !!this.props.data.minLength && this.props.data.value.length < this.props.data.minLength ?
                                <div className="alert alert-danger m-t-5" role="alert" id={(this.props.data.errorClass) ? this.props.data.errorClass : ''}>
                                    <i className="icon-warning f-s-13"></i> {langObj.returnLanguage('pleaseEnterMinimum')} {this.props.data.minLength} Characters
                                </div>
                                :
                                returnStrRegExp === false &&
                                <div className="alert alert-danger m-t-5" role="alert" id={(this.props.data.errorClass) ? this.props.data.errorClass : ''}>
                                    <i className="icon-warning f-s-13"></i> {langObj.returnLanguage('enterValid')}
                                    {(this.props.data.field) ? ' ' + this.props.data.field : ''}
                                </div>
                        }
                    </div>
                }
                break;
            case 'email':
                if (this.props.data.isError === 1) {
                    var emailExp = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                    var returnStrRegExp = emailExp.test(this.props.data.value);
                    return <div>
                        {this.props.data.value === '' || this.props.data.value === null
                            ?
                            <div className="alert alert-danger m-t-5" role="alert" id={(this.props.data.errorClass) ? this.props.data.errorClass : ''}>
                                <i className="icon-warning f-s-13"></i> {langObj.returnLanguage('pleaseEnterString')} {!!this.props.data.field ? ' ' + this.props.data.field : ''}
                            </div>
                            :
                            returnStrRegExp === false &&
                            <div className="alert alert-danger m-t-5" role="alert" id={(this.props.data.errorClass) ? this.props.data.errorClass : ''}>
                                <i className="icon-warning f-s-13"></i> {langObj.returnLanguage('enterValidEmail')}
                            </div>
                        }
                    </div>
                }
                break;
            case 'baseGrade':
                if (this.props.data.isError === 1) {
                    var returnStrRegExp = this.props.data.regExp.test(this.props.data.value);
                    return <div>
                        {this.props.data.value === '' || this.props.data.value === null || this.props.data.value === 0
                            ?
                            <div className="alert alert-danger m-t-5" role="alert" id={(this.props.data.errorClass) ? this.props.data.errorClass : ''}>
                                <i className="icon-warning f-s-13"></i> {langObj.returnLanguage('pleaseString')} {this.props.data.validationType === 'selectfield' ? 'Select' : 'Enter'}
                                {(this.props.data.field) ? ' ' + this.props.data.field : ''}
                            </div>
                            :
                            (this.props.data.value !== null && returnStrRegExp === false) &&
                            <div className="alert alert-danger m-t-5" role="alert" id={(this.props.data.errorClass) ? this.props.data.errorClass : ''}>
                                <i className="icon-warning f-s-13"></i> {langObj.returnLanguage('enterValid')}
                                    {(this.props.data.field) ? ' ' + this.props.data.field : ''}
                            </div>
                        }
                    </div>
                }
                break;
            case 'regExpNotRequired':
                if (this.props.data.isError === 1 && !!this.props.data.regExp) {
                    var returnStrRegExp = this.props.data.regExp.test(this.props.data.value);
                    return <div>
                        {(this.props.data.value !== '' && this.props.data.value !== null && returnStrRegExp === false) &&
                            <div className="alert alert-danger m-t-5" role="alert" id={(this.props.data.errorClass) ? this.props.data.errorClass : ''}>
                                <i className="icon-warning f-s-13"></i> {langObj.returnLanguage('enterValid')}
                                {(this.props.data.field) ? ' ' + this.props.data.field : ''}
                            </div>
                        }
                    </div>
                }
                break;
            case 'minValue':
                if (this.props.data.isError === 1) {
                    var returnStrRegExp = this.props.data.regExp.test(this.props.data.value);
                    return <div>
                        {this.props.data.value === '' || this.props.data.value === null || returnStrRegExp === false
                            ?
                            <div className="alert alert-danger m-t-5" role="alert" id={(this.props.data.errorClass) ? this.props.data.errorClass : ''}>
                                <i className="icon-warning f-s-13"></i> {langObj.returnLanguage('enterValid')}
                                {(this.props.data.field) ? ' ' + this.props.data.field + ' ' + 'should not be less than 1' : ''}
                            </div> : ''
                        }
                    </div>
                }
                break;
            case 'minute':
                if (this.props.data.isError === 1) {
                    return <div>
                        {(this.props.data.value !== null && this.props.data.value.toString().length > 4) &&
                            <div className="alert alert-danger m-t-5" role="alert" id={(this.props.data.errorClass) ? this.props.data.errorClass : ''}>
                                <i className="icon-warning f-s-13"></i> {langObj.returnLanguage('enterValid')}
                                {' ' + this.props.data.field + '.' + ' ' + this.props.data.field + ' ' + 'should not be more than 4 numbers'}
                            </div>
                        }
                    </div>
                }
                break;
            case 'time':
                if (this.props.data.isError === 1) {
                    return <div>
                        {this.props.data.value === '' || this.props.data.value === null
                            ?
                            <div className="alert alert-danger m-t-5" role="alert" id={(this.props.data.errorClass) ? this.props.data.errorClass : ''}>
                                <i className="icon-warning f-s-13"></i> {langObj.returnLanguage('pleaseString')} {this.props.data.validationType === 'selectfield' ? 'Select' : 'Enter'}
                                {(this.props.data.field) ? ' ' + this.props.data.field : ''}
                            </div>
                            :
                            commonFuncObj.get24hTime(this.props.data.startTime) && commonFuncObj.get24hTime(this.props.data.startTime) >= commonFuncObj.get24hTime(this.props.data.endTime) &&
                            <div className="alert alert-danger m-t-5" role="alert" id={(this.props.data.errorClass) ? this.props.data.errorClass : ''}>
                                <i className="icon-warning f-s-13"></i> {langObj.returnLanguage('enterValid')} {(this.props.data.field) ? ' ' + this.props.data.field : ''}
                            </div>
                        }
                    </div>
                }
                else if (this.props.data.isError === 0) {
                    return <div>
                        {(this.props.data.endDate !== '' && this.props.data.startDate > this.props.data.endDate) &&
                            <div className="alert alert-danger m-t-5" role="alert" id={(this.props.data.errorClass) ? this.props.data.errorClass : ''}>
                                <i className="icon-warning f-s-13"></i> {langObj.returnLanguage('enterValid')} {(this.props.data.field) ? ' ' + this.props.data.field : ''}
                            </div>
                        }
                    </div>
                }
                break;
            case 'onlyTime':
                if (this.props.data.isError === 1) {
                    return <div>
                        {(this.props.data.startTime !== '' && this.props.data.value === '' || this.props.data.startTime === '' && this.props.data.value !== '') || (this.props.data.startTime !== null && this.props.data.value === null || this.props.data.startTime === null && this.props.data.value !== null)
                            ?
                            <div className="alert alert-danger m-t-5" role="alert" id={(this.props.data.errorClass) ? this.props.data.errorClass : ''}>
                                <i className="icon-warning f-s-13"></i> {langObj.returnLanguage('enterValid')} {(this.props.data.value === '' || this.props.data.value === null) ? ' ' + this.props.data.field : ' Start Time'}
                            </div>
                            :
                            this.props.data.startTime !== '' && this.props.data.value !== '' && this.props.data.value !== null ?
                                commonFuncObj.get24hTime(this.props.data.startTime) && commonFuncObj.get24hTime(this.props.data.startTime) >= commonFuncObj.get24hTime(this.props.data.endTime) &&
                                <div className="alert alert-danger m-t-5" role="alert" id={(this.props.data.errorClass) ? this.props.data.errorClass : ''}>
                                    <i className="icon-warning f-s-13"></i> {langObj.returnLanguage('enterValid')} {(this.props.data.field) ? ' ' + this.props.data.field : ''}
                                </div> : null
                        }
                    </div>
                }
                break;
            case 'onlyTimeComp':
                if (this.props.data.isError === 1) {
                    return <div>
                        {this.props.data.startTime !== '' && this.props.data.value !== '' && this.props.data.value !== null ?
                            commonFuncObj.get24hTime(this.props.data.startTime) && commonFuncObj.get24hTime(this.props.data.startTime) >= commonFuncObj.get24hTime(this.props.data.endTime) &&
                            <div className="alert alert-danger m-t-5" role="alert" id={(this.props.data.errorClass) ? this.props.data.errorClass : ''}>
                                <i className="icon-warning f-s-13"></i> {langObj.returnLanguage('enterValid')} {(this.props.data.field) ? ' ' + this.props.data.field : ''}
                            </div> : null
                        }
                    </div>
                }
                break;
            case 'date':
                if (this.props.data.isError === 1 || !!this.props.data.value) {
                    return <div>
                        {this.props.data.value === '' || this.props.data.value === null
                            ?
                            <div className="alert alert-danger m-t-5" role="alert" id={(this.props.data.errorClass) ? this.props.data.errorClass : ''}>
                                <i className="icon-warning f-s-13"></i> {langObj.returnLanguage('pleaseString')} {this.props.data.validationType === 'selectfield' ? 'Select' : 'Enter'}
                                {(this.props.data.field) ? ' ' + this.props.data.field : ''}
                            </div>
                            :
                            this.props.data.startDate > this.props.data.endDate &&
                            <div className="alert alert-danger m-t-5" role="alert" id={(this.props.data.errorClass) ? this.props.data.errorClass : ''}>
                                <i className="icon-warning f-s-13"></i> {langObj.returnLanguage('enterValid')} {(this.props.data.field) ? ' ' + this.props.data.field : ''}
                            </div>
                        }
                    </div>
                }
                else if (this.props.data.isError === 0) {
                    return <div>
                        {(this.props.data.endDate !== '' && this.props.data.startDate > this.props.data.endDate) &&
                            <div className="alert alert-danger m-t-5" role="alert" id={(this.props.data.errorClass) ? this.props.data.errorClass : ''}>
                                <i className="icon-warning f-s-13"></i> {langObj.returnLanguage('enterValid')} {(this.props.data.field) ? ' ' + this.props.data.field : ''}
                            </div>
                        }
                    </div>
                }
                break;
            case 'onlyDate':
                if (this.props.data.isError === 1 || !!this.props.data.value) {
                    return <div>
                        {(this.props.data.startDate !== '' && this.props.data.value === '' || this.props.data.startDate === '' && this.props.data.value !== '') || (this.props.data.startDate !== null && this.props.data.value === null || this.props.data.startDate === null && this.props.data.value !== null)
                            ?
                            <div className="alert alert-danger m-t-5" role="alert" id={(this.props.data.errorClass) ? this.props.data.errorClass : ''}>
                                <i className="icon-warning f-s-13"></i> {langObj.returnLanguage('enterValid')} {(this.props.data.value === '' || this.props.data.value === null) ? ' ' + this.props.data.field : ' Start Date'}
                            </div>
                            :
                            this.props.data.startDate !== '' && this.props.data.value !== '' && this.props.data.value !== null ?
                                this.props.data.startDate > this.props.data.endDate &&
                                <div className="alert alert-danger m-t-5" role="alert" id={(this.props.data.errorClass) ? this.props.data.errorClass : ''}>
                                    <i className="icon-warning f-s-13"></i> {langObj.returnLanguage('enterValid')} {(this.props.data.field) ? ' ' + this.props.data.field : ''}
                                </div> : null
                        }
                    </div>
                }
                break;
            case 'onlyCount':
                if (this.props.data.isError === 1 || !!this.props.data.value) {
                    return <div>
                        {(this.props.data.value === '' || this.props.data.value === null)
                            ?
                            <div className="alert alert-danger m-t-5" role="alert" id={(this.props.data.errorClass) ? this.props.data.errorClass : ''}>
                                <i className="icon-warning f-s-13"></i> {langObj.returnLanguage('pleaseEnterString')} {(this.props.data.value === '' || this.props.data.value === null) ? ' ' + this.props.data.field : ' Minimum Count'}
                            </div>
                            :
                            this.props.data.minimumCount !== '' && this.props.data.value !== '' && this.props.data.value !== null ?
                                this.props.data.minimumCount > this.props.data.maximumCount &&
                                <div className="alert alert-danger m-t-5" role="alert" id={(this.props.data.errorClass) ? this.props.data.errorClass : ''}>
                                    <i className="icon-warning f-s-13"></i> {langObj.returnLanguage('enterValid')} {(this.props.data.field) ? ' ' + this.props.data.field : ''}
                                </div> : null
                        }
                    </div>
                }
                break;
            case 'onlyCountComp':
                if (this.props.data.isError === 1 || !!this.props.data.value) {
                    return <div>
                       {this.props.data.minimumCount > this.props.data.maximumCount &&
                             <div className="alert alert-danger m-t-5" role="alert" id={(this.props.data.errorClass) ? this.props.data.errorClass : ''}>
                                   <i className="icon-warning f-s-13"></i> {langObj.returnLanguage('enterValid')} {(this.props.data.field) ? ' ' + this.props.data.field : ''}
                            </div>
                        }
                    </div>
                }
                break;
            case 'year':
                if (this.props.data.isError === 1) {
                    return <div>
                        {(this.props.data.cohortYear !== '' && this.props.data.value === '' || this.props.data.cohortYear === '' && this.props.data.value !== '') || (this.props.data.cohortYear !== null && this.props.data.value === null || this.props.data.cohortYear === null && this.props.data.value !== null)
                            ?
                            <div className="alert alert-danger m-t-5" role="alert" id={(this.props.data.errorClass) ? this.props.data.errorClass : ''}>
                                <i className="icon-warning f-s-13"></i> {langObj.returnLanguage('enterValid')} {(this.props.data.value === '' || this.props.data.value === null) ? ' ' + this.props.data.field : ' Year '}
                            </div>
                            :
                            this.props.data.cohortYear !== '' && this.props.data.value !== '' && this.props.data.value !== null ?
                                this.props.data.cohortYear > this.props.data.endYear &&
                                <div className="alert alert-danger m-t-5" role="alert" id={(this.props.data.errorClass) ? this.props.data.errorClass : ''}>
                                    <i className="icon-warning f-s-13"></i> {langObj.returnLanguage('enterValid')} {(this.props.data.field) ? ' ' + this.props.data.field : ''}
                                </div> : null
                        }
                    </div>
                }
                break;
            case 'credential':
                return <div>
                    {this.props.data.field === 0 &&
                        <div className="alert alert-danger m-t-5" id="err_msg" role="alert">
                            <i className="icon-warning f-s-13"></i> {langObj.returnLanguage('enterValidCredential')}
                        </div>
                    }
                </div>
                break;
            case 'incident':
                if (this.props.data.value !== '' || this.props.data.isError === 1) {
                    var date = this.props.data.associatedField
                    return <div>
                        {this.props.data.value === '' || this.props.data.value === null
                            ?
                            <div className="alert alert-danger m-t-5" role="alert" id={(this.props.data.errorClass) ? this.props.data.errorClass : ''}>
                                <i className="icon-warning f-s-13"></i> {langObj.returnLanguage('pleaseString')} {this.props.data.validationType === 'selectfield' ? 'Select' : 'Enter'}
                                {(this.props.data.field) ? ' ' + this.props.data.field : ''}
                            </div>
                            :
                            this.props.data.value < date &&
                            <div className="alert alert-danger m-t-5" role="alert" id={(this.props.data.errorClass) ? this.props.data.errorClass : ''}>
                                <i className="icon-warning f-s-13"></i> {(this.props.data.field) + ' '} {langObj.returnLanguage('greaterThanApprovalDate')}.
                            </div>
                        }
                    </div>
                }
            case 'number':
                if (this.props.data.isError === 1) {
                    return <div>
                        {this.props.data.value === '' || this.props.data.value === null
                            ?
                            <div className="alert alert-danger m-t-5" role="alert" id={(this.props.data.errorClass) ? this.props.data.errorClass : ''}>
                                <i className="icon-warning f-s-13"></i> {langObj.returnLanguage('pleaseString')} {this.props.data.validationType === 'selectfield' ? 'Select' : 'Enter'}
                                {(this.props.data.field) ? ' ' + this.props.data.field : ''}
                            </div>
                            :
                            (isNaN(this.props.data.value) || this.props.data.value < 24 || this.props.data.value > 42) &&
                            <div className="alert alert-danger m-t-5" role="alert" id={(this.props.data.errorClass) ? this.props.data.errorClass : ''}>
                                <i className="icon-warning f-s-13"></i> {langObj.returnLanguage('enterValid')}
                                    {' ' + this.props.data.field}
                            </div>
                        }
                    </div>
                }
                break;
            case 'numberfield':
                if (this.props.data.isError === 1) {
                    return <div>
                        {this.props.data.value === '' || this.props.data.value === null
                            ?
                            <div className="alert alert-danger m-t-5" role="alert" id={(this.props.data.errorClass) ? this.props.data.errorClass : ''}>
                                <i className="icon-warning f-s-13"></i> {langObj.returnLanguage('pleaseString')} {this.props.data.validationType === 'selectfield' ? 'Select' : 'Enter'}
                                {(this.props.data.field) ? ' ' + this.props.data.field : ''}
                            </div>
                            :
                            (isNaN(this.props.data.value)) &&
                            <div className="alert alert-danger m-t-5" role="alert" id={(this.props.data.errorClass) ? this.props.data.errorClass : ''}>
                                <i className="icon-warning f-s-13"></i> {langObj.returnLanguage('enterValid')}{' ' + this.props.data.field}
                            </div>
                        }
                    </div>
                }
                break;
            case 'urlfield':
                if (this.props.data.isError === 1) {
                    var pattern = new RegExp('^(https?:\\/\\/)?' +
                        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' +
                        '((\\d{1,3}\\.){3}\\d{1,3}))' +
                        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' +
                        '(\\?[;&a-z\\d%_.~+=-]*)?' +
                        '(\\#[-a-z\\d_]*)?$', 'i');
                    var returnStrUrl = pattern.test(this.props.data.value);
                    return <div>
                        {this.props.data.value === '' || this.props.data.value === null
                            ?
                            <div className="alert alert-danger m-t-5" role="alert" id={(this.props.data.errorClass) ? this.props.data.errorClass : ''}>
                                <i className="icon-warning f-s-13"></i> {langObj.returnLanguage('pleaseString')} {this.props.data.validationType === 'selectfield' ? 'Select' : 'Enter'}
                                {(this.props.data.field) ? ' ' + this.props.data.field : ''}
                            </div>
                            :
                            (this.props.data.value !== '' && returnStrUrl === false) &&
                            <div className="alert alert-danger m-t-5" role="alert" id={(this.props.data.errorClass) ? this.props.data.errorClass : ''}>
                                <i className="icon-warning f-s-13"></i> {langObj.returnLanguage('enterValid')}
                                    {' ' + this.props.data.field}
                            </div>
                        }
                    </div>
                }
                break;
            case 'emailfield':
                if (this.props.data.isError === 1) {
                    var emailExpr = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                    var returnStrEmail = emailExpr.test(this.props.data.value);
                    return <div>
                        {this.props.data.value === '' || this.props.data.value === null
                            ?
                            <div className="alert alert-danger m-t-5" role="alert" id={(this.props.data.errorClass) ? this.props.data.errorClass : ''}>
                                <i className="icon-warning f-s-13"></i> {langObj.returnLanguage('pleaseString')} {this.props.data.validationType === 'selectfield' ? 'Select' : 'Enter'}
                                {(this.props.data.field) ? ' ' + this.props.data.field : ''}
                            </div>
                            :
                            (this.props.data.value !== '' && returnStrEmail === false) &&
                            <div className="alert alert-danger m-t-5" role="alert" id={(this.props.data.errorClass) ? this.props.data.errorClass : ''}>
                                <i className="icon-warning f-s-13"></i> {langObj.returnLanguage('enterValid')}
                                    {' ' + this.props.data.field}
                            </div>
                        }
                    </div>
                }
                break;
            case 'checkbox':
                if (this.props.data.isError === 1) {
                    return <div>
                        {this.props.data.value === false &&
                            <div className="alert alert-danger m-t-5" role="alert" id={(this.props.data.errorClass) ? this.props.data.errorClass : ''}>
                                <i className="icon-warning f-s-13"></i> {langObj.returnLanguage('pleaseCheck')} {this.props.data.field}
                            </div>
                        }
                    </div>
                }
                break;
            case 'gradescaleWithDefault':
                if (this.props.data.isError === 1) {
                    return <div>
                        {this.props.data.value === true && this.props.data.gradescaleWithDefault.length > 0 &&
                            <div className="alert alert-danger m-t-5" role="alert" id={(this.props.data.errorClass) ? this.props.data.errorClass : ''}>
                            <i className="icon-warning f-s-13"></i>  {this.props.data.field + ''} {'"' + this.props.data.gradescaleWithDefault[0].title + '"' + ''} is already assigned as default.
                            </div>
                        }
                    </div>
                }
                break;
            case 'file':
                if (this.props.data.isError === 1) {
                    const selectedFileList = this.props.data.value;
                    const typeValid = getFileTypeValid(selectedFileList, this.props.data.fileType);
                    const sizeValid = getFileSizeValid(selectedFileList, this.props.data.fileMaxSize, this.props.data.fileMinSize);
                    return <div>
                        {selectedFileList.length === 0 ?
                            <div className="alert alert-danger m-t-5" role="alert" id={(this.props.data.errorClass) ? this.props.data.errorClass : ''}>
                                <i className="icon-warning f-s-13"></i> {langObj.returnLanguage('pleaseSelect')} {!!this.props.data.field ? ' ' + this.props.data.field : ''}
                            </div>
                            : typeValid !== 0 ?
                                <div className="alert alert-danger m-t-5" role="alert" id={(this.props.data.errorClass) ? this.props.data.errorClass : ''}>
                                    <i className="icon-warning f-s-13"></i> {langObj.returnLanguage('pleaseSelectValidFileType')}
                            </div>
                                : sizeValid !== 0 ?
                                    <div className="alert alert-danger m-t-5" role="alert" id={(this.props.data.errorClass) ? this.props.data.errorClass : ''}>
                                        <i className="icon-warning f-s-13"></i> {langObj.returnLanguage('invalidFileSize')}
                            </div> : ''
                        }
                    </div>
                }
                break;
            case 'onlyFileType':
                if (this.props.data.isError === 1) {
                    const typeValid = getFileTypeValid(this.props.data.value, this.props.data.fileType);
                    const sizeValid = getFileSizeValid(this.props.data.value, this.props.data.fileMaxSize, this.props.data.fileMinSize);
                    return <div>
                        {typeValid !== 0 ?
                            <div className="alert alert-danger m-t-5" role="alert" id={(this.props.data.errorClass) ? this.props.data.errorClass : ''}>
                                <i className="icon-warning f-s-13"></i> {langObj.returnLanguage('pleaseSelectValidFileType')}
                            </div>
                            : sizeValid !== 0 ?
                                <div className="alert alert-danger m-t-5" role="alert" id={(this.props.data.errorClass) ? this.props.data.errorClass : ''}>
                                    <i className="icon-warning f-s-13"></i> {langObj.returnLanguage('invalidFileSize')}
                            </div>
                                : ''
                        }
                    </div>
                }
                break;
            case 'repeatField':
                if (this.props.data.isError === 1) {
                    return <div>
                        {this.props.data.value === '' || this.props.data.value === null
                            ?
                            <div className="alert alert-danger m-t-5" role="alert" id={!!this.props.data.errorClass ? this.props.data.errorClass : ''}>
                                <i className="icon-warning f-s-13"></i> {langObj.returnLanguage('pleaseEnterString')}{!!this.props.data.field ? ` ${this.props.data.field}` : ''}
                            </div>
                            :
                            this.props.data.value !== this.props.data.parentFieldValue &&
                            <div className="alert alert-danger m-t-5" role="alert" id={(this.props.data.errorClass) ? this.props.data.errorClass : ''}>
                                <i className="icon-warning f-s-13"></i> {!!this.props.data.field ? ` ${this.props.data.field} Missmatch` : ''}
                            </div>
                        }
                    </div>
                }
                break;
            case 'password':
                if (this.props.data.isError === 1) {
                    var returnpassword = this.props.data.regExp.test(this.props.data.value);
                    return <div>
                        {this.props.data.value === '' || this.props.data.value === null
                            ?
                            <div className="alert alert-danger m-t-5 " role="alert" id={(this.props.data.errorClass) ? this.props.data.errorClass : ''}>
                                <i className="icon-warning f-s-13"></i> {langObj.returnLanguage('pleaseEnterString')}{(this.props.data.field) ? ' ' + this.props.data.field : ''}
                            </div>
                            :
                            (returnpassword === false || (!!this.props.data.minLength && this.props.data.value.length < this.props.data.minLength)) &&
                            <div className="alert alert-danger m-t-5" role="alert" id={(this.props.data.errorClass) ? this.props.data.errorClass : ''}>
                                <i className="icon-warning f-s-13"></i> {langObj.returnLanguage('enterValid')}{' ' + this.props.data.field}
                            </div>
                        }
                    </div>
                }
                break;
            case 'uniqueId':
                if (this.props.data.isError === 1 && !!this.props.data.arrayIds && !!this.props.data.primaryId && this.props.data.arrayIds.includes(this.props.data.primaryId)) {
                    return <div>
                        <div className="alert alert-danger m-t-5 " role="alert" id={(this.props.data.errorClass) ? this.props.data.errorClass : ''}>
                            <i className="icon-warning f-s-13"></i> {this.props.data.message}
                        </div>
                    </div>
                }
                break;
            case 'uniqueSequence':
                if (this.props.data.isError === 1 && !!this.props.data.value && !!this.props.data.sequenceArray && this.props.data.sequenceArray.filter(num => num === this.props.data.value).length > 1) {
                    return <div>
                        <div className="alert alert-danger m-t-5 " role="alert" id={(this.props.data.errorClass) ? this.props.data.errorClass : ''}>
                            <i className="icon-warning f-s-13"></i> {langObj.returnLanguage('sequenceNumberCannotBeDuplicated')}
                        </div>
                    </div>
                }
                break;
            case 'customValidation':
                if (this.props.data.isError === 1 && !this.props.data.customValidationCondition) {
                    return <div>
                        <div className="alert alert-danger m-t-5 " role="alert" id={(this.props.data.errorClass) ? this.props.data.errorClass : ''}>
                            <i className="icon-warning f-s-13"></i> {this.props.data.customValidationMessage}
                        </div>
                    </div>
                } 
                break;
        }
    }
    render() {
        return (<div>{this.getValidation()}</div>);
    }
}
export default (Validation);
