import { BaseComponent } from '../../shared/BaseComponent';
// <!-- Global stylesheets -->
import './../../../assets/fonts/fonts.css';
import './../../../assets/css/color.css';
import './../../../assets/css/icons/icomoon/icomoon.css';
import './../../../assets/plugins/bootstrap/css/bootstrap.css';
// import './../../../assets/plugins/datatables/datatables.min.css';
import './../../../assets/css/application.css';
//<!-- Core JS files -->
const script1 = document.createElement("script"); 
script1.src = "./../../../assets/plugins/jQuery-slimScroll-1.3.8/jquery.slimscroll.js";   
script1.async = true;
document.body.appendChild(script1);
//<!-- Theme JS files -->
const script2 = document.createElement("script"); 
script2.src = "./../../../assets/js/core/application.js";   
script2.async = true;
document.body.appendChild(script2);


class styleComponent extends BaseComponent {
  
    

    

}

export default styleComponent;