import React from 'react';
import { Redirect, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import {
    BaseComponent, i18n, commonConfig, Validation, actionsCommon, sessionStore, ExecuteArgs
} from '../commonLoginindex';
import { MarkersCommonSelect, endPointConfig, dataTable } from '../../common/commonBillingIndex';
import { isEqual } from 'lodash';
const dataTableObjStudentPasswordList = new dataTable();
const tableColumnsStudentPasswordList = [
    { name: 'checkall', header: 'checkall', columnName: 'selectStudentId', type: 'checkbox', params: ['id'], separator: ',', inputClass: 'check' },
    { name: 'studentName', header: 'Student Name', type: 'default' },
    { name: 'studentCode', header: 'Code', type: 'default' },
    { name: 'userName', header: 'User Name', type: 'default' },
    { name: 'guardian', header: 'Notifiable Contacts', type: 'default' }
];
const notifiableTableColumns = [
    { name: 'checkall', header: 'checkall', columnName: 'id', type: 'checkbox', params: ['personId'], separator: ',', inputClass: 'check', onChangeFunction: 'handleCheckedValue' },
    { name: 'notifiableContacts', header: 'Notifiable Contacts', type: 'default' },
    { name: 'studentName', header: 'Student Name', type: 'default' },
    { name: 'studentCode', header: 'Code', type: 'default' }
];
class StudentPasswordGenerationComponent extends BaseComponent {
    constructor(props) {
        super(props);
        this.state = {
            filterFields: {
                studentnameorcode: '',
                gradeIds: '',
                isRegenerated: false
            },
            generatePasswordData: [],
            generatePasswordDataStudentNotifyList: [],
            getAllStudentFlag: false,
            isGenerateOrIsRegenerate: false,
            isGenerateRegenerateFlag: false,
            requiredFieldsSearch: [
                { fieldName: 'studentnameorcode', fieldType: 'regExpNotRequired', regExp: commonConfig.GRADE_LEVEL_TITLE },
            ],
            isErrorSearch: 0,
        }
        let executedArgument = new ExecuteArgs(commonConfig.COMPONENT_SCHOOL,
            commonConfig.COMPONENT_SCHOOL_GRADELEVEL_GETALL + "schoolId=" + new sessionStore().returnSchoolID() + "&inActiveInclude=" + false,
            commonConfig.METHOD_TYPE_GET);
        this.props.onGetAllGradeLevelSuccess(executedArgument);
    }
    componentDidMount = () => {
        if (!!this.props.notifiableContacts) {
            this.getPendingPasswordStudentList('all');
        } else {
            this.getPendingPasswordStudentList('');
        }
    };

    componentWillReceiveProps(nextProps) {
        if (!isEqual(nextProps.pendingPasswordStudentList, this.props.pendingPasswordStudentList)) {
            this.setState({ getAllStudentFlag: true }, () => {
                dataTableObjStudentPasswordList.returnDestroyTable('markers-datatableStudentPasswordList');
            });
        }
        if (nextProps.pendingPasswordStudentList && nextProps.pendingPasswordStudentList.length != 0 && this.state.getAllStudentFlag) {
            this.setState({ generatePasswordData: nextProps.pendingPasswordStudentList, getAllStudentFlag: false }, () => {
                dataTableObjStudentPasswordList.returnDestroyTable('markers-datatableStudentPasswordList');
                dataTableObjStudentPasswordList.returnResetTable(null, false, 1, 'asc', [], 'markers-datatableStudentPasswordList');
                window.$("#StudentPasswordGeneration").modal('show');
            });
        }
        if (nextProps.pendingPasswordStudentList && nextProps.pendingPasswordStudentList.length === 0) {
            this.setState({ generatePasswordData: [] }, () => {
                window.$("#StudentPasswordGeneration").modal('show');
            });
        }
        if (!isEqual(nextProps.studentNotifyList, this.props.studentNotifyList)) {
            this.setState({ getAllStudentFlag: true })
            dataTableObjStudentPasswordList.returnDestroyTable('markers-datatableStudentPasswordList');
        }
        if (nextProps.studentNotifyList && nextProps.studentNotifyList.length != 0 && this.state.getAllStudentFlag) {
            this.setState({ generatePasswordDataStudentNotifyList: nextProps.studentNotifyList, getAllStudentFlag: false }, () => {
                dataTableObjStudentPasswordList.returnDestroyTable('markers-datatableStudentPasswordList');
                dataTableObjStudentPasswordList.returnResetTable(null, false, 1, 'asc', [], 'markers-datatableStudentPasswordList');
                window.$("#StudentPasswordGeneration").modal('show');
            });
        }
        if (nextProps.studentNotifyList && nextProps.studentNotifyList.length === 0) {
            this.setState({ generatePasswordDataStudentNotifyList: [] }, () => {
                window.$("#StudentPasswordGeneration").modal('show');
            });
        }

        if (!!nextProps.saveGenerateRegenerateStudentPassword && this.state.isGenerateRegenerateFlag) {
            this.setState({ isGenerateRegenerateFlag: false, }, () => {
                this.props.onResetProps({ name: 'saveGenerateRegenerateStudentPassword', data: false });
                this.getPendingPasswordStudentList();
            });
        }
        if (!!nextProps.saveGenerateNotifyStudentPassword && this.state.isGenerateRegenerateFlag) {
            this.setState({ isGenerateRegenerateFlag: false, }, () => {
                this.props.onResetProps({ name: 'saveGenerateNotifyStudentPassword', data: false });
                this.getPendingPasswordStudentList();
            });
        }
    }

    closeModal = () => {
        dataTableObjStudentPasswordList.returnDestroyTable('markers-datatableStudentPasswordList');
        window.$("#StudentPasswordGeneration").modal('hide');
        this.props.changeStudentPasswordGenerationFlag(false);
    }

    getPendingPasswordStudentList = (type) => {
        let validationObj1 = new Validation();
        let submitStatus = validationObj1.fieldValidation(this.state.filterFields, this.state.requiredFieldsSearch);
        if (submitStatus === 0) {
            this.setState({ isErrorSearch: 1 });
        } else {
            this.setState({ isErrorSearch: 0 });
            dataTableObjStudentPasswordList.returnDestroyTable('markers-datatableStudentPasswordList');
            if (!!this.props.notifiableContacts) {
                this.props.onResetProps({ name: 'studentNotifyList', data: [] });
                let fields = Object.assign({}, this.state.filterFields);
                let gradeIds = !!fields.gradeIds ? fields.gradeIds : ''
                let searchText = !!fields.studentnameorcode ? fields.studentnameorcode : ''
                if (type === 'all') {
                    let executedArgument = new ExecuteArgs(commonConfig.COMPONENT_STUDENT,
                        commonConfig.COMPONENT_STUDENT_GET_NOTIFIABLE_CONTACTS + "schoolId=" + new sessionStore().returnSchoolID() + "&studentnameorcode=" + '' + "&gradeIds=" + '',
                        commonConfig.METHOD_TYPE_POST);
                    this.props.onGetAllStudent(executedArgument, 'notify');
                } else {
                    let executedArgument = new ExecuteArgs(commonConfig.COMPONENT_STUDENT,
                        commonConfig.COMPONENT_STUDENT_GET_NOTIFIABLE_CONTACTS + "schoolId=" + new sessionStore().returnSchoolID() + "&studentnameorcode=" + searchText + "&gradeIds=" + gradeIds,
                        commonConfig.METHOD_TYPE_POST);
                    this.props.onGetAllStudent(executedArgument, 'notify');

                }
            } else {
                this.setState({ getAllStudentFlag: true, isGenerateOrIsRegenerate: this.state.filterFields.isRegenerated }, () => {
                    this.props.onResetProps({ name: 'pendingPasswordStudentList', data: [] });
                    let executedArgument = new ExecuteArgs(commonConfig.COMPONENT_STUDENT,
                        endPointConfig.COMPONENT_GET_PENDING_PASSWORD_STUDENT_LIST + new sessionStore().returnSchoolID() + '&studentnameorcode=' + this.state.filterFields.studentnameorcode + '&gradeIds=' + this.state.filterFields.gradeIds + '&isRegenerated=' + this.state.filterFields.isRegenerated,
                        commonConfig.METHOD_TYPE_GET);
                    this.props.onGetPendingPasswordStudentList(executedArgument);
                });
            }
        }
    }

    handleChange = (event) => {
        let filterFields = { ...this.state.filterFields };
        if (event.target.type === "checkbox") {
            filterFields[event.target.name] = event.target.checked;
        } else {
            filterFields[event.target.name] = event.target.value.replace(/^\s+/g, '');
        }
        this.setState({ filterFields });
    }

    handleGeneratePassword = () => {
        if (dataTableObjStudentPasswordList.getCheckedValue('markers-datatableStudentPasswordList').length > 0) {
            let studentIds = [];
            dataTableObjStudentPasswordList.getCheckedValue('markers-datatableStudentPasswordList').forEach((data, i) => {
                studentIds.push(parseInt(data, 10));
            })
            if (!!this.props.notifiableContacts) {
                this.setState({ isGenerateRegenerateFlag: true }, () => {
                    let executedArgument = new ExecuteArgs(commonConfig.COMPONENT_STUDENT,
                        commonConfig.COMPONENT_STUDENT_SEND_NOTIFIABLE_CONTACTS + studentIds,
                        commonConfig.METHOD_TYPE_POST);
                    this.props.onGenerateNotifyPassword(executedArgument, 'generate notify student password');
                })
            } else {
                this.setState({ isGenerateRegenerateFlag: true }, () => {
                    let executedArgument = new ExecuteArgs(commonConfig.COMPONENT_STUDENT,
                        endPointConfig.COMPONENT_GENERATE_REGENERATE_STUDENT_PASSWORD,
                        commonConfig.METHOD_TYPE_POST, { schoolID: new sessionStore().returnSchoolID(), studentIds: studentIds.toString(), isRegeneratePassword: this.state.isGenerateOrIsRegenerate });
                    this.props.onGenerateRegenerateStudentPassword(executedArgument);
                })
            }
        } else {
            this.props.onShowNotify(i18n.t("pleaseSelectStudentS"), 'error');
        }
    }
    render() {
        return (
            <React.Fragment>
                <form action="">
                    <div className="modal fade" id="StudentPasswordGeneration">
                        <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    {!this.props.notifiableContacts ?
                                        <h5 className="modal-title">{i18n.t("generatePasswordStudents")}</h5>
                                        :
                                        <h5 className="modal-title">{i18n.t("generatePasswordStudentContacts")}</h5>
                                    }
                                    <button type="button" className="close" aria-label="Close" onClick={this.closeModal}>
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body pb-0">
                                    <div className="card border mb-3">
                                        <div className="card-body px-3 pt-2 pb-0">
                                            <div className="row align-items-center">
                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                        <label className="control-label">{i18n.t('searchStudent')}</label>
                                                        <input type="text" className="form-control" name="studentnameorcode" maxlength="20" placeholder={i18n.t('studentNameCode')} value={this.state.filterFields.studentnameorcode} onChange={this.handleChange} />
                                                        <Validation data={{
                                                            field: i18n.t("studentNameCode"), errorClass: 'studentnameorcode_error',
                                                            value: this.state.filterFields.studentnameorcode, isError: this.state.isErrorSearch,
                                                            validationType: 'regExpNotRequired', regExp: commonConfig.GRADE_LEVEL_TITLE
                                                        }} returnValidation={this.functionValidation} />
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <MarkersCommonSelect
                                                        label={i18n.t("gradeLevel")}
                                                        name="gradeIds"
                                                        title="gradeIds"
                                                        divClassName="form-group"
                                                        labelClassName="control-label"
                                                        value={this.state.filterFields.gradeIds || ''}
                                                        optArray={this.props.schoolGradeList}
                                                        handleChange={this.handleChange}
                                                        isError={0}
                                                        isRequired={false}
                                                        optionLabelValue='id'
                                                        multi={true}
                                                        optionLabelTitle='gradeTitle'
                                                    />
                                                </div>
                                                <div className="col-md-4" style={{ display: !this.props.notifiableContacts ? 'block' : 'none' }}>
                                                    <div className="form-group mb-0">
                                                        <label className="fluidCheckbox mt-0 mb-0">
                                                            <input name="isRegenerated" type="checkbox" checked={this.state.filterFields.isRegenerated} onChange={this.handleChange} />
                                                            <span><i></i></span> {i18n.t('regeneratePassword')}
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="footer-body px-3 py-2 text-right bg-light border-top">
                                            <Link to="#" className="btn btn-success" onClick={() => this.getPendingPasswordStudentList()}>{i18n.t("search")}</Link>
                                        </div>
                                    </div>
                                    <div className="card border border-top-0 mb-3">
                                        <div className="card-body p-0">
                                            {dataTableObjStudentPasswordList.returnTable(!!this.props.notifiableContacts ? notifiableTableColumns : tableColumnsStudentPasswordList, !!this.props.notifiableContacts ? this.state.generatePasswordDataStudentNotifyList : this.state.generatePasswordData, this, '', '', '', '', [], '', 'markers-datatableStudentPasswordList')}
                                        </div>
                                        <div className="footer-body px-3 py-2 text-right bg-light border-top">
                                            <Link to="#" className="btn btn-success" onClick={() => this.handleGeneratePassword()}>{!!this.state.isGenerateOrIsRegenerate ? i18n.t('regeneratePassword') : i18n.t("generatePassword")}</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </React.Fragment>
        )
    }
}
const mapStateToProps = state => {
    return {
        pendingPasswordStudentList: state.commonStore.pendingPasswordStudentList,
        schoolGradeList: state.commonStore.schoolGradeList,
        saveGenerateRegenerateStudentPassword: state.commonStore.saveGenerateRegenerateStudentPassword,
        studentNotifyList: state.commonStore.studentNotifyList,
        saveGenerateNotifyStudentPassword: state.commonStore.saveGenerateNotifyStudentPassword,
    }
}
const mapDispatchToProps = dispatch => {
    return {
        onGetPendingPasswordStudentList: (executeArgs) => dispatch(actionsCommon.callCommonListApi(executeArgs, 'pending password student')),
        onResetProps: (executeArgs) => dispatch(actionsCommon.commonStoreStateReset(executeArgs)),
        onGetAllGradeLevelSuccess: (executeArgs) => dispatch(actionsCommon.callCommonListApi(executeArgs, 'schoolGrade')),
        onShowNotify: (message, type) => dispatch(actionsCommon.showNotify(message, type)),
        onGenerateRegenerateStudentPassword: (executeArgs) => dispatch(actionsCommon.callCommonSaveApi(executeArgs, 'generate regenerate student password')),
        onGetAllStudent: (executeArgs) => dispatch(actionsCommon.callCommonListApi(executeArgs, 'student notify')),
        onGenerateNotifyPassword: (executeArgs) => dispatch(actionsCommon.callCommonSaveApi(executeArgs, 'generate notify student password')),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(StudentPasswordGenerationComponent)