import React, { Component } from 'react';
import $ from 'jquery';
import { Link } from 'react-router-dom';
import { MarkersMultiLingual } from './markersMultiLingualComponent';
import sessionStore from './sessionStoreComponent';
import ConvertDate from './markersConvertDateComponent';
import 'datatables.net-bs4';
import 'datatables.net-buttons-bs4';
import 'datatables.net-buttons/js/buttons.html5.js';
import 'datatables.net-buttons/js/buttons.print.js';
import 'datatables.net-fixedheader-bs4';
import CommonFuncs from './markersCommonFunctionComponent';
//import 'datatables.net-responsive-bs4';
import 'datatables.net-scroller-bs4';
import 'datatables.net-colreorder';
import 'jszip';
import Moment from 'react-moment';
import i18n from '../../../i18n';
import commonConfig from '../commonConfig';
const langObj = new MarkersMultiLingual();
const commonFuncObj = new CommonFuncs();
var totalRows = 0;
class dataTable extends Component {
    constructor(props) {
        super(props);
        this.searchValue = '';
        this.countObj = {
            'startCount': null,
            'endCount': null
        }
    }
    state = {
        table: null
    }
    defaultOrder = true;
    checkAllFlag = false;
    selectAll(event, customTableClass, onChangeFunction, excludeCheckboxClass = '') {
        let table = $('.' + customTableClass).DataTable();
        var allPages = table.rows({ 'search': 'applied' }).nodes();
        if (!!excludeCheckboxClass) {
            $('input[type="checkbox"]:not(".' + excludeCheckboxClass + '")', allPages).prop('checked', event.target.checked);
        } else {
            $('input[type="checkbox"]', allPages).prop('checked', event.target.checked);
        }
        if (this.checkAllFlag === true) {
            this.returnDestroyTable(customTableClass);
            if ($('input[type="checkbox"]', allPages).length !== 0)
                this.returnResetTable(null, true, 1, 'desc', [], customTableClass);
        }
        if (!!onChangeFunction)
            onChangeFunction()
    }
    // deSelectAll(event) {
    //     var rows = this.state.table.rows({ 'search': 'applied' }).nodes();
    //     $('input[type="checkbox"]', rows).prop('checked', false);
    // }
    getCheckedValue = (customTableClass = 'markers-datatable', valueDataType = 'default') => {
        let listData = [];
        let table = $('.' + customTableClass).DataTable();
        var allPages = table.rows({ 'search': 'applied' }).nodes();
        $('input[type="checkbox"]', allPages).each(function () {
            if (this.checked) {
                listData.push(valueDataType === 'integer' ? parseInt(this.value, 10) : this.value)
            }
        })
        return listData;
    }
    //for datatable select without pagination
    getSelectedCheckedValue = () => {
        let values = [];
        $('input[type="checkbox"]').each(function () {
            if (this.checked) {
                values.push(this.value)
            }
        })
        return values;
    }
    setCheckedValue = (chackedValuesArray) => {
        chackedValuesArray.forEach((value) => {
            $('input[type="checkbox"][value="' + value + '"]').attr('checked', true);
        })
    }
    getRadioValue = () => {
        let value = $("input[name='radio']:checked").val();
        return value;
    }
    //Destroy DataTable...
    returnDestroyTable(tableClass = 'markers-datatable') {
        let table = $('.' + tableClass).DataTable();
        var allPages = table.rows({ 'search': 'applied' }).nodes();
        $('input[type="checkbox"]', allPages).prop('checked', false);
        $('input#select-all[type="checkbox"]').prop('checked', false);
        $('.' + tableClass).DataTable().destroy();
    }
    //Reset DataTable...
    returnResetTable(groupIndex = null, exportVisibility = false, orderIndex = 1, orderSequence = 'desc', headers = [], tableClass = 'markers-datatable', searchFilterFlag = 0, classObj = {}, isSortable = false, functionName = '', isSearchable = true, isExportable = true) {
        var that = this;    
        $(document).ready(function () {
            //Datatable drag & drop...
            if (isSortable === true) {
                var id = null;
                window.$("." + tableClass + " tbody").sortable({
                    cursor: "move",
                    placeholder: "sortable-placeholder",
                    helper: function (e, tr) {
                        var $originals = tr.children();
                        var $helper = tr.clone();
                        $helper.children().each(function (index) {
                            window.$(this).width($originals.eq(index).width());
                        });
                        return $helper;
                    },
                    update: function (event, ui) {
                        let inputArray = $("." + tableClass).DataTable().rows().data()[ui.item.index()];
                        let endPosition = (ui.item.index() + parseInt(10 * $("." + tableClass).DataTable().page.info().page) + 1);
                        classObj[functionName](id, endPosition, inputArray);
                    },
                    start: function (event, ui) {
                        id = $("." + tableClass).DataTable().rows().data()[ui.item.index()][0];
                    }
                }).disableSelection();
            }
            let searchTable = $('.' + tableClass).DataTable();
            searchTable.on('search.dt', () => {
                that.searchValue = searchTable.search()
            });
        });
        var groupColumn = groupIndex;
        var columnArr = [];
        if (headers.length !== 0) {
            //Show or hide visibility of column...
            columnArr = new Array(headers.length).fill(null);
            columnArr[0] = { "visible": false };
            this.state.table = $('.' + tableClass).DataTable({
                //destroy: true,
                colReorder: true,
                bFilter: isSearchable,
                search: {
                    search: this.searchValue || ''
                },
                dom: "<'p-15'<'row'<'col-sm-12 col-md-6'f><'col-sm-12 col-md-6 text-right'B>>><'row'<'col-sm-12'<'table-responsive'tr>>><'p-15'<'row'<'col-sm-12 col-md-6 custom-info-" + tableClass + "'><'col-sm-12 col-md-6 text-right'p>>>",
                columns: columnArr,
                columnDefs: [{
                    'searchable': true,
                    'orderable': true,
                    'className': 'dt-body-center',
                    //"visible": false,
                    "targets": groupIndex != null ? groupColumn : ''
                }],
                "pagingType": "full_numbers",
                "displayStart": new sessionStore().returnPageCount() ? ((that.countObj.endCount == that.countObj.startCount) && (totalRows == that.countObj.startCount - 1)) ? new sessionStore().returnPageCount() - 10 :  new sessionStore().returnPageCount() : 0,
                select: {
                    style: 'os',
                    selector: 'td:first-child'
                },
                order: this.defaultOrder ? [[orderIndex, orderSequence]] : [],
                "drawCallback": groupIndex != null ? function (settings) {
                    var api = this.api();
                    var rows = api.rows({ page: 'current' }).nodes();
                    var last = null;
                    api.column(groupColumn, { page: 'current' }).data().each(function (group, i) {
                        if (last !== group) {
                            $(rows).eq(i).before(
                                '<tr className="group"><td colspan="5">' + group + '</td></tr>'
                            );
                            last = group;
                        }
                    });
                }
                    :
                    ''
                ,
                "footerCallback": function (row, data, start, end, display) {
                    var table = $("." + tableClass).DataTable();
                    var info = table.page.info();
                    let totalRecords = 0;
                    let startCount = info.start;
                    new sessionStore().storeSecureData(commonConfig.SESSION_DT_PAGE_COUNT, startCount);
                    startCount = parseInt(startCount) + 1;
                    let endCount = info.end;
                    that.countObj = { 'startCount': startCount, 'endCount': endCount }
                    if (info.recordsTotal) {
                        totalRecords = info.recordsTotal;
                    }
                    $(".custom-info-" + tableClass).html('<div className="dataTables_info" role="status" aria-live="polite">Showing ' + startCount + ' to ' + endCount + ' of ' + totalRecords + ' entries</div>');
                },
                responsive: false,
                buttons: isExportable === true ? [{
                    extend: 'collection',
                    text: '',
                    buttons: [
                        {
                            extend: 'excel',
                            exportOptions: {
                                columns: exportVisibility === false ? ':visible:not(:last-child)' : '',
                                format: {
                                    body: function (data, row, column, node) {
                                        if (data.includes("(") === false && data.includes(")") === false && ($(data).is("input") || $(data).is("select"))) {
                                            return $(data).val();
                                        }
                                        else if ($($.parseHTML(data)[0]).is('a') || $(data).is("div:has(a)") || $(data).is('time')) {
                                            return $(data).text();
                                        }
                                        else if (data.includes("(") === false && data.includes(")") === false && ($(data).is("label:has(input[type='checkbox'])") || $(data).is("label:has(input[type='radio'])"))) {
                                            return '';
                                        }
                                        else {
                                            return data;
                                        }
                                    }
                                }
                            }
                        },
                        {
                            extend: 'copy',
                        },
                        {
                            extend: 'csv',
                            customize: function (csv) {
                                return "Markerspro\n" + csv;
                            },
                            exportOptions: {
                                columns: exportVisibility === false ? ':visible:not(:last-child)' : '',
                                format: {
                                    body: function (data, row, column, node) {
                                        if (data.includes("(") === false && data.includes(")") === false && ($(data).is("input") || $(data).is("select"))) {
                                            return $(data).val();
                                        }
                                        else if (data.includes("(") === false && data.includes(")") === false && ($($.parseHTML(data)[0]).is('a') || $(data).is("div:has(a)") || $(data).is('time'))) {
                                            return $(data).text();
                                        }
                                        else if (data.includes("(") === false && data.includes(")") === false && ($(data).is("label:has(input[type='checkbox'])") || $(data).is("label:has(input[type='radio'])"))) {
                                            return '';
                                        }
                                        else {
                                            return data;
                                        }
                                    }
                                }
                            }
                        },
                        {
                            extend: 'pdf',
                            exportOptions: {
                                columns: exportVisibility === false ? ':visible:not(:last-child)' : '',
                                format: {
                                    body: function (data, row, column, node) {
                                        if (data.includes("(") === false && data.includes(")") === false && ($(data).is("input") || $(data).is("select"))) {
                                            return $(data).val();
                                        }
                                        else if (data.includes("(") === false && data.includes(")") === false && ($($.parseHTML(data)[0]).is('a') || $(data).is("div:has(a)") || $(data).is('time'))) {
                                            return $(data).text();
                                        }
                                        else if (data.includes("(") === false && data.includes(")") === false && ($(data).is("label:has(input[type='checkbox'])") || $(data).is("label:has(input[type='radio'])"))) {
                                            return '';
                                        }
                                        else {
                                            return data;
                                        }
                                    }
                                }
                            }
                        }
                    ]
                }
                ] : [],
                //scrollY: contentH,
                //scroller: true
            });
        }
        else { 
            this.state.table = $('.' + tableClass).DataTable({
                //destroy: true,
                colReorder: true,
                bFilter: isSearchable,
                search: {
                    search: this.searchValue || ''
                },
                dom: "<'p-15'<'row'<'col-sm-12 col-md-6'f><'col-sm-12 col-md-6 text-right'B>>><'row'<'col-sm-12'<'table-responsive'tr>>><'p-15'<'row'<'col-sm-12 col-md-6 custom-info-" + tableClass + "'><'col-sm-12 col-md-6 text-right'p>>>",
                columnDefs: [{
                    'searchable': true,
                    'orderable': true,
                    'className': 'dt-body-center',
                    //"visible": false,
                    "targets": groupIndex != null ? groupColumn : ''
                }],
                "pagingType": "full_numbers",
                "displayStart": new sessionStore().returnPageCount() ? ((that.countObj.endCount == that.countObj.startCount) && (totalRows == that.countObj.startCount - 1)) ? new sessionStore().returnPageCount() - 10 : new sessionStore().returnPageCount() : 0,
                select: {
                    style: 'os',
                    selector: 'td:first-child'
                },
                order: this.defaultOrder ? [[orderIndex, orderSequence]] : [],
                "drawCallback": groupIndex != null ? function (settings) {
                    var api = this.api();
                    var rows = api.rows({ page: 'current' }).nodes();
                    var last = null;
                    api.column(groupColumn, { page: 'current' }).data() !== undefined && api.column(groupColumn, { page: 'current' }).data().each(function (group, i) {
                        if (last !== group) {
                            $(rows).eq(i).before(
                                '<tr className="group"><td colspan="5">' + group + '</td></tr>'
                            );
                            last = group;
                        }
                    });
                }
                    :
                    ''
                ,
                "footerCallback": function (row, data, start, end, display) {
                    var table = $('.' + tableClass).DataTable();
                    var info = table.page.info();
                    let totalRecords = 0;
                    let startCount = info.start;
                    
                    new sessionStore().storeSecureData(commonConfig.SESSION_DT_PAGE_COUNT, startCount);
                    startCount = parseInt(startCount) + 1;
                    let endCount = info.end;
                    that.countObj = { 'startCount': startCount, 'endCount': endCount}
                    if (info.recordsTotal){
                        totalRecords = info.recordsTotal;
                    }
                    $(".custom-info-" + tableClass).html('<div className="dataTables_info" role="status" aria-live="polite">Showing ' + startCount + ' to ' + endCount + ' of ' + totalRecords + ' entries</div>');
                },
                responsive: false,
                buttons: isExportable === true ? searchFilterFlag === 1 ?
                    [
                        {
                            text: '<i className="icon-filter3"></i>',
                            action: function (e, dt, node, config) {
                                window.$('body').addClass('show-slidepanel-right');
                            }
                        },
                        , {
                            extend: 'collection',
                            text: '',
                            buttons: [
                                {
                                    extend: 'excel',
                                    exportOptions: {
                                        columns: exportVisibility === false ? ':visible:not(:last-child)' : '',
                                        format: {
                                            body: function (data, row, column, node) {
                                                if (data.includes("(") === false && data.includes(")") === false && ($(data).is("input") || $(data).is("select"))) {
                                                    return $(data).val();
                                                }
                                                else if (data.includes("(") === false && data.includes(")") === false && ($($.parseHTML(data)[0]).is('a') || $(data).is("div:has(a)") || $(data).is('time'))) {
                                                    return $(data).text();
                                                }
                                                else if (data.includes("(") === false && data.includes(")") === false && ($(data).is("label:has(input[type='checkbox'])") || $(data).is("label:has(input[type='radio'])"))) {
                                                    return '';
                                                }
                                                else {
                                                    return data;
                                                }
                                            }
                                        }
                                    }
                                },
                                {
                                    extend: 'copy',
                                },
                                {
                                    extend: 'csv',
                                    customize: function (csv) {
                                        return "Markerspro\n" + csv;
                                    },
                                    exportOptions: {
                                        columns: exportVisibility === false ? ':visible:not(:last-child)' : '',
                                        format: {
                                            body: function (data, row, column, node) {
                                                if (data.includes("(") === false && data.includes(")") === false && ($(data).is("input") || $(data).is("select"))) {
                                                    return $(data).val();
                                                }
                                                else if (data.includes("(") === false && data.includes(")") === false && ($($.parseHTML(data)[0]).is('a') || $(data).is("div:has(a)") || $(data).is('time'))) {
                                                    return $(data).text();
                                                }
                                                else if (data.includes("(") === false && data.includes(")") === false && ($(data).is("label:has(input[type='checkbox'])") || $(data).is("label:has(input[type='radio'])"))) {
                                                    return '';
                                                }
                                                else {
                                                    return data;
                                                }
                                            }
                                        }
                                    }
                                },
                                {
                                    extend: 'pdf',
                                    exportOptions: {
                                        columns: exportVisibility === false ? ':visible:not(:last-child)' : '',
                                        format: {
                                            body: function (data, row, column, node) {
                                                if (data.includes("(") === false && data.includes(")") === false && ($(data).is("input") || $(data).is("select"))) {
                                                    return $(data).val();
                                                }
                                                else if (data.includes("(") === false && data.includes(")") === false && ($($.parseHTML(data)[0]).is('a') || $(data).is("div:has(a)") || $(data).is('time'))) {
                                                    return $(data).text();
                                                }
                                                else if (data.includes("(") === false && data.includes(")") === false && ($(data).is("label:has(input[type='checkbox'])") || $(data).is("label:has(input[type='radio'])"))) {
                                                    return '';
                                                }
                                                else {
                                                    return data;
                                                }
                                            }
                                        }
                                    }
                                }
                            ]
                        }
                    ] :
                    [{
                        extend: 'collection',
                        text: '',
                        buttons: [
                            {
                                extend: 'excel',
                                exportOptions: {
                                    columns: exportVisibility === false ? ':visible:not(:last-child)' : '',
                                    format: {
                                        body: function (data, row, column, node) {
                                            if (data.includes("(") === false && data.includes(")") === false && ($(data).is("input") || $(data).is("select"))) {
                                                return $(data).val();
                                            }
                                            else if (data.includes("(") === false && data.includes(")") === false && ($($.parseHTML(data)[0]).is('a') || $(data).is("div:has(a)") || $(data).is('time'))) {
                                                return $(data).text();
                                            }
                                            else if (data.includes("(") === false && data.includes(")") === false && ($(data).is("label:has(input[type='checkbox'])") || $(data).is("label:has(input[type='radio'])"))) {
                                                return '';
                                            }
                                            else {
                                                return data.replace(/<.*?>/ig, "");
                                            }
                                        }
                                    }
                                }
                            },
                            {
                                extend: 'copy',
                            },
                            {
                                extend: 'csv',
                                customize: function (csv) {
                                    return "Markerspro\n" + csv;
                                },
                                exportOptions: {
                                    columns: exportVisibility === false ? ':visible:not(:last-child)' : '',
                                    format: {
                                        body: function (data, row, column, node) {
                                            if (data.includes("(") === false && data.includes(")") === false && ($(data).is("input") || $(data).is("select"))) {
                                                return $(data).val();
                                            }
                                            else if (data.includes("(") === false && data.includes(")") === false && ($($.parseHTML(data)[0]).is('a') || $(data).is("div:has(a)") || $(data).is('time'))) {
                                                return $(data).text();
                                            }
                                            else if (data.includes("(") === false && data.includes(")") === false && ($(data).is("label:has(input[type='checkbox'])") || $(data).is("label:has(input[type='radio'])"))) {
                                                return '';
                                            }
                                            else {
                                                return data.replace(/<.*?>/ig, "");
                                            }
                                        }
                                    }
                                }
                            },
                            {
                                extend: 'pdf',
                                exportOptions: {
                                    columns: exportVisibility === false ? ':visible:not(:last-child)' : '',
                                    format: {
                                        body: function (data, row, column, node) {
                                            if (data.includes("(") === false && data.includes(")") === false && ($(data).is("input") || $(data).is("select"))) {
                                                return $(data).val();
                                            }
                                            else if (data.includes("(") === false && data.includes(")") === false && ($($.parseHTML(data)[0]).is('a') || $(data).is("div:has(a)") || $(data).is('time'))) {
                                                return $(data).text();
                                            }
                                            else if (data.includes("(") === false && data.includes(")") === false && ($(data).is("label:has(input[type='checkbox'])") || $(data).is("label:has(input[type='radio'])"))) {
                                                return '';
                                            }
                                            else {
                                                return data.replace(/<.*?>/ig, "");
                                            }
                                        }
                                    }
                                }
                            }
                        ]
                    }] : [],
                //scrollY: contentH,
                //scroller: true
            });
        }
    }
    //Returns dynamic DataTable with optional buttons...
    returnTable(tableColumns, tableRows, classObj, editFunc = '', deleteFunc = '', dateFlag = 0, viewFunc = '', checkedValuesArray = [], cancelFunc = '', customTableClass = 'markers-datatable', redirectFunc = '') {
        var headers = tableColumns.map((value, key) =>
            <th key={key}>{value.header === 'checkall' ? <label className="fluidCheckbox mb-0"><input name="select_all" value="1" id="select-all" type="checkbox" onClick={(e) => this.selectAll(e, customTableClass, !!classObj[value.onChangeFunction] ? () => classObj[value.onChangeFunction]() : null, !!value.excludeCheckboxClass ? value.excludeCheckboxClass : '')} /><span><i></i></span></label> : value.header === 'checkallnot' ? '' : langObj.returnLanguage(value.header)}</th>
        );
        var rows = null;
        var tableCaption = null;
        new sessionStore().storeSecureData(commonConfig.SESSION_PAYMENT_OBJECT_COUNT, 1);
        if (tableRows)
            totalRows = tableRows.length;
        {
            (!!this.countObj && !!this.countObj.startCount && !!this.countObj.endCount) ?
                $(".custom-info-" + customTableClass).html(totalRows >= 10 ? '<div className="dataTables_info" role="status" aria-live="polite">Showing ' + this.countObj.startCount + ' to ' + this.countObj.endCount + ' of ' + totalRows + ' entries</div>' : totalRows > 0 ? '<div className="dataTables_info" role="status" aria-live="polite">Showing 1 to ' + totalRows + ' of ' + totalRows + ' entries</div>' : '<div className="dataTables_info" role="status" aria-live="polite">Showing 0 of ' + totalRows + ' entries</div>')
                :
                $(".custom-info-" + customTableClass).html(totalRows >= 10 ? '<div className="dataTables_info" role="status" aria-live="polite">Showing 1 to 10 of ' + totalRows + ' entries</div>' : totalRows > 0 ? '<div className="dataTables_info" role="status" aria-live="polite">Showing 1 to ' + totalRows + ' of ' + totalRows + ' entries</div>' : '<div className="dataTables_info" role="status" aria-live="polite">Showing 0 of ' + totalRows + ' entries</div>')
        }
            tableRows && tableRows.length > 0 ?
            rows = tableRows.map((data, rowIndex) =>
                <tr key={rowIndex}>
                    {tableColumns.map((value1, i) => {
                        switch (value1.type) {
                            case 'markRow':
                                return <td key={i}>
                                    <label className="fluidCheckbox mb-0">
                                        <input type="checkbox" name="markRow" id={`markRow_${rowIndex}`} value={rowIndex} />
                                        <span><i></i></span>
                                    </label>
                                </td>
                            case 'radiobutton':
                                return <td key={i}><label className="fluid-radio mb-0 d-flex align-items-center">
                                    <input value={data[value1.value]} type="radio" name="radio" />
                                    <span className="label"></span>
                                </label></td>
                            case 'checkbox':
                                let checkBoxVal = '';
                                let checkBoxName = '';
                                let checkBoxClass = '';
                                if (value1.columnName)
                                    checkBoxName = value1.columnName;

                                checkBoxClass = (!!value1.checkBoxHideFlag && !data[value1.checkboxHideParam]) ?
                                    !!value1.excludeCheckboxClass ? value1.excludeCheckboxClass : !!value1.inputClass ? value1.inputClass : '' :
                                    !!value1.inputClass ? value1.inputClass : '';

                                value1.params.forEach((paramVal, i) => {
                                    checkBoxVal = checkBoxVal + data[paramVal] + value1.separator;
                                });
                                if (checkBoxVal !== '')
                                    checkBoxVal = checkBoxVal.slice(0, -1);

                                return <td key={i}>{(!!value1.checkBoxHideFlag && !data[value1.checkboxHideParam]) ? <label className="fluidCheckbox mb-0 d-none"><input className={checkBoxClass} name={checkBoxName} value={checkBoxVal} type="checkbox" /><span><i></i></span></label> : <label className="fluidCheckbox mb-0"><input className={checkBoxClass} name={checkBoxName} value={checkBoxVal} type="checkbox" /><span><i></i></span></label>}</td>
                            case 'commaSeparatedString': return <td>{commonFuncObj.formatCommaSeparatedString(data[value1.name])}</td>
                            default:
                                return <td key={i}>{this.returnContent(value1.type, data, value1, classObj, editFunc, deleteFunc, dateFlag, viewFunc, cancelFunc, rowIndex, redirectFunc)}</td>
                        }
                    })}
                </tr>
            ) : rows = null;
        tableCaption = totalRows === 0 ? <caption style={{ textAlign: "center" }}>{langObj.returnLanguage('emptyDataTable')}</caption> : null;
        if (totalRows === 0) {
            $('#Empty_Table_1 tbody').empty();
            return <table id="Empty_Table_1" className={"table " + customTableClass}>
                <thead><tr>{headers}</tr></thead>
                <tbody></tbody>
                {tableCaption}
            </table>
        }
        else
            return <table className={"table " + customTableClass}>
                <thead><tr>{headers}</tr></thead>
                <tbody>{rows}</tbody>
                {tableCaption}
            </table>
    }
    //Returns dynamic content...
    returnContent(type, data, value1, classObj, editFunc, deleteFunc, dateFlag, viewFunc, cancelFunc, index, redirectFunc, parentKey) {
        let returnJsx = '';
        switch (type) {
            case 'boolean':
                const booleanTitle = !!value1.booleanTitle ? value1.booleanTitle : 'Yes|No';
                const titleArray = booleanTitle.split('|');
                returnJsx = data[value1.name] === true ? titleArray[0] : titleArray[1]
                break;
            case 'concatText':
                returnJsx = !!data[value1.name] ? `${data[value1.name]} - ${data[value1.associateField]}` : null;
                break;
            case 'holiday':
                returnJsx = data[value1.name] === true ? 'Vacation' : 'Holiday'
                break;
            case 'modal':
                returnJsx = <Link to="#" data-toggle="modal" data-target="#titleSchoolSessionModal">{data[value1.name]}</Link>
                break;
            case 'time':
                returnJsx = commonFuncObj.changeTimeFormat(data[value1.name])
                break;
            case 'time12h':
                returnJsx = data[value1.name] !== null ? commonFuncObj.convertTime(data[value1.name]) : ''
                break;
            case 'timeNoTimeZone':
                returnJsx = commonFuncObj.convertTime(data[value1.name])
                break;
            case 'timeField':
                let method = value1.methodName;
                returnJsx = commonFuncObj[method](data[value1.name])
                break;
            case 'date':
                value1.ConvertDate && value1.ConvertDate === true ?
                    returnJsx = dateFlag === 1 ? <ConvertDate calDate={data[value1.name]} /> : null
                    :
                    returnJsx = !!data[value1.name] && <Moment format={!!value1.format ? value1.format : new sessionStore().returnDateFormat()}>{data[value1.name]}</Moment>
                break;
            case 'blank':
                returnJsx = '-'
                break;
            case 'formattedText':
                let textVal = '';
                value1.params.forEach((paramVal, i) => {
                    textVal = textVal + data[paramVal] + value1.separator;
                });
                if (textVal !== '')
                    textVal = textVal.slice(0, -1);
                returnJsx = textVal
                break;
            case 'link':
                let linkVal = '';
                let linkText = '';
                value1.params.forEach((paramValue, i) => {
                    linkVal = linkVal + data[paramValue] + value1.separator;
                });
                if (linkVal !== '')
                    linkVal = linkVal.slice(0, -1);
                if (!!value1.displayFormat) {
                    linkText = data[value1.name].replace(" ", value1.displayFormat + " ");
                } else if (!!value1.separator) {
                    linkText = data[value1.name].replace(" ", ", ");
                } else {
                    linkText = data[value1.name];
                }
                returnJsx = <div>
                    {redirectFunc !== '' ?
                        <Link to="#" onClick={() => classObj[redirectFunc](linkVal)}>{linkText}</Link>
                        :
                        viewFunc !== '' ?
                            <Link to="#" onClick={() => classObj[viewFunc](linkVal)}>{linkText}</Link>
                            :
                            null
                    }
                </div>
                break;
            case 'linkUrlStudentDiscipline':
                returnJsx = <div>
                    {viewFunc !== '' ?
                        <Link to="#" onClick={() => classObj[viewFunc](data.id, data.disciplineId)}>{data[value1.name]}</Link>
                        : null
                    }
                </div>
                break;
            case 'multipleLink':
                returnJsx = <div className="text-nowrap">
                    {viewFunc !== '' ?
                        <div>
                            {
                                data.fileDetails !== undefined ?
                                    data.fileDetails.map((val, key) => {
                                        return <p className="mb-1"><Link to="#" data-toggle="tooltip" data-placement="top" title={i18n.t("view")} onClick={() => classObj[viewFunc](val.id, val.studentId, val.gradeId, val.markingPeriodId)} className="mr-3">{val.name + "  "}</Link></p>
                                    }) : null
                            }
                        </div>
                        : null
                    }
                </div>
                break;
            case 'linkUrlEOYStatus':
                returnJsx = <div>
                    {viewFunc !== '' ?
                        <Link to="#" onClick={() => classObj[viewFunc](data.studentEnrollmentId, data.studentId, data.studentName, data.studentCode)}>{value1.separator ? data[value1.name].replace(" ", ", ") : data[value1.name]}</Link>
                        : null
                    }
                </div>
                break;
            case 'linkUrlEOYSummary':
                returnJsx = <div>
                    {viewFunc !== '' ?
                        <Link to="#" onClick={() => classObj[viewFunc](data.id + "|" + data.gradeId)}>{value1.separator ? data[value1.name].replace(" ", ", ") : data[value1.name]}</Link>
                        : null
                    }
                </div>
                break;
            case 'linkUrlScheduling':
                returnJsx = <div>
                    {viewFunc !== '' ?
                        <Link to="#" onClick={() => classObj[viewFunc](data.studentid, data[value1.name], data.studentcode, data.gradeid)}>{value1.separator ? data[value1.name].replace(" ", ", ") : data[value1.name]}</Link>
                        : null
                    }
                </div>
                break;
            case 'linkUrlMissingAttendance':
                returnJsx = <div>
                    {viewFunc !== '' ?
                        <Link to={{ pathname: value1.linkUrl, state: { listId: data.id, courseSection: data.courseSectionTitle, attendanceDate: data.attendanceDate, period: data.periodTitle, courseSectionId: data.courseSectionId, courseSectionPeriodId: data.periodId } }}><i className="icon-user-check"></i>{data[value1.name]}</Link>
                        : null
                    }
                </div>
                break;
            case 'plusIcon':
                returnJsx = <div>
                    {viewFunc !== '' ?
                        <Link to="#" onClick={(e) => classObj[viewFunc](e, data.id)}><i className="icon-plus3"></i></Link>
                        : null
                    }
                </div>
                break;
            case 'linkUrlMultiParam':
                returnJsx = <div>
                    {viewFunc !== '' ?
                        <Link to="#" onClick={() => classObj[viewFunc](data.id, data[value1.name])}>{value1.separator ? data[value1.name].replace(" ", ", ") : data[value1.name]}</Link>
                        : null
                    }
                </div>
                break;
            case 'linkUrlMultiParamAttenDance':
                returnJsx = <div>
                    {viewFunc !== '' ?
                        <Link to="#" onClick={() => classObj[viewFunc](data.gradeLevelId, data.courseSectionId, data.periodId, data.courseCodeTitle, data.gradeLevelTitle, data.periodTitle, data.attendanceDate)}>{data[value1.name]}</Link>
                        : null
                    }
                </div>
                break;
            case 'linkUrlStudentTranscript':
                returnJsx = <div>
                    {viewFunc !== '' ?
                        <Link to="#" onClick={() => classObj[viewFunc](data.studentId, data[value1.name], data.studentCode)}>{value1.separator ? data[value1.name].replace(" ", ", ") : data[value1.name]}</Link>
                        : null
                    }
                </div>
                break;
            case 'linkContentMultiParam':
                returnJsx = <div>
                    {data.isEditable === undefined ?
                        <Link to={{ pathname: value1.linkUrl, state: { param1: data[value1.param1], paramName: data[value1.name], param2: data[value1.param2] } }}>{data[value1.name]}</Link>
                        :
                        <Link to={{ pathname: value1.linkUrl, state: { param1: data[value1.param1], paramName: data[value1.name], param2: data[value1.param2] } }}>{data[value1.name]}</Link>
                    }
                </div>
                break;
            case 'linkContent':
                returnJsx = <div>
                    {data.isEditable === undefined ?
                        <Link to={{ pathname: value1.linkUrl, state: { listId: data[value1.parentId], listName: data[value1.name], markingPeriodId: data.markingPeriodId || 0, assessmentId: data.assessmentId || 0, actualEndDate: data.actualEndDate || '' } }}>{value1.separator ? data[value1.name].replace(" ", ", ") : data[value1.name]}</Link>
                        :
                        <Link to={{ pathname: value1.linkUrl, state: { listId: data[value1.parentId], listName: data[value1.name], markingPeriodId: data.markingPeriodId || 0, assessmentId: data.assessmentId || 0, actualEndDate: data.actualEndDate || '', editStatus: data.isEditable } }}>{value1.separator ? data[value1.name].replace(" ", ", ") : data[value1.name]}</Link>
                    }
                </div>
                break;
            case 'linkGrading':
                returnJsx = <div>
                    {
                        <Link to={{ pathname: value1.linkUrl, state: { listId: data[value1.parentId], listName: data[value1.name], subjectId: value1.subjectId, courseId: value1.courseId } }}>{data[value1.name]}</Link>
                    }
                </div>
                break;
            case 'takeAttendancelinkUrl':
                returnJsx = <div>
                    {data.isEditable === undefined ?
                        <Link to={{ pathname: value1.linkUrl, state: { listId: data.id, listName: data[value1.name], markingPeriodId: data.markingPeriodId || 0, assessmentId: data.assessmentId || 0, actualEndDate: data.actualEndDate || '' } }}><i className="icon-user-check"></i> {data[value1.name]}</Link>
                        : null
                    }
                </div>
                break;
            case 'linkContentHoliday':
                returnJsx = <div>
                    {data.isEditable === undefined ?
                        <Link to={{ pathname: value1.linkUrl, state: { listId: data.holidayInfoId, listName: data[value1.name] } }}>{data[value1.name]}</Link>
                        :
                        <Link to={{ pathname: value1.linkUrl, state: { listId: data.holidayInfoId, listName: data[value1.name], editStatus: data.isEditable } }}>{data[value1.name]}</Link>
                    }
                </div>
                break;
            case 'linkContentAssessment':
                returnJsx = <div>
                    {data.isEditable === undefined ?
                        <Link to={{ pathname: value1.linkUrl, state: { listId: data.id, title: data.title, startTime: data.startTime, endTime: data.endTime, endDate: data.endDate, startDate: data.startDate } }}>{data[value1.name]}</Link>
                        :
                        <Link to={{ pathname: value1.linkUrl, state: { listId: data.id, title: data.title, startTime: data.startTime, endTime: data.endTime, endDate: data.endDate, startDate: data.startDate, editStatus: data.isEditable } }}>{data[value1.name]}</Link>
                    }
                </div>
                break;
            case 'toggleActionButton':
                returnJsx = <div className="text-nowrap">
                    {data[value1.status] === 'Pending' && (commonConfig.SCHOOL_ROLE_ADMIN === new sessionStore().returnUserRoleID() || data[value1.user] === new sessionStore().returnUserID()) ?
                        (editFunc !== '' ? <Link to="#" onClick={() => classObj[editFunc](data.id)} className="mr-3"><i className="icon-pencil4 f-s-14"></i></Link> : null) : null
                    }
                    {data[value1.status] === 'Pending' && (commonConfig.SCHOOL_ROLE_ADMIN === new sessionStore().returnUserRoleID() || data[value1.user] === new sessionStore().returnUserID()) ?
                        (deleteFunc !== '' ? <Link to='#' onClick={() => classObj[deleteFunc](data.id)} className="mr-3"><i className="icon-cross f-s-14"></i></Link> : null) : null
                    }
                </div>
                break;
            case 'deleteButton':
                returnJsx = <div className="text-nowrap">
                    {data.isDelete === undefined ?
                        (deleteFunc !== '' ? <Link to='#' onClick={() => classObj[deleteFunc](data.id)} className="mr-3"><i className="icon-cross f-s-14"></i></Link> : null) :
                        ((deleteFunc !== '' && data.isDelete) ? <Link to="#" onClick={() => classObj[deleteFunc](data.id)} className="mr-3"><i className="icon-cross f-s-14"></i></Link> : null)
                    }
                </div>
                break;
            case 'statusChange':
                returnJsx = <div className="text-nowrap">
                    {data[value1.name] === 'Pending' ?
                        (editFunc !== '' ? <React.Fragment><button type="button" className="btn btn-success mr-3" onClick={() => classObj[editFunc](data.id, 'approve')}>{i18n.t('approve')}</button>
                            <button type="button" className="btn btn-danger mr-3" onClick={() => classObj[deleteFunc](data.id, 'cancel')}>{i18n.t('cancel')}</button></React.Fragment>
                            : null) : <span className={data[value1.name] === 'Approved' ? 'text-success' : 'text-danger'}> {data[value1.name]}</span>
                    }
                </div>
                break;
            case 'buttonWithLink':
                returnJsx = <div className="text-nowrap">
                    {viewFunc !== '' ?
                        <Link to="#" onClick={() => classObj[viewFunc](data.id)} className="mr-3"><i className="icon-eye f-s-14"></i></Link>
                        : null
                    }
                    {data.isEditable === undefined ?
                        (editFunc !== '' ? <Link to="#" onClick={() => classObj[editFunc](data.id)} className="mr-3"><i className="icon-pencil4 f-s-14"></i></Link> : null) :
                        ((editFunc !== '' && data.isEditable) ? <Link to="#" onClick={() => classObj[editFunc](data.id)} className="mr-3"><i className="icon-pencil4 f-s-14"></i></Link> : null)
                    }
                    {data.isDelete === undefined ?
                        (deleteFunc !== '' ? <Link to='#' onClick={() => classObj[deleteFunc](data.id)}><i className="icon-cross f-s-14"></i></Link> : null) :
                        ((deleteFunc !== '' && data.isDelete) ? <Link to="#" onClick={() => classObj[deleteFunc](data.id)}><i className="icon-cross f-s-14"></i></Link> : null)
                    }
                </div>
                break;
            case 'transcriptButtonWithLink':
                returnJsx = <div className="text-nowrap">
                    {viewFunc !== '' ?
                        <Link to="#" onClick={() => classObj[viewFunc](data.transcriptDetailId)} className="mr-3"><i className="icon-eye f-s-14"></i></Link>
                        : null
                    }
                    {data.isEditable === undefined ?
                        (editFunc !== '' ? <Link to="#" onClick={() => classObj[editFunc](data.transcriptDetailId)} className="mr-3"><i className="icon-pencil4 f-s-14"></i></Link> : null) :
                        ((editFunc !== '' && data.isEditable) ? <Link to="#" onClick={() => classObj[editFunc](data.transcriptDetailId)} className="mr-3"><i className="icon-pencil4 f-s-14"></i></Link> : null)
                    }
                    {data.isDelete === undefined ?
                        (deleteFunc !== '' ? <Link to='#' onClick={() => classObj[deleteFunc](data.transcriptDetailId)}><i className="icon-cross f-s-14"></i></Link> : null) :
                        ((deleteFunc !== '' && data.isDelete) ? <Link to="#" onClick={() => classObj[deleteFunc](data.transcriptDetailId)}><i className="icon-cross f-s-14"></i></Link> : null)
                    }
                </div>
                break;
            case 'buttonWithViewDelete':
                returnJsx = <div className="text-nowrap">
                    {viewFunc !== '' ?
                        <Link to="#" onClick={() => classObj[viewFunc](data.id)} className="mr-3"><i className="icon-eye f-s-14"></i></Link>
                        : null
                    }
                    {data.isDelete === undefined ?
                        (deleteFunc !== '' ? <Link to='#' onClick={() => classObj[deleteFunc](data.id)}><i className="icon-cross f-s-14"></i></Link> : null) :
                        ((deleteFunc !== '' && data.isDelete) ? <Link to="#" onClick={() => classObj[deleteFunc](data.id)}><i className="icon-cross f-s-14"></i></Link> : null)
                    }
                </div>
                break;
            case 'buttonWithDownloadView':
                returnJsx = <div className="text-nowrap">
                    {viewFunc !== '' ?
                        <Link to="#" data-toggle="tooltip" data-placement="top" title={i18n.t("view")} onClick={() => classObj[viewFunc](data.id)} className="mr-3"><i className="icon-eye f-s-14"></i></Link>
                        : null
                    }
                    {viewFunc !== '' ?
                        <Link to="#" className="mr-3" data-toggle="tooltip" data-placement="top" title={i18n.t("download")}><i className="icon-download2 f-s-14"></i></Link>
                        : null
                    }
                </div>
                break;
            case 'button':
                returnJsx = <div>
                    {data.isEditable === undefined ?
                        (editFunc !== '' ? <Link to="#" onClick={() => classObj[editFunc](data.id)} className="mr-3"><i className="icon-pencil4 f-s-14"></i></Link> : null) :
                        ((editFunc !== '' && data.isEditable) ? <Link to="#" onClick={() => classObj[editFunc](data.id)} className="mr-3"><i className="icon-pencil4 f-s-14"></i></Link> : null)
                    }
                    {data.isDelete === undefined ?
                        (deleteFunc !== '' ? <Link to='#' onClick={() => classObj[deleteFunc](data.id)} className="mr-3"><i className="icon-cross f-s-14"></i></Link> : null) :
                        ((deleteFunc !== '' && data.isDelete) ? <Link to="#" onClick={() => classObj[deleteFunc](data.id)} className="mr-3"><i className="icon-cross f-s-14"></i></Link> : null)
                    }
                    {data.lookupIncidentStatusTitle !== "Cancelled" && data.lookupIncidentStatusTitle !== "Closed" && data.lookupIncidentStatusTitle !== "Action Taken" ?
                        (cancelFunc !== '' ? <Link to="#" onClick={() => classObj[cancelFunc](data.id)} className="mr-3"><i className="icon-blocked"></i></Link> : null) : null
                    }
                </div>
                break;
            case 'buttonStudentDiscipline':
                returnJsx = <div>
                    {data.isEditable === undefined ?
                        (editFunc !== '' ? <Link to="#" onClick={() => classObj[editFunc](data.id, data.disciplineId)} className="mr-3"><i className="icon-pencil4 f-s-14"></i></Link> : null) :
                        ((editFunc !== '' && data.isEditable) ? <Link to="#" onClick={() => classObj[editFunc](data.id, data.disciplineId)} className="mr-3"><i className="icon-pencil4 f-s-14"></i></Link> : null)
                    }
                    {data.isDelete === undefined ?
                        (deleteFunc !== '' ? <Link to='#' onClick={() => classObj[deleteFunc](data.disciplineId)} className="mr-3"><i className="icon-cross f-s-14"></i></Link> : null) :
                        ((deleteFunc !== '' && data.isDelete) ? <Link to="#" onClick={() => classObj[deleteFunc](data.disciplineId)} className="mr-3"><i className="icon-cross f-s-14"></i></Link> : null)
                    }
                </div>
                break;
            case 'buttonHoliday':
                returnJsx = <div>
                    {data.isEditable === undefined ?
                        (editFunc !== '' ? !!data.holidayListId ? !!data.fromDate && (data.fromDate.slice(0, 10) > new Date().toISOString().slice(0, 10)) ? <Link to="#" onClick={() => classObj[editFunc](data.holidayListId)} className="mr-3"><i className="icon-pencil4 f-s-14"></i></Link> : null : <Link to="#" onClick={() => classObj[editFunc](data.holidayInfoId)} className="mr-3"><i className="icon-pencil4 f-s-14"></i></Link> : null) :
                        ((editFunc !== '' && data.isEditable) ? !!data.holidayListId ? !!data.fromDate && (data.fromDate.slice(0, 10) > new Date().toISOString().slice(0, 10)) ? <Link to="#" onClick={() => classObj[editFunc](data.holidayListId)} className="mr-3"><i className="icon-pencil4 f-s-14"></i></Link> : null : <Link to="#" onClick={() => classObj[editFunc](data.holidayInfoId)} className="mr-3"><i className="icon-pencil4 f-s-14"></i></Link> : null)
                    }
                    {data.isDelete === undefined ?
                        (deleteFunc !== '' ? <Link to='#' onClick={() => classObj[deleteFunc](data.holidayInfoId || data.holidayListId)} className="mr-3"><i className="icon-cross f-s-14"></i></Link> : null) :
                        ((deleteFunc !== '' && data.isDelete) ? <Link to="#" onClick={() => classObj[deleteFunc](data.holidayInfoId || data.holidayListId)} className="mr-3"><i className="icon-cross f-s-14"></i></Link> : null)
                    }
                </div>
                break;
            case 'status':
                returnJsx = !!value1.customStatus ? commonFuncObj.formatStatus(data[value1.name], value1.customDeclineColor, value1.customDeclineTextFormat) : commonFuncObj.formatStatus(data[value1.name])
                break;
            case 'statusV2':
                returnJsx = !!value1.customStatus ? commonFuncObj.formatPaymentStatus(data[value1.name], value1.customDeclineColor, value1.customDeclineTextFormat) : commonFuncObj.formatPaymentStatus(data[value1.name])
                break;
            case 'colorField':
                returnJsx = <div style={{ color: value1.colorCode }}> {data[value1.name]} </div>
                break;
            case 'transcript-dropdown':
                returnJsx = <select className="custom-select transcript" name="lookupPublicationStatus">
                    <option value={'9502|' + data.studentId}>Draft</option>
                    {data.publicationStatus && data.publicationStatus.length > 0 ?
                        data.publicationStatus.map((val, key) => {
                            return <option key={key} value={val.id + "|" + val.studentId}>{val.title}</option>
                        }) : ''
                    }
                </select>
                break;
            case 'selectField':
                let inputValue = data[value1.name]
                if (!!value1.default && data[value1.name] === 0)
                    inputValue = value1.default
                returnJsx = <React.Fragment>
                    <select className="custom-select" onChange={(e) => classObj.handleChange(e, index)} name={value1.name} value={inputValue}>
                        {!!classObj.props && !!classObj.props[value1.selectArray] && classObj.props[value1.selectArray] !== 0 ?
                            classObj.props[value1.selectArray].map((val, key) => {
                                return <option value={val.id}>{val.title}</option>
                            }) : ''
                        }
                    </select>
                    {value1.subField.type === 'textarea' ?
                        <textarea type="text" id={`${value1.subField.name}_${index}`} name={`${value1.subField.name}_${index}`} onChange={(e) => classObj.handleChange(e, index)} className="form-control" placeholder={!!value1.subField.title ? value1.subField.title : i18n.t(value1.subField.name)}></textarea>
                        : ''
                    }
                </React.Fragment>
                break;
            case 'dateField':
                returnJsx = <input type="text" id={`${value1.name}_${index}`} data-provide="datepicker" className="form-control datepicker" data-date-format="yyyy-mm-dd" placeholder={i18n.t('selectDate')} readOnly={true} name={value1.name} value={!!data[value1.name] ? (data[value1.name].slice(0, 10) !== '0001-01-01' ? data[value1.name].slice(0, 10) : '') : ''} />
                break;
            case 'fileDownload':
                returnJsx = !!data.fileId && data.fileId !== 0 ? <Link to="#" onClick={() => classObj.fileDownload(data.fileId)}>{data[value1.name]}</Link> : ''
                break;
            case 'perpetratorName':
                let perpetratorName = '-';
                if (!!data.internalPerpetratorName && !!data.externalPerpetratorName) {
                    perpetratorName = `${data.internalPerpetratorName}, ${data.externalPerpetratorName}`;
                } else if (!!data.internalPerpetratorName) {
                    perpetratorName = `${data.internalPerpetratorName}`;
                } else if (!!data.externalPerpetratorName) {
                    perpetratorName = `${data.externalPerpetratorName}`;
                }
                returnJsx = `${perpetratorName}`
                break;
            case 'buttonV2':
                returnJsx = <div className="d-flex">
                    {!!value1.properties && Array.isArray(value1.properties) ? value1.properties.map((propsData, key) => {
                        const paramsArray = !!propsData.params ? (Array.isArray(propsData.params) ? [propsData.params.map(paramIndex => { return data[paramIndex] })] : [data[propsData.params]]) : [data.id]
                        let linkAttr = { to: "#", className: "mr-3" }
                        if (!!propsData.functionName && typeof classObj[propsData.functionName] === "function" && paramsArray.length !== 0) {
                            linkAttr = { ...linkAttr, onClick: paramsArray.length === 1 ? () => classObj[propsData.functionName](paramsArray[0]) : () => classObj[propsData.functionName](...paramsArray) }
                        }
                        switch (propsData.icon) {
                            case 'edit':
                                return !!new sessionStore().returnSessionCurrentPagePermission() && new sessionStore().returnSessionCurrentPagePermission().editPermission && (!data.hasOwnProperty('isEditable') || (data.hasOwnProperty('isEditable') && data.isEditable)) ?
                                    <Link key={`${parentKey}_${key}`} {...linkAttr}><i className="icon-pencil4 f-s-14"></i></Link> : null
                            case 'manage':
                                return !!new sessionStore().returnSessionCurrentPagePermission() && new sessionStore().returnSessionCurrentPagePermission().editPermission && (!data.hasOwnProperty('isEditable') || (data.hasOwnProperty('isEditable') && data.isEditable)) ?
                                    <Link key={`${parentKey}_${key}`} {...linkAttr}><i className="icon-cog f-s-14"></i></Link> : null
                            case 'delete':
                                return !!new sessionStore().returnSessionCurrentPagePermission() && new sessionStore().returnSessionCurrentPagePermission().deletePermission && (!data.hasOwnProperty('isDelete') || (data.hasOwnProperty('isDelete') && data.isDelete)) ?
                                    <Link key={`${parentKey}_${key}`} {...linkAttr}><i className="icon-cross f-s-14"></i></Link> : null
                            case 'view':
                                return <Link key={`${parentKey}_${key}`} {...linkAttr}><i className="icon-eye f-s-14"></i></Link>
                            default:
                                return <Link key={`${parentKey}_${key}`} {...linkAttr}><i className={`icon-${propsData.icon} f-s-14`}></i></Link>
                        }
                    }) : null}
                </div>
                break;
            case 'linkV2':
                returnJsx = <div>
                    {!!value1.properties && Array.isArray(value1.properties) ? value1.properties.map((propsData, key) => {
                        const paramsArray = !!propsData.params ? (Array.isArray(propsData.params) ? [propsData.params.map(paramIndex => { return data[paramIndex] })] : [data[propsData.params]]) : [data.id]
                        let linkAttr = { to: "#", className: "mr-3" }
                        if (!!propsData.functionName && typeof classObj[propsData.functionName] === "function" && paramsArray.length !== 0) {
                            linkAttr = { ...linkAttr, onClick: paramsArray.length === 1 ? () => classObj[propsData.functionName](paramsArray[0]) : () => classObj[propsData.functionName](...paramsArray) }
                        }
                        return <Link key={`${parentKey}_${key}`} {...linkAttr}>{!!propsData.columnName && !!data[propsData.columnName] ? data[propsData.columnName] : propsData.text}</Link>
                    }) : null}
                </div>
                break;
            case 'days':
                returnJsx = commonFuncObj.convertNumberListToSpacedDaysOfTheWeek(data.workingDays);
                break;
            case 'activeInactive':
                returnJsx = !!data[value1.name] ? "Active" : "Inactive"
                break;
            case 'customRow':
                returnJsx = !!value1.functionName && typeof classObj[value1.functionName] === "function" ? classObj[value1.functionName]({ ...data }) : null
                break;
            default:
                returnJsx = data[value1.name]
        }
        return returnJsx;
    }
}
export default dataTable;
