import commonConfig from '../commonConfig';
import defaultSchoolImage from './../../../assets/images/no_image_school.svg';
import defaultUserImage from '../../../assets/images/no_image_user.svg';
import SecureStorage from 'secure-web-storage';
var CryptoJS = require("crypto-js");
var SECRET_KEY = '8cfLJmCNUVFZkLcmT916tA9n91eAUepfB2';
const secureStorage = new SecureStorage(sessionStorage, {
    hash: function hash(key) {
        key = CryptoJS.SHA256(key, SECRET_KEY);
        return key.toString();
    },
    encrypt: function encrypt(data) {
        data = CryptoJS.AES.encrypt(data, SECRET_KEY);
        data = data.toString();
        return data;
    },
    decrypt: function decrypt(data) {
        data = CryptoJS.AES.decrypt(data, SECRET_KEY);
        data = data.toString(CryptoJS.enc.Utf8);
        return data;
    }
});
export class sessionStore {
    static SCHOOL_ID = secureStorage.getItem(commonConfig.SESSION_SCHOOLID);
    static LANG = secureStorage.getItem(commonConfig.SESSION_LANGUAGE);
    static SESSION_ID = secureStorage.getItem(commonConfig.SESSION_ID);
    static SESSION_DATE_FORMAT = secureStorage.getItem(commonConfig.SESSION_DATE_FORMAT);
    static SESSION_PHONE_FORMAT = secureStorage.getItem(commonConfig.SESSION_PHONE_FORMAT);

    returnSchoolID() {
        var schoolId = parseInt(secureStorage.getItem(commonConfig.SESSION_SCHOOLID), 10);
        if (!isNaN(schoolId)) {
            return schoolId;
        } else {
            return 0;
        }
    }
    returnSessionID() {
        var sessionId = parseInt(secureStorage.getItem(commonConfig.SESSION_ID), 10);
        if (!isNaN(sessionId)) {
            return sessionId;
        } else {
            return 0;
        }
    }
    returnIsLMSFlag() {
        return secureStorage.getItem(commonConfig.IS_LMS_FLAG);
    }
    returnSessionMarkingPeriodID() {
        // the call below to secureStorage is causing an error
        try {
            return parseInt(secureStorage.getItem(commonConfig.SESSION_MARKINGPERIOD_ID), 10);
        }
        catch (err) {
            return parseInt(0, 10);
        }
    }
    returnDistrictID() {
        return parseInt(secureStorage.getItem(commonConfig.SESSION_DISTRICTID), 10);
    }

    returnHostEnvironment() {
        try {
            return (secureStorage.getItem(commonConfig.SESSION_ENVIRONMENT));
        }
        catch (err) {
            return "development";
        }
    }
    returnLanguage() {
        var language = secureStorage.getItem(commonConfig.SESSION_LANGUAGE);
        if (language) {
            language = 'en';
        }
        return language;
    }
    returnStateCode() {
        try {
            return (secureStorage.getItem(commonConfig.SESSION_STATECODE));
        }
        catch (err) {
            return "";
        }
    }
    returnPageCount() {
        return parseInt(secureStorage.getItem(commonConfig.SESSION_DT_PAGE_COUNT), 10);
    }
    //sso
    returnLoginUrl() {
        return secureStorage.getItem(commonConfig.SESSION_LOGIN_URL);
    }
    returnSchoolName() {
        return secureStorage.getItem(commonConfig.SESSION_SCHOOLNAME);
    }
    returnSchoolPhoto() {
        var schoolImg = secureStorage.getItem(commonConfig.SESSION_SCHOOL_PHOTO);
        return schoolImg !== "null" ? schoolImg : defaultSchoolImage;
    }
    returnSchoolYear() {
        return parseInt(secureStorage.getItem(commonConfig.SESSION_SCHOOLYEAR), 10);
    }
    returnSchoolSession() {
        return secureStorage.getItem(commonConfig.SESSION_SCHOOLSESSION);
    }
    returnSchoolSection() {
        return secureStorage.getItem(commonConfig.SESSION_SECTION_ID);
    }
    returnSchoolSessionId() {
        return secureStorage.getItem(commonConfig.SESSION_SCHOOL_SESSION_ID);
    }
    returnGradeLevel() {
        return secureStorage.getItem(commonConfig.SESSION_GRADE_LEVEL_ID);
    }
    returnRoomId() {
        return secureStorage.getItem(commonConfig.SESSION_ROOM_ID);
    }
    returnDepartmentId() {
        return secureStorage.getItem(commonConfig.SESSION_DEPARTMENT_ID);
    }
    returnGradeScaleId() {
        return secureStorage.getItem(commonConfig.SESSION_GRADE_SCALE_ID);
    }
    returnPeriodId() {
        return secureStorage.getItem(commonConfig.SESSION_PERIOD_ID);
    }
    returnRoleId() {
        return secureStorage.getItem(commonConfig.SESSION_ROLE_ID);
    }

    //returns school permission list for admin with respect to modifying/Adding data in previous school sessions
    returnAdminSchlPrevSessionPermissionList() {
        return JSON.parse(secureStorage.getItem(commonConfig.ADMIN_SCHL_PREV_SESSION_PERMISSION));
    }
    //returns current school session Enddate
    returnCurrentSchoolSessionEndDate() {
        let CurrentSchoolSessionEndDate = secureStorage.getItem(commonConfig.CURRENT_SCHOOL_SESSION_END_DATE);
        if (!!CurrentSchoolSessionEndDate) {
            return CurrentSchoolSessionEndDate;
        } else {
            return 'N/A';
        }
    }

    returnGuardianId() {
        return parseInt(secureStorage.getItem(commonConfig.SESSION_GUARDIAN_ID), 10);
    }
    returnStaffContactId() {
        return parseInt(secureStorage.getItem(commonConfig.SESSION_STAFF_CONTACT_ID), 10);
    }
    returnMarkingperiodId() {
        return parseInt(secureStorage.getItem(commonConfig.SESSION_MARKING_PERIOD_ID), 10);
    }
    returnSubjectId() {
        return parseInt(secureStorage.getItem(commonConfig.COURSE_SUBJECT_ID), 10);
    }
    returnCourseId() {
        return parseInt(secureStorage.getItem(commonConfig.COURSE_ID), 10);
    }
    returnCoursesId() {
        return parseInt(secureStorage.getItem(commonConfig.COURSES_ID), 10);
    }
    returnCourseSectionId() {
        return parseInt(secureStorage.getItem(commonConfig.COURSE_SECTION_ID), 10);
    }
    returnCourseSubjectId() {
        return parseInt(secureStorage.getItem(commonConfig.COURSES_SUBJECT_ID), 10);
    }
    returnSubjectTitle() {
        return secureStorage.getItem(commonConfig.COURSE_SUBJECT_TITLE);
    }
    returnSchoolAddress() {
        return secureStorage.getItem(commonConfig.SESSION_SCHOOLADDRESS);
    }
    returnDateFormat() {
        let dateFormat = !!secureStorage.getItem(commonConfig.SESSION_DATE_FORMAT) ? secureStorage.getItem(commonConfig.SESSION_DATE_FORMAT) : commonConfig.DEFAULT_DATE_FORMAT;
        return dateFormat;
    }
    returnMaxFileSize() {
        return !!secureStorage.getItem(commonConfig.SESSION_FILE_MAXSIZE) ? secureStorage.getItem(commonConfig.SESSION_FILE_MAXSIZE) : commonConfig.FILE_SIZE_100MB;
    }
    returnMinFileSize() {
        return !!secureStorage.getItem(commonConfig.SESSION_FILE_MINSIZE) ? secureStorage.getItem(commonConfig.SESSION_FILE_MINSIZE) : commonConfig.FILE_SIZE_1KB;
    }
    returnPhoneFormat() {
        return secureStorage.getItem(commonConfig.SESSION_PHONE_FORMAT);
    }
    returnIdleTimer() {
        return secureStorage.getItem(commonConfig.SESSION_IDLETIMER);
    }
    //Student
    returnStudentName() {
        var name = secureStorage.getItem(commonConfig.SESSION_STUDENTNAME);
        if (name !== '') {
            return name;
        } else {
            return 'N/A';
        }
    }
    returnIsDashBoard() {
        return secureStorage.getItem(commonConfig.SESSION_IS_DASHBOARD);
    }
    returnIsSchoolReport() {
        return secureStorage.getItem(commonConfig.SESSION_IS_SCHOOLREPORT);
    }
    returnIsDistrictReport() {
        return secureStorage.getItem(commonConfig.SESSION_IS_DISTRICTREPORT);
    }
    returnDistrictLogoFlag() {
        return secureStorage.getItem(commonConfig.SESSION_DISTRICT_LOGO_FLAG);
    }
    returnDistrictLogo() {
        var districtLogo = secureStorage.getItem(commonConfig.SESSION_DISTRICT_LOGO);
        return !!districtLogo ? districtLogo : null;
    }
    returnProfilePicture() {
        const img = secureStorage.getItem(commonConfig.SESSION_PROFILE_PICTURE);
        return img !== "null" ? img : defaultUserImage;
    }
    returnStudentPhoto() {
        return secureStorage.getItem(commonConfig.SESSION_STUDENT_PHOTO);
    }
    returnStudentID() {
        return parseInt(secureStorage.getItem(commonConfig.SESSION_STUDENTID) ? secureStorage.getItem(commonConfig.SESSION_STUDENTID) : 0, 10);
    }
    returnStudentGradeLevelID() {
        return parseInt(secureStorage.getItem(commonConfig.SESSION_STUDENT_GRADE_LEVEL_ID) ? secureStorage.getItem(commonConfig.SESSION_STUDENT_GRADE_LEVEL_ID) : 0, 10);
    }
    returnStudentEnrollmentID() {
        return parseInt(secureStorage.getItem(commonConfig.SESSION_STUDENT_ENROLLMENT_ID), 10);
    }
    returnStudentVisaFlag() {
        return JSON.parse(secureStorage.getItem(commonConfig.SESSION_STUDENTVISAFLAG));
    }
    returnStudentCode() {
        return secureStorage.getItem(commonConfig.SESSION_STUDENT_CODE);
    }
    returnStudentGradeTitle() {
        return secureStorage.getItem(commonConfig.SESSION_STUDENT_GRADE_LEVEL_TITLE);
    }
    //Staff
    returnStaffName() {
        var name = secureStorage.getItem(commonConfig.SESSION_STAFFNAME);
        if (name !== '') {
            return name;
        } else {
            return 'N/A';
        }
    }

    returnStaffPhoto() {
        return secureStorage.getItem(commonConfig.SESSION_STAFF_PHOTO);
    }

    returnStaffID() {
        return parseInt(secureStorage.getItem(commonConfig.SESSION_STAFFID) ? secureStorage.getItem(commonConfig.SESSION_STAFFID) : 0, 10);
    }
    returnUserID() {
        return parseInt(secureStorage.getItem(commonConfig.SESSION_USER_ID), 10);
    }
    returnUserName() {
        var name = secureStorage.getItem(commonConfig.SESSION_USER_NAME);
        if (name !== '') {
            return name;
        } else {
            return 'N/A';
        }
    }
    returnLoggedUserEmail() {
        var email = secureStorage.getItem(commonConfig.SESSION_LOGGED_USER_EMAIL);
        if (email !== '') {
            return email;
        } else {
            return '';
        }
    }
    returnUserRoleID() {
        return parseInt(secureStorage.getItem(commonConfig.SESSION_LOGGED_USER_ROLE) ? secureStorage.getItem(commonConfig.SESSION_LOGGED_USER_ROLE) : 0, 10);
    }
    returnUserRole() {
        return parseInt(secureStorage.getItem(commonConfig.SESSION_USER_ROLE) ? secureStorage.getItem(commonConfig.SESSION_USER_ROLE) : 0, 10);
    }
    returnPersonID() {
        return parseInt(secureStorage.getItem(commonConfig.SESSION_PERSONID), 10);
    }

    returnSchoolCalendarID() {
        return parseInt(secureStorage.getItem(commonConfig.SESSION_CALENDARID), 10);
    }

    returnNewPersonID() {
        var newPersonId = parseInt(secureStorage.getItem(commonConfig.SESSION_NEWPERSONID), 10);
        if (!isNaN(newPersonId)) {
            return newPersonId;
        } else {
            return 0;
        }
    }

    returnNewPersonName() {
        var newPersonName = secureStorage.getItem(commonConfig.SESSION_NEWPERSONNAME);
        if (newPersonName !== '') {
            return newPersonName;
        } else {
            return 'N/A';
        }
    }
    returnSchoolSelected() {
        return secureStorage.getItem(commonConfig.SESSION_SELECT_SCHOOL);
    }
    returnSessionMpSchool() {
        return secureStorage.getItem(commonConfig.SESSION_MP_SCHOOL);
    }


    cleanSessionStore() {
        var n = sessionStorage.length;
        while (n--) {
            var key = sessionStorage.key(n);
            sessionStorage.removeItem(key);
        }
    }

    returnLastUserSessionDetails() {
        return JSON.parse(secureStorage.getItem(commonConfig.SESSION_LAST_USER_DETAILS));
    }

    returnSessionRoleBasedPermissionList() {
        return JSON.parse(secureStorage.getItem(commonConfig.SESSION_ROLE_BASED_PERMISSION_LIST));
    }

    returnSessionCurrentPagePermission() {
        return JSON.parse(secureStorage.getItem(commonConfig.SESSION_CURRENT_PAGE_PERMISSION));
    }

    returnSessionSchoolBasedRoleList() {
        return JSON.parse(secureStorage.getItem(commonConfig.SESSION_SCHOOL_BASED_ROLE_LIST));
    }
    returnUserSchoolMultiRoleList() {
        return JSON.parse(secureStorage.getItem(commonConfig.SESSION_SCHOOL_BASED_MULTI_ROLE_LIST));
    }

    returnSessionAssessmentAdminId() {
        return JSON.parse(secureStorage.getItem(commonConfig.SESSION_ASSESSMENT_ADMINID));
    }

    returnManageIncidentType() {
        return secureStorage.getItem(commonConfig.SESSION_MANAGE_INCIDENT_TYPE);
    }
    returnCurrentMenuCode() {
        return secureStorage.getItem(commonConfig.SESSION_MENU_CODE);
    }
    returnNextClassShow() {
        return parseInt(secureStorage.getItem(commonConfig.SESSION_NEXT_CLASS_SHOW), 10);
    }
    returnNextClassStart() {
        return secureStorage.getItem(commonConfig.SESSION_NEXT_CLASS_START);
    }
    //sso
    returnLoginUrl() {
        return secureStorage.getItem(commonConfig.SESSION_LOGIN_URL);
    }
    //time zone
    returnSchoolTimeZone() {
        let timeZoneName = secureStorage.getItem(commonConfig.SESSION_TIME_ZONE);
        if (timeZoneName !== '' && timeZoneName !== null) {
            return timeZoneName;
        } else {
            return 'N/A';
        }
    }
    returnEmailSelectedTeacherId() {
        // unsure if this is integer or string
        return JSON.parse(secureStorage.getItem(commonConfig.EMAIL_SELECTED_TEACHER_ID), 10);
    }
    returnEmailSelectedTeacher() {
        return secureStorage.getItem(commonConfig.EMAIL_SELECTED_TEACHER);
    }

    storeSecureData(name, data) {

        secureStorage.setItem(name, data);
    }
    returnSSOToken() {
        return secureStorage.getItem(commonConfig.SESSION_SSO_TOKEN);
    }
    returnSessionToken() {
        return secureStorage.getItem(commonConfig.SESSION_TOKEN);
    }
    returnTempCourseSectionId() {
        // unsure if this should be integer or string
        return parseInt(secureStorage.getItem('tempCourseSectionId'), 10);
    }
    returnRoutePath() {
        return secureStorage.getItem(commonConfig.SESSION_ROUTEPATH);
    }
    removeSessionItem(itemName) {
        secureStorage.removeItem(itemName);
    }
    returnMicroService() {
        try {
            return (secureStorage.getItem(commonConfig.SESSION_MICRO_SERVICE));
        }
        catch (err) {
            return "web";
        }
    }
    returnPayableList() {
        try {
            return (secureStorage.getItem(commonConfig.SESSION_PAYABLE_LIST));
        }
        catch (err) {
            return "development";
        }
    }
    returnPayableTransfer() {
        try {
            return (secureStorage.getItem(commonConfig.SESSION_PAYABLE_TRANSFER));
        }
        catch (err) {
            return "development";
        }
    }
    returnIncludeChild() {
        try {
            return (secureStorage.getItem(commonConfig.SESSION_INCLUDE_CHILD));
        }
        catch (err) {
            return "development";
        }
    }
    returnParentTotalDue() {
        try {
            return (secureStorage.getItem(commonConfig.SESSION_PARENT_TOTAL_DUE));
        }
        catch (err) {
            return "development";
        }
    }
    returnParentPaymentDetails() {
        try {
            return (secureStorage.getItem(commonConfig.SESSION_PARENT_PAYMENT_DETAILS));
        }
        catch (err) {
            return "development";
        }
    }
    returnPaymentObjectCount() {
        try {
            return (secureStorage.getItem(commonConfig.SESSION_PAYMENT_OBJECT_COUNT));
        }
        catch (err) {
            return "development";
        }
    }
}
export default sessionStore;
