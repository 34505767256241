import $ from 'jquery'
import React from 'react';
import { Link } from 'react-router-dom';
import MarkersCommonSelect from './markersCommonSelect';
import sessionStore from './sessionStoreComponent';
import { connect } from 'react-redux';
import i18n from '../../../i18n'
import ExecuteArgs from '../../shared/model/executeArgs';
import commonConfig from '../commonConfig';
import { pick } from 'lodash';
import * as actionsCommon from '../../shared/store/actions/index';
import endPointConfig from '../commonEndPointConfig';
import Validation from '../component/validationComponent';
import MarkersFileUpload from './markersFileUpload';
import { hostArray } from '../../../hostConfig';
import MarkersDatepicker from '../component/markersDatepicker';
import base64ImageConfig from '../base64ImageConfig';
let moment = require('moment');
class MarkersCommonPerson extends React.Component {
    initializeFields = {
        firstName: '',
        middleName: '',
        lastName: '',
        nameSuffix: '',
        lookupNameSuffix: null,
        homePhoneNumber: '',
        workPhoneNumber: '',
        mobileNumber: '',
        primaryEmail: '',
        alternateEmail: '',
        gender: '',
        profilePicId: null,
        lookupSalutation: 0,
        listAddress: [],
        selectedFileList: null,
        lookupOccupationType: '',
        occupationTitle: '',
        designation: '',
        monthlyIncome: null,
        workEmail: '',
        listCredential: [],
        dateOfBirth: ''
    }
    initializeAddressFields = {
        lookupAddressType: null,
        addressLine: '',
        city: "",
        state: "",
        county: "",
        countryCode: "",
        zip: "",
        geoLocation: null,
        isDefault: true
    }
    initializeCredentialFields = {
        credentialName: '',
        lookupCredentialType: null,
        issuranceDate: '',
        expirationDate: '',
        issuingState: '',
        certificateOrLicenseNo: '',
        issuingAuthority: ''
    }
    constructor(props) {
        super(props);
        this.state = {
            getPersonPostedDataField: {
                schoolId: new sessionStore().returnSchoolID(),
                type: this.props.hasOwnProperty('type') ? this.props.type : ''
            },
            fields: { ...this.initializeFields },
            newAddress: { ...this.initializeAddressFields },
            newCredential: { ...this.initializeCredentialFields },
            genderTypeList: [
                { "id": "M", "title": "Male" },
                { "id": "F", "title": "Female" },
                { "id": "O", "title": "Other" }
            ],
            phoneTypeList: [
                { "id": 901, "title": "Work Phone" },
                { "id": 902, "title": "Mobile Phone" },
                { "id": 903, "title": "Home Phone" }
            ],
            lookupSalutationList: [],
            lookupAddressTypeList: [],
            isError: 0,
            delfields: { id: null, deleteionReason: '' },
            getPersonInfoFlag: false,
            requiredFieldsAddress: [
                { fieldName: 'lookupAddressType', fieldType: 'default' },
                { fieldName: 'addressLine', fieldType: 'regExp', regExp: commonConfig.ADDRESS_SCHOOL },
                { fieldName: 'city', fieldType: 'regExp', regExp: commonConfig.ADDRESS_COUNTY_REGEX },
                { fieldName: 'state', fieldType: 'regExp', regExp: commonConfig.ADDRESS_CITY_STATE_COUNTRY },
                { fieldName: 'county', fieldType: 'regExpNotRequired', regExp: commonConfig.ADDRESS_COUNTY_REGEX },
                { fieldName: 'countryCode', fieldType: 'default' },
                { fieldName: 'zip', fieldType: 'regExp', regExp: commonConfig.POSTAL_CODE_ZIP }
            ],
            requiredFieldsCredential: [
                { fieldName: 'credentialName', fieldType: 'regExp', regExp: commonConfig.INSTITUITION_NAME },
                { fieldName: 'issuingState', fieldType: 'regExpNotRequired', regExp: commonConfig.ADDRESS_CITY_STATE_COUNTRY },
                { fieldName: 'certificateOrLicenseNo', fieldType: 'regExpNotRequired', regExp: commonConfig.LICENCE_NO },
                { fieldName: 'issuingAuthority', fieldType: 'regExpNotRequired', regExp: commonConfig.CERTIFICATE_NUMBER },
                { fieldName: 'lookupCredentialType', fieldType: 'default' },
                { fieldName: 'issuranceDate', fieldType: 'default' },
            ],
            requiredFieldsPhone: [
                { fieldName: 'tempPhoneNumber', fieldType: 'regExp', regExp: commonConfig.PHONE_NUMBER_ADD_PERSON, minLength: commonConfig.PHONE_NUMBER_MIN_LENGTH },
                { fieldName: 'tempPhoneType', fieldType: 'default' },
            ],
            requiredFields: [
                { fieldName: 'firstName', fieldType: 'regExp', regExp: commonConfig.SCHOOL_NAME_REGEX },
                { fieldName: 'lastName', fieldType: 'regExp', regExp: commonConfig.SCHOOL_NAME_REGEX },
                { fieldName: 'middleName', fieldType: 'regExpNotRequired', regExp: commonConfig.SCHOOL_NAME_REGEX },
                { fieldName: 'primaryEmail', fieldType: 'regExp', regExp: commonConfig.EMAIL },
                { fieldName: 'alternateEmail', fieldType: 'regExpNotRequired', regExp: commonConfig.EMAIL },
                { fieldName: 'selectedFileList', fieldType: 'onlyFileType', fileType: commonConfig.FILE_TYPE_IMAGE, fileMaxSize: new sessionStore().returnMaxFileSize(), fileMinSize: new sessionStore().returnMinFileSize() }, { fieldName: 'firstName', fieldType: 'regExp', regExp: commonConfig.SCHOOL_NAME_REGEX },
                { fieldName: 'occupationTitle', fieldType: 'regExpNotRequired', regExp: commonConfig.OCCUPATION_TITLE_DESIGNATION },
                { fieldName: 'designation', fieldType: 'regExpNotRequired', regExp: commonConfig.OCCUPATION_TITLE_DESIGNATION },
                { fieldName: 'monthlyIncome', fieldType: 'regExpNotRequired', regExp: commonConfig.MONTHLY_INCOME },
                { fieldName: 'workEmail', fieldType: 'regExpNotRequired', regExp: commonConfig.EMAIL }
            ],
            integerFieldCredential: ['lookupCredentialType'],
            integerField: ["lookupOccupationType"],
            integerFieldAddress: ['lookupAddressType'],
            tempPhoneType: '',
            tempPhoneNumber: '',
            tempGenderType: '',
            tempPhnArr: [],
            form_data: {},
            showAddNotify: false,
            savePersonData: { id: null, fullName: '' },
            isSearch: true,
            allPersonSearch: [],
            allPerson: [],
            personId: null,
            personName: '',
            isErrorCredential: 0,
            lookupCredentialType: [],
            isErrorPhone: 0,
            isAddressError: 0,
            editAddress: false,
            addressIndex: null,
            editCredential: false,
            isOccupationFieldsNotEmpty: false,
            credentialIndex: null
        }
        this.props.onCommonResetProps({ name: 'stateByCountryList', data: [] });
        this.props.onCommonResetProps({ name: 'countyByStateList', data: [] });
        this.props.onGetLookup(commonConfig.LOOKUP_STAFF_CREDENTIAL_TYPE);
        this.props.onGetLookup(commonConfig.LOOKUP_SALUTATION_TYPE);
        this.props.onGetLookup(commonConfig.LOOKUP_ADDRESS_TYPE);
        this.props.onGetLookup(commonConfig.LOOKUP_PERSON_OCCUPATION_TYPE);
        this.props.onGetLookup(commonConfig.LOOKUP_NAME_SUFFIX_TYPE);
        let executedArgument = new ExecuteArgs(commonConfig.COMPONENT_RESOURCE,
            commonConfig.COMPONENT_RESOURCE_GET_COUNTRY,
            commonConfig.METHOD_TYPE_GET);
        this.props.onGetCountryList(executedArgument);
    }
    getPersonInfo = () => {
        this.setState({ showAddNotify: false }, () => {
            if (this.state.getPersonPostedDataField.type === 'teacher') {
                let executedArgument = new ExecuteArgs(commonConfig.COMPONENT_STAFF,
                    endPointConfig.COMPONENT_SCHEDULE_TEACHER_GETALL + new sessionStore().returnSchoolID(),
                    commonConfig.METHOD_TYPE_GET);
                this.props.onGetPersonSucess(executedArgument);
            }
            if (this.state.getPersonPostedDataField.type === 'approvedTeacher') {
                let executedArgument = new ExecuteArgs(commonConfig.COMPONENT_STAFF,
                    endPointConfig.COMPONENT_STAFF_GET_STAFF_HOLIDAY_LIST + new sessionStore().returnSchoolID(),
                    commonConfig.METHOD_TYPE_GET);
                this.props.onGetHolidayStaffSucess(executedArgument);
            }
            else {
                let executedArgument = new ExecuteArgs(commonConfig.COMPONENT_RESOURCE,
                    commonConfig.COMPONENT_PERSON_GETALL,
                    commonConfig.METHOD_TYPE_POST, this.state.getPersonPostedDataField);
                this.props.onGetPersonSucess(executedArgument);
            }
        });
    }
    componentDidMount() {
        this.getPersonInfo();
        window.$("#addschooldesignatorsPersonModal").on('hidden.bs.modal', () => {
            this.setState({ fields: { ...this.initializeFields }, clearFile: true, tempPhnArr: [], tempPhoneNumber: '', tempPhoneType: '', isOccupationFieldsNotEmpty: false }, () => {
                let removeOccupationRequiredFields = this.state.requiredFields.filter(requiredField => requiredField.fieldName === "occupationTitle" ? requiredField.fieldType !== "regExp" : requiredField.fieldName !== 'lookupOccupationType');
                document.getElementById("addschooldesig").reset();
                this.setState({ requiredFields: removeOccupationRequiredFields });
            });
        });
        window.$(document).on('click', '[data-toggle="toggle-visibility"]', function (e) {
            e.preventDefault();
            var toggleElement = window.$(this).attr('data-targetlayer');
            var selfDestruct = window.$(this).attr('data-destructlayer');
            if (selfDestruct !== 'undefined') {
                window.$(selfDestruct).hide();
            }
            window.$(toggleElement).show();
        });
    }
    setFileField = (selectedFileList) => {
        let fields = Object.assign({}, this.state.fields);
        fields["selectedFileList"] = selectedFileList;
        this.setState({ fields: fields });
    }
    componentWillReceiveProps(nextProps) {
        if (nextProps.lookupCredentialTypeList && nextProps.lookupCredentialTypeList.length > 0) {
            this.setState({
                lookupCredentialType: nextProps.lookupCredentialTypeList
            })
        }
        if (nextProps.lookupAddressTypeList) {
            this.setState({ lookupAddressTypeList: nextProps.lookupAddressTypeList })
        }
        if (nextProps.lookupSalutationList) {
            this.setState({ lookupSalutationList: nextProps.lookupSalutationList })
        }
        if (nextProps.saveSchoolPerson && JSON.stringify(nextProps.saveSchoolPerson.Id) && this.state.getPersonInfoFlag) {
            this.setState({ getPersonInfoFlag: false, fields: this.initializeFields, isSearch: false, personId: JSON.stringify(nextProps.saveSchoolPerson.Id), personName: JSON.stringify(nextProps.saveSchoolPerson.FullName).replace(/\"/g, "") }, () => {
                this.props.setPersonData(this.state.personId, this.state.personName);
                window.$("#addschooldesig").trigger("reset");
                window.$('#addschooldesignatorsPersonModal').modal('hide');
                this.getPersonInfo();
            });
        }
        if (nextProps.schoolPersonList && nextProps.schoolPersonList.length > 0) {
            this.setState({ allPerson: nextProps.schoolPersonList });
        }
        if (nextProps.staffHolidayStaffList && nextProps.staffHolidayStaffList.length > 0) {
            this.setState({ allPerson: nextProps.staffHolidayStaffList });
        }
        if (nextProps.staffHolidayStaffList && nextProps.staffHolidayStaffList.length === 0 && this.props.type === 'approvedTeacher') {
            this.setState({ allPerson: [] })
        }
        if (nextProps.personInfo && !!nextProps.personInfo.id && !!nextProps.personInfo.name) {
            this.setState({ personId: nextProps.personInfo.id, personName: nextProps.personInfo.name });
        }
        //if (nextProps.personInfo && !nextProps.personInfo.id && !nextProps.personInfo.name) {
        //    this.setState({ personId: null, personName: '' });
        //}
    }
    //This method checks weather any data is populated in any of the occupation sub-component fields and push requiredFieldsOfOccupation if data is populated
    updateOccupationRequiredFields = (eventName) => {
        let occupationFieldArray = ['workEmail', 'occupationTitle', 'lookupOccupationType', 'designation', 'monthlyIncome'];
        if (occupationFieldArray.indexOf(eventName) !== -1) {
            let occupationFields = pick(this.state.fields, occupationFieldArray);
            let isOccupationFieldsNotEmpty = Object.values(occupationFields).some(occupationField => (occupationField !== null && occupationField !== ''));
            let requiredFieldsOfOccupation = [{ fieldName: 'lookupOccupationType', fieldType: 'default' }, { fieldName: 'occupationTitle', fieldType: 'regExp', regExp: commonConfig.OCCUPATION_TITLE_DESIGNATION }]
            let removeOccupationRequiredFields = (requiredFields) => requiredFields.filter(requiredField => requiredField.fieldName === "occupationTitle" ? requiredField.fieldType !== "regExp" : requiredField.fieldName !== 'lookupOccupationType');
            let requiredFields = !!isOccupationFieldsNotEmpty ? [...this.state.requiredFields, ...requiredFieldsOfOccupation] : removeOccupationRequiredFields(this.state.requiredFields);
            this.setState({ requiredFields, isOccupationFieldsNotEmpty }, () => {
                //isOccupationFieldsNotEmpty returns false if there is no data in the occupation sub-component if found returns true
            });
        }
    }
    handleChange = event => {
        let fields = Object.assign({}, this.state.fields);
        let integerField = this.state.integerField;
        let eventName = event.target.name;
        if (integerField.indexOf(event.target.name) === -1) {
            fields[event.target.name] = event.target.value;
        } else {
            if (isNaN(parseFloat(event.target.value))) {
                fields[event.target.name] = null;
            } else {
                fields[event.target.name] = parseInt(event.target.value, 10);
            }
        }
        this.setState({ fields: fields }, () => {
            this.updateOccupationRequiredFields(eventName);
        });
    }
    handleChangePhone = event => {
        this.setState({ tempPhoneNumber: event.target.value.replace(/^\s+/g, '') });
    }
    handleChangePhoneType = event => {
        this.setState({ tempPhoneType: event.target.value });
    }
    handleChangeGenderType = event => {
        let fields = Object.assign({}, this.state.fields);
        fields['gender'] = event.target.value;
        this.setState({ tempGenderType: event.target.value, fields: fields });
    }
    handleChangeAddress = event => {
        let newAddress = this.state.newAddress;
        if (event.target.name === 'countryCode') {
            newAddress['state'] = '';
            newAddress['county'] = '';
            this.props.onCommonResetProps({ name: 'countyByStateList', data: [] });
            let executedArgument = new ExecuteArgs(commonConfig.COMPONENT_RESOURCE,
                commonConfig.COMPONENT_RESOURCE_GET_STATE_BY_COUNTRY + event.target.value,
                commonConfig.METHOD_TYPE_GET);
            this.props.onGetStateByCountry(executedArgument);
        }
        if (event.target.name === 'state' && !!this.state.newAddress.countryCode && this.state.newAddress.countryCode === 'US') {
            let executedArgument = new ExecuteArgs(commonConfig.COMPONENT_RESOURCE,
                commonConfig.COMPONENT_RESOURCE_GET_COUNTY_BY_STATE + event.target.value,
                commonConfig.METHOD_TYPE_GET);
            this.props.onGetCountyByState(executedArgument);
        }
        if (event.target.name === "isDefault") {
            newAddress[event.target.name] = event.target.checked;
        } else {
            if (this.state.integerFieldAddress.indexOf(event.target.name) === -1) {
                newAddress[event.target.name] = event.target.value;
            }
            else {
                newAddress[event.target.name] = parseInt(event.target.value, 10);
            }
        }
        //newAddress[event.target.name] = event.target.value.replace(/^\s+/g, '');
        this.setState({ newAddress: newAddress });
    }
    handleChangeCredential = event => {
        let newCredential = this.state.newCredential;
        newCredential[event.target.name] = event.target.value.replace(/^\s+/g, '');
        this.setState({ newCredential: newCredential });
    }
    isErrorPhone = (isErrorPhone) => {
        let isError = false;
        let { tempPhoneNumber, tempPhoneType } = this.state;
        if ((!!tempPhoneType && !tempPhoneNumber) || (!tempPhoneType && !!tempPhoneNumber)) {
            isError = true;
            this.setState({ isErrorPhone: 1 });
        } else if ((!!tempPhoneType && !!tempPhoneNumber) || !!isErrorPhone) {
            this.props.onShowNotify(i18n.t('addPhoneNumber'), 'error');
            isError = true;
        }
        return isError;
    }
    handleSubmit = event => {
        event.preventDefault();
        let validationObj = new Validation();
        let submitStatus = validationObj.fieldValidation(this.state.fields, this.state.requiredFields);
        if (submitStatus === 0 || this.isErrorPhone(this.state.isErrorPhone)) {
            this.setState({ isError: 1 });
        } else {
            this.setState({ isError: 0 }, () => {
                this.setState({ getPersonInfoFlag: true }, () => {
                    let fields = this.state.fields;
                    if (this.state.tempPhoneType === commonConfig.IS_HOME_PHONE) {
                        if (fields['homePhoneNumber'] !== '') {
                            fields['homePhoneNumber'] = fields['homePhoneNumber'] + ',' + this.state.tempPhoneNumber;
                        } else {
                            fields['homePhoneNumber'] = this.state.tempPhoneNumber;
                        }
                    }
                    if (this.state.tempPhoneType === commonConfig.IS_WORK_PHONE) {
                        if (fields['workPhoneNumber'] !== '') {
                            fields['workPhoneNumber'] = fields['workPhoneNumber'] + ',' + this.state.tempPhoneNumber;
                        } else {
                            fields['workPhoneNumber'] = this.state.tempPhoneNumber;
                        }
                    }
                    if (this.state.tempPhoneType === commonConfig.IS_MOBILE_PHONE) {
                        if (fields['mobileNumber'] !== '') {
                            fields['mobileNumber'] = fields['mobileNumber'] + ',' + this.state.tempPhoneNumber;
                        } else {
                            fields['mobileNumber'] = this.state.tempPhoneNumber;
                        }
                    }
                    this.setState({ fields: fields });
                    this.setState({ showAddNotify: false }, () => {
                        const fileData = new FormData()
                        fileData.append('inputData', JSON.stringify(this.state.fields));
                        fileData.append('component', commonConfig.COMPONENT_RESOURCE);
                        fileData.append('endpoint', commonConfig.COMPONENT_PERSON_SAVE);
                        if (this.state.fields.selectedFileList) {
                            for (let file of this.state.fields.selectedFileList) {
                                fileData.append("postedFile", file);
                            }
                        }
                        this.props.onSavePersonSucess(fileData);
                    });
                });
            });
        }
    }
    //This function is called for add phone field.
    fieldShowMore = event => {
        event.preventDefault();
        let validationObj = new Validation();
        let submitStatus = validationObj.fieldValidation(this.state, this.state.requiredFieldsPhone);
        if (submitStatus === 0) {
            this.setState({ isErrorPhone: 1 })
        } else if (this.state.tempPhoneType !== "" && this.state.tempPhoneNumber !== "" && submitStatus === 1) {
            let tempPhoneArray = this.state.tempPhnArr;
            tempPhoneArray.push({ phoneType: this.state.tempPhoneType, phoneNumber: this.state.tempPhoneNumber })
            this.setState({ tempPhnArr: tempPhoneArray, isErrorPhone: 0 }, () => {
                this.setState({ tempPhoneType: '' });
                this.setState({ tempPhoneNumber: '' });
                this.phoneShow();
                let fields = this.state.fields;
                if (this.state.tempPhoneType === commonConfig.IS_HOME_PHONE) {
                    if (fields['homePhoneNumber'] !== '') {
                        fields['homePhoneNumber'] = fields['homePhoneNumber'] + ',' + this.state.tempPhoneNumber;
                    } else {
                        fields['homePhoneNumber'] = this.state.tempPhoneNumber
                    }
                }
                if (this.state.tempPhoneType === commonConfig.IS_WORK_PHONE) {
                    if (fields['workPhoneNumber'] !== '') {
                        fields['workPhoneNumber'] = fields['workPhoneNumber'] + ',' + this.state.tempPhoneNumber;
                    } else {
                        fields['workPhoneNumber'] = this.state.tempPhoneNumber
                    }
                }
                if (this.state.tempPhoneType === commonConfig.IS_MOBILE_PHONE) {
                    if (fields['mobileNumber'] !== '') {
                        fields['mobileNumber'] = fields['mobileNumber'] + ',' + this.state.tempPhoneNumber;
                    } else {
                        fields['mobileNumber'] = this.state.tempPhoneNumber
                    }
                }
                this.setState({ fields: fields });
            });
        }
    }
    deletePhone = (e, val) => {
        e.preventDefault();
        var myArray = this.state.tempPhnArr;
        myArray = $.grep(myArray, function (element, index) { return element.phoneNumber === val }, true);
        this.setState({ tempPhnArr: myArray }, () => {
            this.state.tempPhnArr.forEach((data, key) => {
                let fields = this.state.fields;
                if (data.phoneType === commonConfig.IS_HOME_PHONE) {
                    var homeph = fields['homePhoneNumber'].split(",");
                    var homeph_arr = [];
                    for (var i = 0; i < homeph.length; i++) {
                        if (homeph[i] !== val)
                            homeph_arr.push(homeph[i]);
                    }
                    fields['homePhoneNumber'] = homeph_arr.toString();
                }
                if (data.phoneType === commonConfig.IS_WORK_PHONE) {
                    var workph = fields['workPhoneNumber'].split(",");
                    var workph_arr = [];
                    for (i = 0; i < workph.length; i++) {
                        if (workph[i] !== val)
                            workph_arr.push(workph[i]);
                    }
                    fields['workPhoneNumber'] = workph_arr.toString();
                }
                if (data.phoneType === commonConfig.IS_MOBILE_PHONE) {
                    var mobileph = fields['mobileNumber'].split(",");
                    var mobileph_arr = [];
                    for (i = 0; i < mobileph.length; i++) {
                        if (mobileph[i] !== val)
                            mobileph_arr.push(mobileph[i]);
                    }
                    fields['mobileNumber'] = mobileph_arr.toString();
                }
                this.setState({ fields: fields });
            })
        })
    }
    saveCredential = () => {
        let validationObj = new Validation();
        let submitStatus = validationObj.fieldValidation(this.state.newCredential, this.state.requiredFieldsCredential);
        if (submitStatus === 0) {
            this.setState({ isErrorCredential: 1 });
        } else {
            this.setState({ isErrorCredential: 0 }, () => {
                let fields = Object.assign({}, this.state.fields);
                if (this.state.editCredential) {
                    var credential = { ...this.state.newCredential }
                    var key = this.state.credentialIndex
                    fields['listCredential'].splice(key, 1, credential)
                    this.setState({ fields, editCredential: false, credentialIndex: null }, () => {
                        this.clearCredentialFields()
                    })
                }
                else {
                    if (this.state.newCredential.length !== '' || this.state.newCredential.length !== 'undefined' || this.state.newCredential.length !== null) {
                        let cred = this.state.newCredential;
                        fields['listCredential'].push(cred);
                    }
                    this.setState({ fields, editCredential: false, credentialIndex: null }, () => {
                        this.clearCredentialFields()
                    })
                }
            })
        }
    }
    closeModal = () => {
        this.setState({ field: this.initializeFields }, () => {
            window.$('#addschooldesignatorsPersonModal').modal('hide');
        });
    }
    clearAddressFields = () => {
        this.setState({
            ...this.state, isAddressError: 0, addressIndex: null, editAddress: false,
            newAddress: {
                lookupAddressType: null,
                addressLine: '',
                city: "",
                state: "",
                county: "",
                countryCode: "",
                zip: "",
                geoLocation: '',
                isDefault: true
            }
        }, () => {
            window.$("#addNewAddress").hide();
            window.$("#btnAddModalAddress").show();
        })
    }
    //This function is called for delete address from temporary array.
    removeAddress = (event, key) => {
        event.preventDefault();
        let fields = Object.assign({}, this.state.fields);
        fields['listAddress'].splice(key, 1)
        this.setState({ fields: fields }, () => {
            this.clearAddressFields()
        })
    }
    editAddress = (item, addressIndex) => {
        let newAddress = { ...item };
        let executedArgument = new ExecuteArgs(commonConfig.COMPONENT_RESOURCE,
            commonConfig.COMPONENT_RESOURCE_GET_STATE_BY_COUNTRY + newAddress.countryCode,
            commonConfig.METHOD_TYPE_GET);
        this.props.onGetStateByCountry(executedArgument);
        if (!!newAddress.countryCode && newAddress.countryCode === 'US') {
            let executedArgument = new ExecuteArgs(commonConfig.COMPONENT_RESOURCE,
                commonConfig.COMPONENT_RESOURCE_GET_COUNTY_BY_STATE + newAddress.state,
                commonConfig.METHOD_TYPE_GET);
            this.props.onGetCountyByState(executedArgument);
        }
        this.setState({ newAddress, editAddress: true, addressIndex }, () => {
            window.$("#addNewAddress").show();
            window.$("#btnAddModalAddress").hide();
        })
    }
    saveAddress = () => {
        let validationObj = new Validation();
        let submitStatus = validationObj.fieldValidation(this.state.newAddress, this.state.requiredFieldsAddress);
        if (submitStatus === 0) {
            this.setState({ isAddressError: 1 });
        } else {
            this.setState({ isAddressError: 0 }, () => {
                let fields = Object.assign({}, this.state.fields);
                if (this.state.editAddress) {
                    var address = { ...this.state.newAddress }
                    var key = this.state.addressIndex
                    fields['listAddress'].splice(key, 1, address)
                    this.setState({ fields, editAddress: false, addressIndex: null }, () => {
                        this.clearAddressFields()
                    })
                }
                else {
                    if (this.state.newAddress.length !== '' || this.state.newAddress.length !== 'undefined' || this.state.newAddress.length !== null) {
                        fields['listAddress'].push(this.state.newAddress);
                    }
                    this.setState({ fields: fields }, () => {
                        this.clearAddressFields()
                    });
                }
            })
        }
    }
    addressShow = () => {
        var address = null;
        this.state.fields.listAddress.length > 0 ?
            address = this.state.fields.listAddress.map((item, key) =>
                <div className="panel-default">
                    <div className="panel-body">
                        <div className="row">
                            <div className="col-10 col-md-7 col-lg-10">
                                <div className="address">
                                    <label className="d-block d-md-none f-s-12 text-muted m-b-5">{i18n.t('address')}</label>
                                    <h4>{item.addressLine}</h4>
                                    <p>{item.city},{item.state},{item.zip}</p>
                                </div>
                            </div>
                            <div className="col-12 col-md-3 col-lg-2 ">
                                <div className="action-icons text-right text-md-left">
                                    <Link to="#" onClick={() => this.editAddress(item, key)}><i className="icon-pen6"></i></Link>
                                    <Link to="#" onClick={(e) => this.removeAddress(e, key)}><i className="icon-bin2 "></i></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : address = <div className="panel-default">
                <div className="panel-body">
                    <div className="text-danger f-s-12 text-center" role="alert">
                        <i className="icon-info2 m-r-5"></i> {i18n.t('noAddressFound')}
                    </div>
                </div>
            </div>;
        return address;
    }
    editCredential = (item, credentialIndex) => {
        let newCredential = { ...item }
        this.setState({ newCredential, editCredential: true, credentialIndex }, () => {
            window.$("#addNewCredential").show();
            window.$("#btnAddCredential").hide();
        })
    }
    credentialShow = () => {
        var credential = null;
        this.state.fields.listCredential && this.state.fields.listCredential.length > 0 ?
            credential = this.state.fields.listCredential.map((data, i) =>
                <div className="panel-default">
                    <div className="panel-body">
                        <div className="row">
                            <div className="col-10 col-md-7 col-lg-10">
                                <div className="address">
                                    <label className="d-block d-md-none f-s-12 text-muted m-b-5">{i18n.t('address')}</label>
                                    <h4>{data.credentialName}</h4>
                                    <p>{data.issuingState},{data.certificateOrLicenseNo},{data.issuingAuthority}</p>
                                </div>
                            </div>
                            <div className="col-12 col-md-3 col-lg-2 ">
                                <div className="action-icons text-right text-md-left">
                                    <Link to="#" onClick={() => this.editCredential(data, i)}><i className="icon-pen6"></i></Link>
                                    <Link to="#" onClick={(e) => this.removeCredential(e, i)}><i className="icon-bin2 "></i></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : credential = <div className="panel-default">
                <div className="panel-body">
                    <div className="text-danger f-s-12 text-center" role="alert">
                        <i className="icon-info2 m-r-5"></i> {i18n.t('noCredentialFound')}
                    </div>
                </div>
            </div>;
        return credential;
    }
    clearCredentialFields = () => {
        this.setState({
            ...this.state, isErrorCredential: 0, credentialIndex: null, editCredential: false,
            newCredential: {
                credentialName: '',
                lookupCredentialType: null,
                issuranceDate: '',
                expirationDate: '',
                issuingState: '',
                certificateOrLicenseNo: '',
                issuingAuthority: ''
            }
        }, () => {
            window.$("#addNewCredential").hide();
            window.$("#btnAddCredential").show();
        })
    }
    removeCredential = (event, key) => {
        event.preventDefault();
        let fields = Object.assign({}, this.state.fields);
        fields['listCredential'].splice(key, 1)
        this.setState({ fields: fields }, () => {
            this.clearCredentialFields()
        })
    }
    phoneShow = () => {
        var phoneDetails = this.state.tempPhnArr.length > 0 ?
            this.state.tempPhnArr.map((arrVal, key) =>
                <div className="row mainClass">
                    <div className="col-md-4 col-lg-4">
                        <div className="form-group">
                            <select className="custom-select" onChange={this.handleChangePhoneType} value={arrVal.phoneType}>
                                <option value="">{arrVal.phoneType}</option>
                                {this.state.phoneTypeList.map((lookup, key) => {
                                    return <option key={key} value={lookup.id}>{lookup.title}</option>
                                })}
                            </select>
                        </div>
                    </div>
                    <div className="col-md-5 col-lg-6">
                        <div className="form-group">
                            <input type="text" maxLength={10} className="form-control" placeholder={i18n.t('enterPhone')} name="homePhoneNumber" onChange={this.handleChangePhone} value={arrVal.phoneNumber} />
                        </div>
                    </div>
                    <div className="col-md-3 col-lg-2">
                        <div className="form-group">
                            <div className="text-center appendMinus">
                                <Link to="#" className="btn btn-outline-danger btn-block mr-2 minus" onClick={(e) => this.deletePhone(e, arrVal.phoneNumber)}><i className="icon-cross"></i></Link>
                            </div>
                        </div>
                    </div>
                </div>
            ) : null
        return phoneDetails;
    }
    searchPerson = (event) => {
        const personName = event.target.value;
        let filterData = this.state.allPerson && this.state.allPerson.length > 0 ? this.state.allPerson.filter(item => item[this.state.getPersonPostedDataField.type === 'teacher' ? 'staffFullName' : 'fullName'].toLowerCase().includes(personName.toLowerCase())) : '';
        let setStateObject = { allPersonSearch: filterData, personName: personName }
        if (personName.length === 0) {
            setStateObject = { ...setStateObject, personId: null }
        }
        this.setState(setStateObject, () => {
            this.props.setPersonData(this.state.personId, this.state.personName);
            if (!!personName) {
                window.$('.autocomplete-dropdown').show();
            } else {
                window.$('.autocomplete-dropdown').hide();
            }
        })
    }
    setPersonId = (data) => {
        if (this.state.getPersonPostedDataField.type === 'teacher') {
            this.setState({ personName: data.staffFullName, personId: data.id }, () => {
                this.props.setPersonData(data.id, data.staffFullName)
                window.$('.autocomplete-dropdown').hide();
            });
        } else if (this.props.hasOwnProperty('type') && this.props.type === 'staff' && this.props.hasOwnProperty('includeStaffId') && !!this.props.includeStaffId) {
            this.props.setPersonData(data.id, data.fullName, data.staffId)
            window.$('.autocomplete-dropdown').hide();
        } else {
            this.setState({ personName: data.fullName, personId: data.id }, () => {
                this.props.setPersonData(this.props.hasOwnProperty('type') && this.props.type === 'staff' && !this.props.hasOwnProperty('showAddPerson') ? data.staffId : data.id, data.fullName)
                window.$('.autocomplete-dropdown').hide();
            });
        }
    }
    occupationShow = () => {
        return <React.Fragment>
            <h6 className="text-primary m-t-10"><b>{i18n.t('occupation')}</b></h6>
            <div className="panel-default">
                <div className="panel-body">
                    <div className="row">
                        <div className="col-sm-6 col-md-12 col-lg-6">
                            <div className="form-group">
                                <label className="col-form-label">{i18n.t('type')} {this.state.isOccupationFieldsNotEmpty && <span>*</span>}</label>
                                <select className="custom-select" name="lookupOccupationType" onChange={this.handleChange} value={this.state.fields.lookupOccupationType}>
                                    <option value="">{i18n.t('select')}</option>
                                    {this.props.lookupPersonOccupationTypeList.map((lookup, key) => {
                                        return <option key={key} value={lookup.id}>{lookup.title}</option>
                                    })}
                                </select>
                                {this.state.isOccupationFieldsNotEmpty && <Validation data={{
                                    field: i18n.t("type"),
                                    errorClass: 'type_error',
                                    value: this.state.fields.lookupOccupationType,
                                    isError: this.state.isError,
                                    validationType: 'selectfield'
                                }} returnValidation={this.functionValidation} />}
                            </div>
                        </div>
                        <div className="col-sm-6 col-md-12 col-lg-6">
                            <div className="form-group">
                                <label className="col-form-label">{i18n.t('title')} {this.state.isOccupationFieldsNotEmpty && <span>*</span>}</label>
                                <input type="text" maxLength={50} className="form-control" placeholder={i18n.t('enterTitle')} name="occupationTitle" onChange={this.handleChange} value={this.state.fields.occupationTitle} />
                                <Validation data={{ field: i18n.t('title'), errorClass: 'occupationTitle_error', value: this.state.fields.occupationTitle, isError: this.state.isError, validationType: this.state.isOccupationFieldsNotEmpty ? 'regExp' : 'regExpNotRequired', regExp: commonConfig.OCCUPATION_TITLE_DESIGNATION }} returnValidation={this.functionValidation} />
                            </div>
                        </div>
                        <div className="col-sm-6 col-md-12 col-lg-4"></div>
                    </div>
                    <div className="row">
                        <div className="col-sm-6 col-md-12 col-lg-6">
                            <div className="form-group">
                                <label className="col-form-label">{i18n.t('designation')}</label>
                                <input type="text" maxLength={50} className="form-control" placeholder={i18n.t('enterDesignation')} name="designation" onChange={this.handleChange} value={this.state.fields.designation} />
                                <Validation data={{ field: i18n.t('designation'), errorClass: 'designation_error', value: this.state.fields.designation, isError: this.state.isError, validationType: 'regExpNotRequired', regExp: commonConfig.OCCUPATION_TITLE_DESIGNATION }} returnValidation={this.functionValidation} />
                            </div>
                        </div>
                        <div className="col-sm-6 col-md-12 col-lg-6">
                            <div className="form-group">
                                <label className="col-form-label">{i18n.t('monthlyIncome')}</label>
                                <input type="text" maxLength={40} className="form-control" placeholder={i18n.t('enterMonthlyIncome')} name="monthlyIncome" onChange={this.handleChange} value={this.state.fields.monthlyIncome || ''} />
                                <Validation data={{ field: i18n.t('monthlyIncome'), errorClass: 'monthlyIncome_error', value: this.state.fields.monthlyIncome, isError: this.state.isError, validationType: 'regExpNotRequired', regExp: commonConfig.MONTHLY_INCOME }} returnValidation={this.functionValidation} />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-6 col-md-12 col-lg-6">
                            <div className="form-group">
                                <label className="col-form-label">{i18n.t('workEmail')}</label>
                                <input type="text" maxLength={40} className="form-control" placeholder={i18n.t('enterWorkEmail')} name="workEmail" onChange={this.handleChange} value={this.state.fields.workEmail} />
                                <Validation data={{ field: i18n.t('workEmail'), errorClass: 'workEmail_error', value: this.state.fields.workEmail, isError: this.state.isError, validationType: 'regExpNotRequired', regExp: commonConfig.EMAIL }} returnValidation={this.functionValidation} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    }
    render() {
        const { isError, isRequired } = this.props;
        let fieldAttr = {
            className: 'form-control',
            type: "text",
            placeholder: this.props.hasOwnProperty('placeholder') ? this.props.placeholder : i18n.t('searchPerson'),
            name: "personName",
            value: this.state.personName || ''
        }
        const labelTitle = this.props.hasOwnProperty('labelTitle') ? this.props.labelTitle : i18n.t('person');
        const searchPersonValidationResult = !!isRequired ? <Validation data={{
            field: labelTitle,
            errorClass: 'personId_error',
            value: this.state.personId || '',
            isError: isError,
            validationType: 'selectfield'
        }} returnValidation={this.functionValidation} /> : null;
        return (
            <React.Fragment>
                {!this.state.isSearch ?
                    <div className="form-group">
                        <label className="col-form-label">{labelTitle}{!!isRequired && <span> *</span>}</label>
                        <input {...fieldAttr} data-minchar="0" readOnly={true} />
                        {searchPersonValidationResult}
                    </div>
                    :
                    <div className="form-group dropdown-autocomplete">
                        <label className="col-form-label">{labelTitle}{!!isRequired && <span> *</span>}</label>
                        <input {...fieldAttr} data-minchar="0" autoComplete="off" onChange={(event) => this.searchPerson(event)} />
                        {searchPersonValidationResult}
                        <div className="autocomplete-dropdown">
                            <div className="autocomplete-dropdown-body">
                                {/*this.props.personInfo.name !== null && this.props.personInfo.name !== '' && */this.state.allPersonSearch && this.state.allPersonSearch.length > 0 ? this.state.allPersonSearch.map((data, i) =>
                                    <Link className="media" to="#" onClick={() => this.setPersonId(data)}>
                                        <img className="mr-3 rounded-circle" alt="img" src={base64ImageConfig.USER_DEFAULT_IMG} />
                                        <div className="media-body">
                                            <h5 className="mt-0">{this.state.getPersonPostedDataField.type === 'teacher' ? data.staffFullName : data.fullName}</h5>
                                        </div>
                                    </Link>
                                ) : ''}
                            </div>
                            {(this.state.getPersonPostedDataField.type === '' || this.state.getPersonPostedDataField.type === 'exceptstudent' || ((this.state.getPersonPostedDataField.type === 'staff' || this.state.getPersonPostedDataField.type === 'staffwithstaffcontacts') && this.props.hasOwnProperty('showAddPerson') && this.props.showAddPerson)) &&
                                <Link className="autocomplete-dropdown-footer" to="#" data-toggle="modal" data-target="#addschooldesignatorsPersonModal">
                                    <i className="icon-plus3 m-r-10"></i> {i18n.t('addNewPerson')}
                                </Link>
                            }
                            {this.props.showAddStaff && !this.props.showAddPerson
                                ? <Link to="#" onClick={() => window.location.href = `${hostArray[new sessionStore().returnHostEnvironment()].home}/staff/addStaff/demographic`} className="autocomplete-dropdown-footer">
                                    <i className="icon-plus3 m-r-10"></i>{i18n.t("addNewStaff")}
                                </Link>
                                : null
                            }
                        </div>
                    </div>
                }
                {(this.state.getPersonPostedDataField.type === '' || this.state.getPersonPostedDataField.type === 'exceptstudent' || ((this.state.getPersonPostedDataField.type === 'staff' || this.state.getPersonPostedDataField.type === 'staffwithstaffcontacts') && this.props.hasOwnProperty('showAddPerson') && this.props.showAddPerson)) &&
                    <div id="addschooldesignatorsPersonModal" className="modal fade">
                        <form action="" id="addschooldesig" name="addschooldesig">
                            <div className="modal-dialog modal-lg" role="document">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h5 className="modal-title" id="CreateCalendarModal">{i18n.t('addPerson')}</h5>
                                        <button type="button" className="close" onClick={this.closeModal}>
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <div className="modal-body">
                                        <h6 className="text-primary m-t-10"><b>{i18n.t('name')}</b></h6>
                                        <div className="panel-default">
                                            <div className="panel-body">
                                                <div className="row">
                                                    <div className="col-sm-6 col-md-12 col-lg-4">
                                                        <div className="form-group">
                                                            <label className="col-form-label">{i18n.t('salutation')}</label>
                                                            <select className="custom-select" name="lookupSalutation" onChange={this.handleChange} value={this.state.fields.salutation}>
                                                                <option value="">{i18n.t('select')}</option>
                                                                {this.state.lookupSalutationList.map((lookup, key) => {
                                                                    return <option key={key} value={lookup.id}>{lookup.title}</option>
                                                                })}
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-6 col-md-12 col-lg-4">
                                                        <div className="form-group">
                                                            <MarkersCommonSelect
                                                                label={i18n.t("nameSuffix")}
                                                                divClassName="mb-4  mb-lg-0"
                                                                name="lookupNameSuffix"
                                                                value={this.state.fields['lookupNameSuffix']}
                                                                optArray={this.props.lookupNameSuffixList}
                                                                handleChange={this.handleChange}
                                                                isError={this.state.isError}
                                                                isRequired={false}
                                                                optionLabelValue='id'
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-6 col-md-12 col-lg-4"></div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-sm-6 col-md-12 col-lg-4">
                                                        <div className="form-group">
                                                            <label className="col-form-label">{i18n.t('firstName')} <span>*</span></label>
                                                            <input type="text" maxLength={50} className="form-control" placeholder={i18n.t('enterFirstName')} name="firstName" onChange={this.handleChange} value={this.state.fields.firstName} />
                                                            <Validation data={{ field: i18n.t('firstName'), errorClass: 'firstName_error', value: this.state.fields.firstName, isError: this.state.isError, validationType: 'regExp', regExp: commonConfig.SCHOOL_NAME_REGEX }} returnValidation={this.functionValidation} />
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-6 col-md-12 col-lg-4">
                                                        <div className="form-group">
                                                            <label className="col-form-label">{i18n.t('middleName')}</label>
                                                            <input type="text" maxLength={50} className="form-control" placeholder={i18n.t('enterMiddleName')} name="middleName" onChange={this.handleChange} value={this.state.fields.middleName} />
                                                            <Validation data={{ field: i18n.t('middleName'), errorClass: 'middleName_error', value: this.state.fields.middleName, isError: this.state.isError, validationType: 'regExpNotRequired', regExp: commonConfig.SCHOOL_NAME_REGEX }} returnValidation={this.functionValidation} />
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-6 col-md-12 col-lg-4">
                                                        <div className="form-group">
                                                            <label className="col-form-label">{i18n.t('lastName')} <span>*</span></label>
                                                            <input type="text" maxLength={50} className="form-control" placeholder={i18n.t('enterLastName')} name="lastName" onChange={this.handleChange} value={this.state.fields.lastName} />
                                                            <Validation data={{ field: i18n.t('lastName'), errorClass: 'lastName_error', value: this.state.fields.lastName, isError: this.state.isError, validationType: 'regExp', regExp: commonConfig.SCHOOL_NAME_REGEX }} returnValidation={this.functionValidation} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-sm-6 col-md-12 col-lg-6">
                                                <MarkersDatepicker
                                                    value={this.state.fields.dateOfBirth}
                                                    title={i18n.t('dateOfBirth')}
                                                    name="dateOfBirth"
                                                    isError={this.state.isError}
                                                    onChange={this.handleChange}
                                                    endDate={moment().subtract(commonConfig.STAFF_MIN_AGE, 'years').format("YYYY-MM-DD")}
                                                />
                                                <div className="form-group">
                                                    <label className="col-form-label">{i18n.t('gender')} </label>
                                                    <select className="custom-select" onChange={this.handleChangeGenderType} name="gender" value={this.state.tempGenderType}>
                                                        <option value="">{i18n.t('selectGenderType')}</option>
                                                        {this.state.genderTypeList.map((lookup, key) => {
                                                            return <option key={key} value={lookup.id}>{lookup.title}</option>
                                                        })}
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-sm-6 col-md-12 col-lg-6">
                                                <MarkersFileUpload
                                                    label={i18n.t("selectPhoto")}
                                                    isError={this.state.isError}
                                                    isRequired={false}
                                                    setFileField={this.setFileField}
                                                    fileType={commonConfig.FILE_TYPE_IMAGE}
                                                    isPreview={true}
                                                    savedFileList={[]}
                                                    fileMaxSize={new sessionStore().returnMaxFileSize()}
                                                    fileMinSize={new sessionStore().returnMinFileSize()}
                                                />
                                            </div>
                                        </div>
                                        <h6 className="text-primary m-t-10"><b>{i18n.t('email')}</b></h6>
                                        <div className="row">
                                            <div className="col-sm-6 col-md-12 col-lg-6">
                                                <div className="form-group">
                                                    <label className="col-form-label">{i18n.t('primaryEmail')}  <span>*</span></label>
                                                    <input type="text" maxLength={256} name="primaryEmail" value={this.state.fields.primaryEmail} className="form-control" onChange={this.handleChange} placeholder={i18n.t('enterPrimaryEmail')} />
                                                    <Validation data={{ field: i18n.t('primaryEmail'), errorClass: 'primaryEmail_error', value: this.state.fields.primaryEmail, isError: this.state.isError, validationType: 'regExp', regExp: commonConfig.EMAIL }} returnValidation={this.functionValidation} />
                                                </div>
                                            </div>
                                            <div className="col-sm-6 col-md-12 col-lg-6">
                                                <div className="form-group">
                                                    <label className="col-form-label">{i18n.t('alternateEmail')}</label>
                                                    <input type="text" maxLength={256} name="alternateEmail" value={this.state.fields.alternateEmail} onChange={this.handleChange} className="form-control" placeholder={i18n.t('enterAlternateEmail')} />
                                                    <Validation data={{ field: i18n.t('alternateEmail'), errorClass: 'email_error', value: this.state.fields.alternateEmail, isError: this.state.isError, validationType: 'regExpNotRequired', regExp: commonConfig.EMAIL }} returnValidation={this.functionValidation} />
                                                </div>
                                            </div>
                                        </div>
                                        <h6 className="text-primary m-t-10"><b>{i18n.t('phone')}</b></h6>
                                        <div className="label-header ">
                                            <div className="row">
                                                <div className="col-md-4 col-lg-4">
                                                    <label>{i18n.t('phoneType')}</label>
                                                </div>
                                                <div className="col-md-5 col-lg-6">
                                                    <label>{i18n.t('phoneNumberWithCode')}</label>
                                                </div>
                                                <div className="col-md-3 col-lg-2">
                                                    <label>{i18n.t('action')}</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="panel-default">
                                            <div className="panel-body p-b-0">
                                                <div className="outerClass">
                                                    <div className="row mainClass">
                                                        <div className="col-md-4 col-lg-4">
                                                            <div className="form-group">
                                                                <select className="custom-select" onChange={this.handleChangePhoneType} value={this.state.tempPhoneType}>
                                                                    <option value="">{i18n.t('selectPhoneType')}</option>
                                                                    {this.state.phoneTypeList.map((lookup, key) => {
                                                                        return <option key={key} value={lookup.title}>{lookup.title}</option>
                                                                    })}
                                                                </select>
                                                                <Validation data={{ field: i18n.t('phoneType'), errorClass: 'PhoneType_error', value: this.state.tempPhoneType, isError: this.state.isErrorPhone, validationType: 'selectfield' }} returnValidation={this.functionValidation} />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-5 col-lg-6">
                                                            <div className="form-group">
                                                                <input type="text" maxLength={14} className="form-control" placeholder={i18n.t('enterPhone')} name="homePhoneNumber" onChange={this.handleChangePhone} value={this.state.tempPhoneNumber} />
                                                                <Validation data={{ field: i18n.t('phoneNumberWithCode'), errorClass: 'phone_error', value: this.state.tempPhoneNumber, isError: this.state.isErrorPhone, validationType: 'regExp', regExp: commonConfig.PHONE_NUMBER_ADD_PERSON, minLength: commonConfig.PHONE_NUMBER_MIN_LENGTH }} returnValidation={this.functionValidation} />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-3 col-lg-2">
                                                            <div className="form-group">
                                                                <div className="text-center appendPlus">
                                                                    {this.state.tempPhoneType !== '' && this.state.tempPhoneNumber !== ''
                                                                        ?
                                                                        <Link to="#" className="btn btn-outline-success btn-block mr-2 plus" onClick={this.fieldShowMore}>
                                                                            <i className="icon-plus3" ></i>
                                                                        </Link>
                                                                        :
                                                                        <Link to="#" className="btn btn-outline-success btn-block mr-2 plus" onClick={this.fieldShowMore} >
                                                                            <i className="icon-plus3" ></i>
                                                                        </Link>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {this.phoneShow()}
                                                </div>
                                            </div>
                                        </div>
                                        <h6 className="text-primary m-t-10"><b>{i18n.t('address')}</b></h6>
                                        {this.addressShow()}
                                        <div className="text-center">
                                            <Link to="#" className="btn btn-primary m-b-15" id="btnAddModalAddress" data-toggle="toggle-visibility" data-targetlayer="#addNewAddress" data-destructlayer="#btnAddModalAddress" >
                                                <i className="icon-plus3 m-r-10"></i> {i18n.t('addNewAddress')}
                                            </Link>
                                        </div>
                                        <div className="panel-default" id="addNewAddress" style={{ display: 'none' }}>
                                            <div className="panel-header">
                                                <h6 className="panel-title">{i18n.t('address')} <label className="badge badge-primary text-uppercase m-l-15"> {!this.state.editAddress ? i18n.t('new') : i18n.t('update')}</label></h6>
                                            </div>
                                            <div className="panel-body pt-0 pb-0">
                                                <div className="row">
                                                    <div className="col-sm-6 col-md-12 col-lg-6">
                                                        <div className="form-group">
                                                            <label className="col-form-label">{i18n.t('addressType')} <span>*</span></label>
                                                            <select className="custom-select" value={this.state.newAddress.lookupAddressType || ''} name="lookupAddressType" onChange={this.handleChangeAddress}>
                                                                <option selected>{i18n.t('selectType')}</option>
                                                                {this.state.lookupAddressTypeList.map((lookup, key) => {
                                                                    return <option key={key} value={lookup.id}>{lookup.title}</option>
                                                                })}
                                                            </select>
                                                            <Validation data={{ field: i18n.t('addressType'), errorClass: 'addressType_error', value: this.state.newAddress.lookupAddressType, isError: this.state.isAddressError, validationType: 'field' }} returnValidation={this.functionValidation} />
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-6 col-md-12 col-lg-6">
                                                        <div className="form-group">
                                                            <label className="col-form-label">{i18n.t('address')} <span>*</span></label>
                                                            <input type="text" maxLength={256} className="form-control" placeholder={i18n.t('enterAddress')} value={this.state.newAddress.addressLine} name="addressLine" onChange={this.handleChangeAddress} />
                                                            <Validation data={{ field: i18n.t('address'), errorClass: 'address_error', value: this.state.newAddress.addressLine, isError: this.state.isAddressError, validationType: 'regExp', regExp: commonConfig.ADDRESS_SCHOOL }} returnValidation={this.functionValidation} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-sm-6 col-md-12 col-lg-6">
                                                        <MarkersCommonSelect
                                                            label={i18n.t("country")}
                                                            name="countryCode"
                                                            value={this.state.newAddress['countryCode']}
                                                            optArray={this.props.countryList}
                                                            handleChange={this.handleChangeAddress}
                                                            isError={this.state.isAddressError}
                                                            isRequired={true}
                                                        />
                                                    </div>
                                                    <div className="col-sm-6 col-md-12 col-lg-6">
                                                        {/* <div className="form-group">
                                                            <label className="col-form-label">{i18n.t('state')} <span>*</span></label>
                                                            <input type="text" maxLength={256} className="form-control" placeholder={i18n.t('enterState')} value={this.state.newAddress.state} name="state" onChange={this.handleChangeAddress} />
                                                            <Validation data={{ field: i18n.t('state'), errorClass: 'state_error', value: this.state.newAddress.state, isError: this.state.isAddressError, validationType: 'regExp', regExp: commonConfig.ADDRESS_CITY_STATE_COUNTRY }} returnValidation={this.functionValidation} />
                                                        </div> */}
                                                        <MarkersCommonSelect
                                                            label={i18n.t("state")}
                                                            optionLabelValue="title"
                                                            name="state"
                                                            value={this.state.newAddress.state}
                                                            optArray={this.props.stateByCountryList}
                                                            handleChange={this.handleChangeAddress}
                                                            isError={this.state.isAddressError}
                                                            isRequired={true}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    {!!this.state.newAddress.countryCode && this.state.newAddress.countryCode === 'US' &&
                                                        <div className="col-sm-6 col-md-12 col-lg-6">
                                                            <MarkersCommonSelect
                                                                label={i18n.t("county")}
                                                                optionLabelValue="title"
                                                                name="county"
                                                                value={this.state.newAddress.county}
                                                                optArray={this.props.countyByStateList}
                                                                handleChange={this.handleChangeAddress}
                                                                isError={this.state.isAddressError}
                                                                isRequired={false}
                                                            />
                                                        </div>}
                                                    <div className="col-sm-6 col-md-12 col-lg-6">
                                                        <div className="form-group">
                                                            <label className="col-form-label">{i18n.t('city')} <span>*</span></label>
                                                            <input type="text" maxLength={256} className="form-control" placeholder={i18n.t('enterCity')} value={this.state.newAddress.city} name="city" onChange={this.handleChangeAddress} />
                                                            <Validation data={{ field: i18n.t('city'), errorClass: 'city_error', value: this.state.newAddress.city, isError: this.state.isAddressError, validationType: 'regExp', regExp: commonConfig.ADDRESS_COUNTY_REGEX }} returnValidation={this.functionValidation} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-sm-6 col-md-12 col-lg-6">
                                                        <div className="form-group">
                                                            <label className="col-form-label">{i18n.t('zipPostalCode')} <span>*</span></label>
                                                            <input type="text" maxLength={10} className="form-control" placeholder={i18n.t('enterZipPostalCode')} value={this.state.newAddress.zip} name="zip" onChange={this.handleChangeAddress} />
                                                            <Validation data={{ field: i18n.t('zipPostalCode'), errorClass: 'state_error', value: this.state.newAddress.zip, isError: this.state.isAddressError, validationType: 'regExp', regExp: commonConfig.POSTAL_CODE_ZIP }} returnValidation={this.functionValidation} />
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-6 col-md-12 col-lg-6">
                                                        <div className="form-group">
                                                            <label className="col-form-label">Geolocation</label>
                                                            <input type="text" className="form-control" value={this.state.newAddress.geoLocation} name="geoLocation" placeholder={i18n.t('enterGeoLocation')} onChange={this.handleChangeAddress} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-12">
                                                    {/*<label className="checkbox-inline checkbox-switch switch-success"><input type="checkbox" name="" /><span></span> {i18n.t('default')}</label>*/}
                                                    <label className="d-flex align-items-center mb-0">
                                                        <div className="fluid-switch-toggle cursor-pointer">
                                                            <input className="switch-toggle-state" type="checkbox" name="" />
                                                            <div className="switch-indicator"></div> 
                                                            <div className="switch-activebg"></div>
                                                        </div>
                                                        <span className="switch-text">{i18n.t("default")}</span>
                                                    </label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="panel-body pt-0 text-right">
                                                <Link to="#" className="btn btn-outline-success m-r-5 addNewFieldAreaaa showAddButton1" onClick={this.saveAddress}>
                                                    <i className="icon-checkmark2"></i><span className="sr-only">{i18n.t('done')}</span>
                                                </Link>
                                                <Link to="#" className="btn btn-outline-warning" data-toggle="toggle-visibility" data-targetlayer="#btnAddModal" data-destructlayer="#addNewAddress" onClick={this.clearAddressFields}>
                                                    <i className="icon-cross"></i><span className="sr-only">{i18n.t('cancel')}</span>
                                                </Link>
                                            </div>
                                        </div>
                                        <h6 className="text-primary m-t-10"><b>{i18n.t('credential')}</b></h6>
                                        {this.credentialShow()}
                                        <div className="text-center">
                                            <Link to="#" className="btn btn-primary m-b-15" id="btnAddCredential" data-toggle="toggle-visibility" data-targetlayer="#addNewCredential" data-destructlayer="#btnAddCredential">
                                                <i className="icon-plus3 m-r-10"></i> {i18n.t('addNewCredential')}
                                            </Link>
                                        </div>
                                        <div className="panel-default" id="addNewCredential" style={{ display: 'none' }}>
                                            <div className="panel-header">
                                                <h6 className="panel-title">{i18n.t('credential')} <label className="badge badge-primary text-uppercase m-l-15">{!this.state.editCredential ? i18n.t('new') : i18n.t('update')}</label></h6>
                                            </div>
                                            <div className="panel-body">
                                                <div className="row">
                                                    <div className="col-sm-6 col-md-12 col-lg-6">
                                                        <div className="form-group">
                                                            <label className="col-form-label">{i18n.t('credentialName')}  <span>*</span></label>
                                                            <input type="text" maxLength={256} className="form-control" placeholder={i18n.t('credentialName')} name="credentialName" onChange={this.handleChangeCredential} value={this.state.newCredential.credentialName} required />
                                                            <Validation data={{
                                                                field: i18n.t('credentialName'),
                                                                errorClass: 'credentialName_error',
                                                                value: this.state.newCredential.credentialName,
                                                                isError: this.state.isErrorCredential,
                                                                validationType: 'regExp', regExp: commonConfig.INSTITUITION_NAME
                                                            }} returnValidation={this.functionValidation} />
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-6 col-md-12 col-lg-6">
                                                        <div className="form-group">
                                                            <label className="col-form-label">{i18n.t('credentialType')} <span>*</span></label>
                                                            <select className="custom-select" onChange={this.handleChangeCredential} name="lookupCredentialType" value={this.state.newCredential.lookupCredentialType || ''}>
                                                                <option value="">
                                                                    {i18n.t('select')}
                                                                </option>
                                                                {this.state.lookupCredentialType.map((lookup, key) => {
                                                                    return <option key={key} value={lookup.id}>{lookup.title}</option>
                                                                })}
                                                            </select>
                                                            <Validation data={{
                                                                field: i18n.t("credentialType"),
                                                                errorClass: 'credentialType_error',
                                                                value: this.state.newCredential.lookupCredentialType,
                                                                isError: this.state.isErrorCredential,
                                                                validationType: 'selectfield'
                                                            }} returnValidation={this.functionValidation} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-sm-6 col-md-12 col-lg-6">
                                                        <MarkersDatepicker
                                                            title={i18n.t('issuingDate')}
                                                            value={this.state.newCredential.issuranceDate}
                                                            name="issuranceDate"
                                                            onChange={this.handleChangeCredential}
                                                            isRequired={true}
                                                            isError={this.state.isErrorCredential}
                                                            validationType="field"
                                                        />
                                                    </div>
                                                    <div className="col-sm-6 col-md-12 col-lg-6">
                                                        <MarkersDatepicker
                                                            title={i18n.t('expirationDate')}
                                                            value={this.state.newCredential.expirationDate}
                                                            name="expirationDate"
                                                            isError={this.state.isErrorCredential}
                                                            onChange={this.handleChangeCredential}
                                                            startDate={this.state.newCredential.issuranceDate}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-sm-6 col-md-12 col-lg-6">
                                                        <div className="form-group">
                                                            <label className="col-form-label">{i18n.t('issuingState')}</label>
                                                            <input type="text" maxLength={256} className="form-control" placeholder={i18n.t('enterIssuingState')} name="issuingState" value={this.state.newCredential.issuingState} onChange={this.handleChangeCredential} />
                                                            <Validation data={{
                                                                field: i18n.t("issuingState"),
                                                                errorClass: 'issuingState_error',
                                                                value: this.state.newCredential.issuingState,
                                                                isError: this.state.isErrorCredential,
                                                                validationType: 'regExpNotRequired', regExp: commonConfig.ADDRESS_CITY_STATE_COUNTRY
                                                            }} returnValidation={this.functionValidation} />
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-6 col-md-12 col-lg-6">
                                                        <div className="form-group">
                                                            <label className="col-form-label">{i18n.t('certificateOrLicenseNo')}</label>
                                                            <input type="text" maxLength={30} className="form-control" placeholder={i18n.t('enterCertificateLicenceNo')} name="certificateOrLicenseNo" value={this.state.newCredential.certificateOrLicenseNo} onChange={this.handleChangeCredential} />
                                                            <Validation data={{
                                                                field: i18n.t("certificateLicenceNo"),
                                                                errorClass: 'cretificateLicenceNo_error',
                                                                value: this.state.newCredential.certificateOrLicenseNo,
                                                                isError: this.state.isErrorCredential,
                                                                validationType: 'regExpNotRequired', regExp: commonConfig.LICENCE_NO
                                                            }} returnValidation={this.functionValidation} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-sm-6 col-md-12 col-lg-6">
                                                        <div className="form-group">
                                                            <label className="col-form-label">{i18n.t('issuingAuthority')}</label>
                                                            <input type="text" maxLength={256} className="form-control" placeholder={i18n.t('enterIssuingAuthority')} name="issuingAuthority" value={this.state.newCredential.issuingAuthority} onChange={this.handleChangeCredential} />
                                                            <Validation data={{
                                                                field: i18n.t("issuingAuthority"),
                                                                errorClass: 'issuingAuthority_error',
                                                                value: this.state.newCredential.issuingAuthority,
                                                                isError: this.state.isErrorCredential,
                                                                validationType: 'regExpNotRequired', regExp: commonConfig.CERTIFICATE_NUMBER
                                                            }} returnValidation={this.functionValidation} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="panel-body pt-0 text-right">
                                                <Link to="#" className="btn btn-outline-success m-r-5 addNewFieldAreaaa showAddButton1" onClick={this.saveCredential}>
                                                    <i className="icon-checkmark2"></i><span className="sr-only">{i18n.t('done')}</span>
                                                </Link>
                                                <Link to="#" className="btn btn-outline-warning" data-toggle="toggle-visibility" data-targetlayer="#btnAddCredential" data-destructlayer="#addNewCredential" onClick={this.clearCredentialFields}>
                                                    <i className="icon-cross " ></i><span className="sr-only">{i18n.t('cancel')}</span>
                                                </Link>
                                            </div>
                                        </div>
                                        {this.props.hasOwnProperty('isOccupation') ?
                                            <div></div>
                                            :
                                            this.occupationShow()
                                        }
                                        <div className="modal-footer">
                                            <button type="button" className="btn btn-success" onClick={this.handleSubmit}>{i18n.t('submit')}</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                }
            </React.Fragment>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        lookupSalutationList: state.commonStore.lookupSalutationList,
        lookupAddressTypeList: state.commonStore.lookupAddressTypeList,
        saveSchoolPerson: state.commonStore.saveSchoolPerson,
        schoolPersonList: state.commonStore.schoolPersonList,
        staffHolidayStaffList: state.commonStore.staffHolidayStaffList,
        lookupCredentialTypeList: state.commonStore.lookupCredentialTypeList,
        lookupPersonOccupationTypeList: state.commonStore.lookupPersonOccupationTypeList,
        countryList: state.commonStore.countryList,
        stateByCountryList: state.commonStore.stateByCountryList,
        countyByStateList: state.commonStore.countyByStateList,
        lookupNameSuffixList: state.commonStore.lookupNameSuffixList
    };
};
const mapDispatchToProps = dispatch => {
    return {
        onSavePersonSucess: (fileData) => dispatch(actionsCommon.callCommonSaveApi(fileData, 'School Person', true)),
        onGetPersonSucess: (executeArgs) => dispatch(actionsCommon.callCommonListApi(executeArgs, 'School Person')),
        onGetHolidayStaffSucess: (executeArgs) => dispatch(actionsCommon.callCommonListApi(executeArgs, 'staff holiday staff')),
        onGetCountryList: (executeArgs) => dispatch(actionsCommon.callCommonListApi(executeArgs, 'country')),
        onGetLookup: (categoryType) => dispatch(actionsCommon.callLookupApi(categoryType)),
        onShowNotify: (message, type) => dispatch(actionsCommon.showNotify(message, type)),
        onGetStateByCountry: (executeArgs) => dispatch(actionsCommon.callCommonListApi(executeArgs, 'state by country')),
        onGetCountyByState: (executeArgs) => dispatch(actionsCommon.callCommonListApi(executeArgs, 'county by state')),
        onCommonResetProps: (executeArgs) => dispatch(actionsCommon.commonStoreStateReset(executeArgs))
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(MarkersCommonPerson);