import React, { Component } from 'react';
import sessionStore from '../../components/common/component/sessionStoreComponent';
import { Redirect } from 'react-router-dom';
export class BaseComponent extends Component {
    constructor(props) {
        if (new sessionStore().returnSessionToken() === null) {
            return <Redirect to="/login" />;
        }
        super(props);
    }
}
export default BaseComponent;