export class endPointConfig {
    //Billing Head
    static COMPONENT_BILLING_HEAD_SAVE = 'v1/billinghead/save';
    static COMPONENT_BILLING_HEAD_UPDATE = 'v1/billinghead/update';
    static COMPONENT_GET_ALL_BILLING_HEAD = 'v1/billinghead/getall?';
    static COMPONENT_GET_BILLING_HEAD_BYID = 'v1/billinghead/getbyid?Id=';
    static COMPONENT_BILLING_HEAD_DELETE = 'v1/billinghead/delete';
    //generate student password
    static COMPONENT_GET_PENDING_PASSWORD_STUDENT_LIST = 'v1/studentinfo/getpendingpasswordstudentlist?schoolId=';
    static COMPONENT_GENERATE_REGENERATE_STUDENT_PASSWORD = 'v1/studentinfo/generateselectivestudentusername';
    //Fee Structure
    static COMPONENT_FEE_STRUCTURE_SAVE = 'v1/feestructure/save';
    static COMPONENT_FEE_STRUCTURE_UPDATE = 'v1/feestructure/update';
    static COMPONENT_FEE_STRUCTURE_DELETE = 'v1/feestructure/delete';
    static COMPONENT_GET_FEE_STRUCTURE_BYID = 'v1/feestructure/getbyid?Id=';
    static COMPONENT_GET_FEE_STRUCTURE_BY_PAGINATION = 'v1/feestructure/getfeestructurebypagination';
    static COMPONENT_GET_ALL_FEE_STRUCTURE_BY_SCHOOL_ID = 'v1/feestructure/getbyschoolid?';
    static COMPONENT_GET_ALL_FEE_STRUCTURE_BY_GRADE_AND_SESSION_ID = 'v1/feestructure/getbygradeandsessionid?';
    static COMPONENT_GET_MULTIPLE_FEE_STRUCTURE_BY_GRADE_AND_SESSION_ID = 'v1/feestructure/getbygradeandsessionidwithfeestructure?';
    //Payable Date
    static COMPONENT_GET_ALL_PAYABLE_DATE = 'v1/payabledate/getall?';
    static COMPONENT_PAYABLE_DATE_SAVE = 'v1/payabledate/save';
    static COMPONENT_PAYABLE_DATE_UPDATE = 'v1/payabledate/update';
    static COMPONENT_PAYABLE_DATE_BY_ID = 'v1/payabledate/getbyfeestructureid?Id=';
    static COMPONENT_PAYABLE_DATE_DELETE = 'v1/payabledate/delete';
    static COMPONENT_PAYABLE_DATE_COPY_FEE_STRUC_GET_ALL = "v1/payabledate/getallcopyfeebyschoolid?";
    static COMPONENT_PAYABLE_DATE_GET_COPY_DETAILS = "v1/payabledate/getcopyfeevalidation?";
    //Payment History
    static COMPONENT_PAYMENT_HISTORY_GET_STUDENT_PAYMENT_HISTORY_BY_SCHOOLID = 'v1/studentpaymenthistory/getstudentpaymenthistorybyschoolid?';
    //Student Payable
    static COMPONENT_GET_ALL_STUDENT_PAYABLE = 'v1/studentpayable/getstudentpayablebypagination';
    static COMPONENT_GET_ALL_STUDENT_BY_GRADE_AND_SESSION = 'v1/studentpayable/getstudentsbygradeandsession?';
    static COMPONENT_STUDENT_PAYABLE_SAVE = 'v1/studentpayable/savestudentpayable';
    static COMPONENT_MASS_STUDENT_PAYABLE_SAVE = 'v1/studentpayable/savemassstudentpayable';
    static COMPONENT_STUDENT_PAYABLE_UPDATE = 'v1/studentpayable/updatestudentpayable';
    static COMPONENT_STUDENT_PAYABLE_BY_ID = 'v1/studentpayable/getstudentpayablebyid?';
    static COMPONENT_STUDENT_PAYABLE_DELETE = 'v1/studentpayable/delete';
    static COMPONENT_STUDENT_PAYABLE_DELETE_PAYMENT = 'v1/studentpayable/payment/delete';
    static COMPONENT_STUDENT_PAYABLE_INSTALLMENT_DELETE = 'v1/studentpayable/installment/delete';
    static COMPONENT_GET_ALL_STUDENT_PAYMENT = 'v1/studentpayable/getpayableinitiation';
    static COMPONENT_STUDENT_PAYABLE_MASS_ASSOCIATION = 'v1/studentpayable/massassociationstudentpayable';
    static COMPONENT_STUDENT_PAYABLE_MASS_DELETE = 'v1/studentpayable/deletestudentsselectedunpaidpayables';
    //Student Payment
    static COMPONENT_GET_STUDENT_DUE_PAYMENT = 'v1/studentpayment/getduepaymentbystudentid';
    static COMPONENT_GET_PAST_PAYMENT_BY_STUDENTID = 'v1/studentpayment/getpastpaymentbystudentid'
    static COMPONENT_GET_STUDENT_PAYMENT_BYID = 'v1/studentpayment/getbyid?Id='
    static COMPONENT_SAVE_STUDENT_CASH_PAYMENT = 'v1/studentpayment/savestudenttotalcashpayment'
    static COMPONENT_SAVE_STUDENT_CHEQUE_PAYMENT = 'v1/studentpayment/savestudenttotalchequepayment'
    static COMPONENT_SAVE_STUDENT_NEFT_PAYMENT = 'v1/studentpayment/savestudenttotalneftpayment'
    static COMPONENT_UPDATE_STUDENT_CASH_PAYMENT = 'v1/studentpayment/updatestudentcashpayment'
    static COMPONENT_UPDATE_STUDENT_CHEQUE_PAYMENT = 'v1/studentpayment/updatestudentchequepayment'
    static COMPONENT_UPDATE_STUDENT_NEFT_PAYMENT = 'v1/studentpayment/updatestudentneftpayment'
    static COMPONENT_GET_PAYMENT_DETAILS_BY_PAYABLEID = 'v1/studentpayment/getbystudentpayableids?ids='
    static COMPONENT_STUDENT_ONLINE_PAYMENT_SAVE = 'v1/studentpayment/savestudentonlinepayment';
    static COMPONENT_STUDENT_TOTAL_ONLINE_PAYMENT_SAVE = 'v1/studentpayment/savestudenttotalonlinepayment';
    static COMPONENT_GET_STUDENT_DUE_PAYMENT_BY_PARENT_ID = 'v1/studentpayment/getduepaymentbyparentid';
    static COMPONENT_ALL_STUDENT_TOTAL_ONLINE_PAYMENT_SAVE = 'v1/studentpayment/saveallstudentstotalonlinepayment';
    static COMPONENT_GET_ALL_PAYMENT_DETAILS_BY_PAYABLEID = 'v1/studentpayment/getpaymentdetailsbypayableids?payableIds='
    static COMPONENT_GET_STUDENT_GUARDIANS = 'v1/studentpayment/getstudentguardians?studentId=';
    //Gateway Integration
    static COMPONENT_GATEWAY_INTEGRATION_SAVE = 'v1/gatewayintegration/savegatewayinegration';
    static COMPONENT_GATEWAY_INTEGRATION_UPDATE = 'v1/gatewayintegration/updategatewayinegration';
    static COMPONENT_GET_ALL_GATEWAY_INTEGRATION = 'v1/gatewayintegration/getallgatewayintegrationbyschoolid?schoolId=';
    static COMPONENT_GET_GATEWAY_INTEGRATION_BY_ID = 'v1/gatewayintegration/getgatewayintegrationbyid?Id=';
    static COMPONENT_GATEWAY_INTEGRATION_DELETE = 'v1/gatewayintegration/deletegatewayintegration';
    static COMPONENT_GET_ALL_ACTIVE_GATEWAY_INTEGRATION = 'v1/gatewayintegration/getallactivegatewayintegrationbyschoolid?schoolId=';
    // Upload Maximum File Size
    static COMPONENT_UPLOAD_FILE_SIZE_GET_BYID = 'v1/uploadfilesize/getbydistrictid?DistrictId=';
    static COMPONENT_DISTRICT_GET_GENERAL_INFO = 'v1/districtgeneralinfo/getbydistrictid?Id=';
    //past due report
    static COMPONENT_GET_STUDENT_PASTDUES = "v1/billingreport/getstudentpastdues";
    static COMPONENT_DOWNLOAD_STUDENT_PASTDUES = "v1/billingreport/downloadpastduereport";
    static COMPONENT_REPORT_FEESTRUCTURE_ASSOCIATEDWITHSTUDENTS_BY_SESSIONID = "v1/feestructure/getfeestructureassociatewithstudent?";
   
    //student collection report
    static COMPONENT_FEEGROUP_GETALLBY_SESSION_ID = 'v1/feegroup/getallfeegroupbysessionid';
    static COMPONENT_GET_STUDENTLIST_COLLECTION_REPORT = 'v1/billingreport/getstudentlistforcollectionreport';
    static COMPONENT_DOWNLOAD_STUDENT_COLLECTION_REPORT = 'v1/billingreport/downloadstudentcollectionreport'
}
export default endPointConfig;