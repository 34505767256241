export class PaymentGateway {
    //Razorpay payment gateway checkout method
    razorCheckout(data = {}, paymentData = {}, classObj = {}, callbackFunc = '', currency = 'USD', cancelFunc = '') {
        if (paymentData && paymentData.orderId) {
            var options = {
                "key": paymentData.apiKey, // Enter the Key ID generated from the Dashboard
                "amount": paymentData.amount, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
                "currency": currency,
                "name": "Test Customer",
                "description": paymentData.description,
                "image": "https://dotnettec.com/wp-content/uploads/2019/10/cropped-dotnettec_header_logo.png", // You can give your logo url
                "order_id": paymentData.orderId,
                "handler": function (response) {
                    classObj[callbackFunc](response, data);
                },
                "prefill": {
                    "name": "Test Customer",
                    "email": "test@gmail.com",
                    "contact": "9844556677"
                },
                "notes": {
                    "address": "result.address"
                },
                "theme": {
                    "color": "#F37254"
                },
                "modal": {
                    "ondismiss": function () {
                        classObj[cancelFunc]();
                    }
                }
            };
            var rzp1 = new window.Razorpay(options);
            rzp1.open();
        }
    }
    //Stripe payment gateway checkout method
    stripeCheckout(data = {}, paymentData = {}, classObj = {}, callbackFunc = '') {
        var stripe = new window.Stripe(paymentData.apiKey);
        stripe.redirectToCheckout({ sessionId: paymentData.orderId });
        //classObj[callbackFunc]({}, paymentData);
    }
}
export default PaymentGateway;